import { Skeleton } from '@collective/ui';
import { Image, ImageProps } from '@collective/ui/lib/image/image';

import { generateSrcSet, SrcSetSizes } from '../utils/image-utils';

type ImageWrappedProps = ImageProps & {
  src: string;
  srcSetSizes?: SrcSetSizes;
  srcSize?: { width: number; height?: number };
  scale?: number;
  withSkeleton?: boolean;
};

export const ImageWrapped = ({
  src,
  srcSetSizes,
  srcSize,
  scale,
  withSkeleton,
  ...rest
}: ImageWrappedProps) => {
  const srcSet = srcSetSizes
    ? generateSrcSet(src, srcSetSizes, scale)
    : undefined;

  return (
    <Image
      src={src}
      srcSet={srcSet}
      objectFit="cover"
      data-chromatic="ignore"
      fallback={withSkeleton ? <Skeleton data-chromatic="ignore" /> : undefined}
      {...rest}
    />
  );
};
