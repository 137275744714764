import {
  companyBs,
  companyName,
  paragraphs1,
} from '@collective/utils/fixtures';
import cuid from 'cuid';
import slugify from 'slugify';

import { Client_CollectiveType, PublicStatus } from '../../generated';
import { generateMember } from './member';

export const generateCollective = (
  props?: Partial<Client_CollectiveType>,
  n = 0
): Client_CollectiveType => {
  const name = props?.name || companyName[n];
  return {
    id: cuid(),
    name,
    slug: slugify(name.toLowerCase()),
    tagline: paragraphs1[n],
    publicStatus: PublicStatus.Public,
    members: [generateMember()],
    skills: companyBs[n].split(' '),
    ...props,
  };
};
