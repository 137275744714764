import {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

type PublicPageIframeContextProps = {
  timestamp: number;
  refreshIframe: () => void;
  loaded: boolean;
  setLoaded: (data: boolean) => void;
  hasError: boolean;
  setHasError: (data: boolean) => void;
  pageReady: MutableRefObject<boolean>;
};

export const PublicPageIframeContext =
  createContext<PublicPageIframeContextProps>({
    timestamp: 0,
    refreshIframe: () => {},
    loaded: false,
    setLoaded: () => {},
    hasError: false,
    setHasError: () => {},
    pageReady: { current: false },
  });

type PublicPageIframeContextProviderProps = {
  children: ReactNode;
};

export const PublicPageIframeContextProvider = ({
  children,
}: PublicPageIframeContextProviderProps) => {
  const [timestamp, setTimestamp] = useState<number | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const pageReady = useRef(false);

  /**
   * The timestamp null is made to avoid a bug when landing on the marketplace
   * with the slug in the url. If the iframe didn't load (example : website dead),
   * the onload event of the iframe was not triggered for some reason
   * It also avoid a conflict of SSR where timestamp has different initial values
   */
  useEffect(() => {
    setTimestamp(new Date().getTime());
  }, [setTimestamp]);

  const refreshIframe = useCallback(() => {
    setLoaded(false);
    setHasError(false);
    pageReady.current = false;
    setTimestamp(new Date().getTime());
  }, [setLoaded, setHasError, setTimestamp]);

  if (timestamp === null) {
    return null;
  }

  const contextValue = {
    timestamp,
    refreshIframe,
    loaded,
    setLoaded,
    hasError,
    setHasError,
    pageReady,
  };

  return (
    <PublicPageIframeContext.Provider value={contextValue}>
      {children}
    </PublicPageIframeContext.Provider>
  );
};

export const usePublicPageIframe = () => useContext(PublicPageIframeContext);
