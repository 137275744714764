import {
  JobWorkPreference,
  Maybe,
  ProjectStartDate,
} from '@collective/data-type';
import { SupportedLanguage } from '@collective/translation';
import {
  Box,
  Flex,
  IconCalendar,
  IconClock,
  IconCompany,
  IconMapPin,
  Text,
} from '@collective/ui';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { JobExpireBadge } from './job-expire-badge';

type IdealStartDateRowProps = {
  idealStartDate: Maybe<ProjectStartDate>;
};

export const IdealStartDateRow = ({
  idealStartDate,
}: IdealStartDateRowProps) => {
  const { t } = useTranslation('common');

  if (!idealStartDate) {
    return null;
  }

  return (
    <Flex gap={2} align="center">
      <IconCalendar color="rythm.700" />
      <Text color="rythm.700">
        {t('job.details.keyInformation.startDate.label')}
      </Text>
      <Box minH="26px" lineHeight="26px">
        <IdealStartDateContent idealStartDate={idealStartDate} />
      </Box>
    </Flex>
  );
};

const IdealStartDateContent = ({
  idealStartDate,
}: {
  idealStartDate: ProjectStartDate;
}) => {
  const { t } = useTranslation('common');

  const getLabel = () => {
    switch (idealStartDate) {
      case ProjectStartDate.Asap:
        return t('startDate.asap');
      case ProjectStartDate.In_2To_4Weeks:
        return t('startDate.in2To4Weeks');
      case ProjectStartDate.In_4To_8Weeks:
        return t('startDate.in4To8Weeks');
      case ProjectStartDate.In_8WeeksOrMore:
        return t('startDate.in8WeeksOrMore');
    }
  };

  const label = getLabel();

  if (idealStartDate === ProjectStartDate.Asap) {
    return (
      <Flex alignItems="center">
        <Box
          bgColor="critical.500"
          w={2}
          h={2}
          display="inline-block"
          mr={2}
          borderRadius="sm"
        />
        <Text variant="desktop-m-medium" minH="26px" lineHeight="26px">
          {label}
        </Text>
      </Flex>
    );
  }

  return (
    <Text variant="desktop-m-regular" minH="26px" lineHeight="26px">
      {label}
    </Text>
  );
};

type JobLocationRowProps = {
  location: Maybe<string>;
  language: SupportedLanguage;
};

export const JobLocationRow = ({ location, language }: JobLocationRowProps) => {
  const { t, i18n } = useTranslation('common');

  if (!location || i18n.language !== language) {
    return null;
  }

  return (
    <Flex gap={2} wrap="wrap" align="center">
      <IconMapPin color="rythm.700" />
      <Text color="rythm.700">
        {t('job.details.keyInformation.location.label')}
      </Text>
      <Text>{location}</Text>
    </Flex>
  );
};

type JobWorkPreferencesRowProps = {
  workPreferences: JobWorkPreference[];
};

export const JobWorkPreferencesRow = ({
  workPreferences,
}: JobWorkPreferencesRowProps) => {
  const { t } = useTranslation('common');

  if (!workPreferences.length) {
    return null;
  }

  const label = workPreferences.map((workPreference) => {
    switch (workPreference) {
      case JobWorkPreference.Hybrid:
        return t('job.details.keyInformation.workPreferences.value.hybrid');
      case JobWorkPreference.OnSite:
        return t('job.details.keyInformation.workPreferences.value.onSite');
      case JobWorkPreference.Remote:
        return t('job.details.keyInformation.workPreferences.value.remoteOnly');
    }
  });

  return (
    <Flex gap={2} wrap="wrap" align="center">
      <IconCompany color="rythm.700" />
      <Text color="rythm.700">
        {t('job.details.keyInformation.workPreferences.label')}
      </Text>
      <Text>{label.join(', ')}</Text>
    </Flex>
  );
};

type JobExpirationDateRowProps = {
  expiresAt: Maybe<string>;
};

export const JobExpirationDateRow = ({
  expiresAt,
}: JobExpirationDateRowProps) => {
  const { t } = useTranslation('common');

  if (!expiresAt || dayjs().isAfter(expiresAt)) {
    return null;
  }

  return (
    <Flex gap={2} wrap="wrap" align="center">
      <IconClock color="rythm.700" />
      <Text color="rythm.700">
        {t('job.details.keyInformation.expirationDate.label')}
      </Text>
      <JobExpireBadge expiresAt={expiresAt} />
    </Flex>
  );
};
