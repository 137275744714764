import { BorderBox, Flex, IconInfo, Text, Tooltip } from '@collective/ui';
import { displayCurrencyFromNumber } from '@collective/utils/helpers';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

type BudgetRangeBadgeProps = {
  budgetRangeFrom?: number | undefined | null;
  budgetRangeTo?: number | undefined | null;
  withTooltip?: boolean;
};

export function BudgetRangeBadge({
  budgetRangeFrom,
  budgetRangeTo,
  withTooltip = false,
}: BudgetRangeBadgeProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation('common');

  if (isNil(budgetRangeFrom) || isNil(budgetRangeTo)) {
    return null;
  }

  const [from, to] = [
    displayCurrencyFromNumber(budgetRangeFrom, false, language),
    displayCurrencyFromNumber(budgetRangeTo, false, language),
  ];

  return (
    <BorderBox
      noHover
      borderColor="transparent"
      bgColor="primary.25"
      color="primary.600"
      px="6px"
      py="4px"
      borderRadius="8px"
    >
      <Flex gap={2} align="center">
        <Text variant="desktop-m-medium" color="primary.600">
          {from === to ? from : t('profile.budgetRange.range', { from, to })}
        </Text>
        {withTooltip && (
          <Tooltip label={t('profile.budgetRange.estimationTooltip')}>
            <IconInfo />
          </Tooltip>
        )}
      </Flex>
    </BorderBox>
  );
}
