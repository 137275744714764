import {
  Button,
  Flex,
  IconDuplicate,
  IconLinkedIn,
  IconMail,
  IconShare,
  IconXCom,
  Modal,
  TextInput,
  useBoolean,
  useSuccessToast,
} from '@collective/ui';
import {
  copyTextToClipboard,
  getPublicJobLink,
} from '@collective/utils/helpers';
import { useTranslation } from 'react-i18next';

import { ButtonLink } from '../button-link/button-link';
import { JobShareBanner } from './job-share-banner';

type JobShareProps = {
  slug: string;
};

export const JobShare = ({ slug }: JobShareProps) => {
  const { t } = useTranslation('common');

  const [isOpen, setOpen] = useBoolean(false);

  if (!slug) {
    return null;
  }

  return (
    <>
      <JobShareBanner
        button={
          <Button
            variant="secondary"
            rightIcon={<IconShare />}
            ml={{ base: undefined, md: 'auto' }}
            width={{ base: '100%', md: 'auto' }}
            onClick={setOpen.on}
          >
            {t('job.share.button')}
          </Button>
        }
      />
      <JobShareModal slug={slug} isOpen={isOpen} onClose={setOpen.off} />
    </>
  );
};

type JobShareModalProps = {
  slug: string;
  isOpen: boolean;
  onClose: VoidFunction;
};

const JobShareModal = ({ slug, isOpen, onClose }: JobShareModalProps) => {
  const { t } = useTranslation('common');

  const successToast = useSuccessToast();

  const link = getPublicJobLink(slug || '');

  const onCopyClick = async () => {
    await copyTextToClipboard(link);

    successToast({ title: t('job.share.modal.toast.copy') });
  };

  const emailLink = useEmailLink(link);
  const linkedInLink = useLinkedInLink(link);
  const xLink = useXLink(link);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('job.share.modal.title')}>
      <Flex direction="column" gap="24px" p="20px">
        <JobShareBanner />

        <Flex gap="8px">
          <TextInput value={link} isDisabled />
          <Button
            leftIcon={<IconDuplicate />}
            variant="secondary"
            onClick={onCopyClick}
          >
            {t('job.share.modal.button.copy')}
          </Button>
        </Flex>

        <Flex gap="8px">
          <ButtonLink
            href={emailLink}
            buttonProps={{ leftIcon: <IconMail />, variant: 'secondary' }}
          >
            {t('job.share.modal.button.email')}
          </ButtonLink>
          <ButtonLink
            href={linkedInLink}
            target="blank"
            buttonProps={{ leftIcon: <IconLinkedIn />, variant: 'secondary' }}
          >
            {t('job.share.modal.button.linkedIn')}
          </ButtonLink>
          <ButtonLink
            href={xLink}
            target="blank"
            buttonProps={{ leftIcon: <IconXCom />, variant: 'secondary' }}
          >
            {t('job.share.modal.button.x')}
          </ButtonLink>
        </Flex>
      </Flex>
    </Modal>
  );
};

const useEmailLink = (link: string) => {
  const { t } = useTranslation('common');

  const subject = encodeURIComponent(t('job.share.modal.social.email.subject'));
  const body = encodeURIComponent(
    t('job.share.modal.social.email.body', { link })
  );

  return `mailto:?subject=${subject}&body=${body}`;
};

const useLinkedInLink = (link: string) => {
  const url = encodeURIComponent(link);

  return `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
};

const useXLink = (link: string) => {
  const { t } = useTranslation('common');

  const url = encodeURIComponent(link);
  const text = encodeURIComponent(t('job.share.modal.social.x.body'));

  return `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
};
