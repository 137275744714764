import { SetRequired } from 'type-fest';

import { Collective_PaymentRequestMemberItemType } from '../../generated';

export const generatePaymentRequestMemberItem = (
  props: SetRequired<
    Partial<Collective_PaymentRequestMemberItemType>,
    'memberId'
  >
): Collective_PaymentRequestMemberItemType => ({
  amount: 1000,
  ...props,
});
