import { Analytics, AnalyticsBrowser } from '@june-so/analytics-next';
import { useEffect, useState } from 'react';

// Following the doc here - https://www.june.so/docs/react
export function useJune(enabled: boolean, writeKey: string) {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const loadAnalytics = async () => {
      const [response] = await AnalyticsBrowser.load({
        writeKey,
      });
      setAnalytics(response);
    };
    loadAnalytics();
  }, [enabled, writeKey]);

  return analytics;
}
