import { PublicPages_GetCaseStudy } from '@collective/data-type';
import { AspectRatio } from '@collective/ui';
import { useBreakpointValue } from '@collective/ui/hooks/media-query/use-breakpoint';
import { RichTextEditorView } from '@collective/ui/lib/inputs/rich-text-editor/rich-text-editor-view';
import { Box, Divider, Flex } from '@collective/ui/lib/layout';
import { Heading } from '@collective/ui/lib/typography/heading';
import { Text } from '@collective/ui/lib/typography/text';
import { variants } from '@collective/ui/themes/components/text';
import { ALL_IDENTIFIER } from '@collective/utils/frontend';

import { HighlightText } from '../highlighting/highlight-text';
import { ImageWrapped } from '../image-wrapped/image-wrapped';
import { PublicCaseStudyInfoFooter } from './public-case-study-info-footer';
import { PublicCaseStudyInfoHeader } from './public-case-study-info-header';
import { PublicCaseStudyTestimonial } from './public-case-study-testimonial';

export type CaseStudyEntity =
  | Omit<
      NonNullable<PublicPages_GetCaseStudy['caseStudy']>,
      'pagination' | 'collective'
    >
  | undefined;

export type PublicCaseStudyProps = {
  caseStudy: CaseStudyEntity;
};

export const CASE_STUDY_CONTENT_WIDTH = '694px';

export const PublicCaseStudy = ({ caseStudy }: PublicCaseStudyProps) => {
  const titleVariant = useBreakpointValue({
    base: 'desktop-xl-bold',
    md: 'desktop-xxl-bold',
  } as const);
  const textVariant = useBreakpointValue({
    base: 'mobile-m-regular',
    md: 'desktop-m-regular',
  } as const);

  if (!caseStudy) {
    return null;
  }

  return (
    <Box pt={{ base: 4, md: 8, lg: 14 }} px="16px">
      <Box margin="auto" maxW={CASE_STUDY_CONTENT_WIDTH}>
        <PublicCaseStudyInfoHeader
          companyInformation={caseStudy.companyInformation}
        />

        <Flex
          mb={6}
          direction={{ base: 'column', md: 'row' }}
          align="start"
          justify="space-between"
          gap="24px"
        >
          {caseStudy.coverImage && (
            <AspectRatio
              ratio={4 / 3}
              w={{ base: '100%', md: '260px' }}
              flexShrink={0}
              order={{ base: 0, md: 1 }}
              border="1px solid"
              borderColor="rythm.200"
              borderRadius="16px"
              overflow="hidden"
            >
              <ImageWrapped
                src={caseStudy.coverImage}
                alt={`${caseStudy.title} cover image`}
                srcSetSizes={['xs', 'sm', 'md']}
                sizes="(min-width: 768px) 260px"
                withSkeleton
              />
            </AspectRatio>
          )}
          <Box>
            <HighlightText identifiers={[ALL_IDENTIFIER]}>
              <Heading
                variant={titleVariant}
                mb={{ base: 3, md: 4 }}
                data-dd-id="caseStudyTitle"
                wordBreak="break-word"
              >
                {caseStudy.title}
              </Heading>
            </HighlightText>
            <HighlightText identifiers={[ALL_IDENTIFIER]}>
              <Text
                variant={textVariant}
                color="rythm.700"
                data-dd-id="caseStudyDescription"
              >
                {caseStudy.description}
              </Text>
            </HighlightText>
          </Box>
        </Flex>
        <PublicCaseStudyTestimonial testimonial={caseStudy.testimonial} />

        <Divider borderColor="rythm.300" my={6} />

        <RichTextEditorView
          content={caseStudy.content || ''}
          data-chromatic="ignore"
          data-dd-id="caseStudyContent"
          customEditorStyles={{ p: variants['desktop-l-regular'] }}
          mb={10}
        />

        <PublicCaseStudyInfoFooter caseStudy={caseStudy} />
      </Box>
    </Box>
  );
};
