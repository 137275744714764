import { Box, BoxProps } from '@collective/ui';

type MemberAreaVerticalWrapperProps = BoxProps & {
  children: React.ReactNode;
};

export const MemberAreaVerticalWrapper = ({
  children,
  ...rest
}: MemberAreaVerticalWrapperProps) => {
  return (
    <Box h="100%" pt={6} pb={32} {...rest}>
      {children}
    </Box>
  );
};
