import { Flex } from '@collective/ui';
import dayjs from 'dayjs';
import { ReactNode } from 'react';

import { JobDetails } from './job-details';
import { JobExpired } from './job-expired';
import { JobExpiredBadge } from './job-expired-badge';
import { JobGeneralInformation } from './job-general-information';
import { JobShare } from './job-share';
import { adaptJobData } from './job-view.adapter';
import { JobViewArgs, JobViewFormattedProps } from './types';

type JobViewCommonProps = {
  cta?: ReactNode;
  boardUrl?: string;
  showExpired?: boolean;
};

type JobViewProps = JobViewCommonProps & JobViewArgs;

export const JobView = ({
  cta,
  boardUrl,
  showExpired,
  ...jobViewProps
}: JobViewProps) => {
  const data = adaptJobData(jobViewProps);

  if (!data) {
    return null;
  }

  const { expiresAt, title } = data;

  if (!showExpired && expiresAt && dayjs().isAfter(expiresAt)) {
    return <JobExpired title={title} boardUrl={boardUrl} />;
  }

  return (
    <FormattedJobView
      cta={cta}
      boardUrl={boardUrl}
      showExpired={showExpired}
      {...data}
    />
  );
};

type FormattedJobViewProps = JobViewFormattedProps & JobViewCommonProps;

const FormattedJobView = (props: FormattedJobViewProps) => {
  const { cta, slug, expiresAt, showExpired } = props;

  return (
    <Flex direction="column" gap="24px" maxW="960px" w="100%">
      {showExpired && <JobExpiredBadge expiresAt={expiresAt} />}
      <JobGeneralInformation {...props} cta={cta} />
      <JobShare slug={slug} />
      <JobDetails {...props} />
    </Flex>
  );
};
