import { number20000 } from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Collective_MilestoneMemberType } from '../../generated';
import { generateMember } from './member';
import { generateUser } from './user';

export const generateMilestoneMember = (
  props?: Partial<Collective_MilestoneMemberType>,
  n = 0
): Collective_MilestoneMemberType => ({
  milestoneId: cuid(),
  memberId: cuid(),
  amount: number20000[n],
  user: generateUser({}, n),
  member: generateMember({}, n),
  ...props,
});
