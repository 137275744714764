import cuid from 'cuid';
import { SetRequired } from 'type-fest';

import { Client_ClientInvoiceType, ClientInvoiceStatus } from '../../generated';
import { generatePaymentRequest } from './payment-request';

export const generateClientInvoice = (
  props: SetRequired<Partial<Client_ClientInvoiceType>, 'reference'>,
  n = 0
): Client_ClientInvoiceType => {
  const paymentRequestId =
    props?.paymentRequestId ?? generatePaymentRequest({}, n).id;

  const referenceNumber = props.reference?.split('-')?.[2] || '1';

  return {
    id: cuid(),
    status: ClientInvoiceStatus.Paid,
    documentId: cuid(),
    paymentRequestId,
    createdAt: '2012-09-25T15:21:10.547Z',
    issueDate: '2012-08-19T15:21:10.547Z',
    dueDate: '2012-09-25T15:21:10.547Z',
    referenceNumber,
    ...props,
  };
};
