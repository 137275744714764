import {
  companyName,
  email,
  firstName,
  lastName,
} from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Collective_CollectiveContactType } from '../../generated';
import { generateCollective } from '.';

export const generateCollectiveContact = (
  props?: Partial<Collective_CollectiveContactType>,
  n = 0
): Collective_CollectiveContactType => {
  return {
    id: cuid(),
    firstname: firstName[n],
    lastname: lastName[n],
    email: email[n],
    company: companyName[n],
    phoneNumber: '+33612345678',
    phoneCountry: 'FR',
    collectiveId: cuid(),
    clientId: cuid(),
    collective: generateCollective(),
    dueAmount: 0,
    ...props,
  };
};
