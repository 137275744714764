import { Dot, Flex, Text } from '@collective/ui';
import { getYearAndMonthDifference } from '@collective/utils/helpers';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

type ProfileDatesProps = {
  startDate?: string | null;
  endDate?: string | null;
  format?: string;
};

export const ProfileDates = ({
  startDate,
  endDate,
  format = 'MMM YYYY',
}: ProfileDatesProps) => {
  const { t } = useTranslation('common');

  if (!startDate) {
    return null;
  }

  const start = dayjs(startDate).format(format);
  const end = endDate
    ? dayjs(endDate).utc(false).format(format)
    : t('profile.dates.present');

  return (
    <Flex align="center" gap="4px">
      <Text color="rythm.700" variant="desktop-s-regular">
        {start} - {end}
      </Text>

      <Duration
        startDate={startDate}
        endDate={endDate || dayjs().toISOString()}
      />
    </Flex>
  );
};

type DurationProps = Pick<ProfileDatesProps, 'startDate' | 'endDate'>;

const Duration = ({ startDate, endDate }: DurationProps) => {
  const { t } = useTranslation('common');

  const result = getYearAndMonthDifference(startDate, endDate);

  if (!result) {
    return null;
  }

  const { years, months } = result;

  const yearLabel = years > 0 ? t('profile.dates.year', { count: years }) : '';
  const monthLabel =
    months > 0 ? t('profile.dates.month', { count: months }) : '';

  const label = [yearLabel, monthLabel].join(' ');

  return (
    <>
      <Dot />
      <Text color="rythm.700" variant="desktop-s-regular">
        {label}
      </Text>
    </>
  );
};
