import { companyName, image } from '@collective/utils/fixtures';
import cuid from 'cuid';

import {
  Collective_CaseStudyCompanyInformationType,
  Industry,
  Segment,
} from '../../generated';

export const generateCaseStudyCompanyInformation = (
  props?: Partial<Collective_CaseStudyCompanyInformationType>,
  n = 0
): Collective_CaseStudyCompanyInformationType => {
  return {
    id: cuid(),
    name: companyName[n],
    picture: image[n],
    segment: props?.segment || Segment.Enterprise,
    industries: props?.industries || [
      { id: cuid(), value: Industry.AgricultureFishery },
      { id: cuid(), value: Industry.ApparelFashion },
    ],
    ...props,
  };
};
