import {
  Collective_GetArchivedProjects,
  Collective_GetOverviewProjects,
} from '../generated';

export const defaultArchivedProjects: Collective_GetArchivedProjects = {
  archivedProjects: {
    completedMissions: [],
    expired: [],
    declined: [],
    negotiationsStopped: [],
  },
};

export const defaultOverviewProjects: Collective_GetOverviewProjects = {
  overviewProjects: {
    matching: [],
    negotiating: [],
    ongoingMissions: [],
  },
};
