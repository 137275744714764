import cuid from 'cuid';

import {
  Collective_OpportunityStatusType,
  OpportunityStatusType,
} from '../../generated';
import { generateUser } from './user';

export const generateOpportunityStatus = (
  props?: Partial<Collective_OpportunityStatusType>,
  n = 0
): Collective_OpportunityStatusType => ({
  id: cuid(),
  opportunityId: cuid(),
  createdByUserId: cuid(),
  createdAt: new Date().toISOString(),
  createdByUser: generateUser(undefined, n),
  status: OpportunityStatusType.AcceptedByTheCollective,
  description: undefined,
  collectiveReasons: [],
  ...props,
});
