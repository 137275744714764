import { Box, Button, Text } from '@collective/ui';
import { Trans, useTranslation } from 'react-i18next';

type EducationEmptyStateProps = {
  onClick: VoidFunction;
};

export const EducationEmptyState = ({ onClick }: EducationEmptyStateProps) => {
  const { t } = useTranslation('common');

  return (
    <Box textAlign="center">
      <Text variant="desktop-m-regular" color="rythm.700" mb={3}>
        <Trans i18nKey="profile.education.empty.text" ns="common" />
      </Text>
      <Button variant="secondary" onClick={onClick}>
        {t('profile.education.empty.button')}
      </Button>
    </Box>
  );
};
