import { number20000 } from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Client_MilestoneMemberType } from '../../generated';
import { generateMember } from './member';
import { generateUser } from './user';

export const generateMilestoneMember = (
  props?: Partial<Client_MilestoneMemberType>,
  n = 0
): Client_MilestoneMemberType => {
  const user = props?.user || generateUser({}, n);

  return {
    milestoneId: cuid(),
    memberId: cuid(),
    amount: number20000[n],
    user,
    member: generateMember({ user }, n),
    ...props,
  };
};
