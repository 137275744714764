import { number20000 } from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Collective_PaymentRequestMilestoneMemberType } from '../../generated';
import { generateMember } from './member';
import { generateUser } from './user';

export const generatePaymentRequestMilestoneMember = (
  props?: Partial<Collective_PaymentRequestMilestoneMemberType>,
  n = 0
): Collective_PaymentRequestMilestoneMemberType => ({
  paymentRequestMilestoneId: cuid(),
  memberId: cuid(),
  amount: number20000[n],
  user: generateUser({}, n),
  member: generateMember({}, n),
  ...props,
});
