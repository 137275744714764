import { number20000, sentence5, sentences2 } from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Collective_PaymentRequestMilestoneType } from '../../generated';
import { generatePaymentRequestMilestoneMember } from './payment-request-milestone-member';

export const generatePaymentRequestMilestone = (
  props?: Partial<Collective_PaymentRequestMilestoneType>,
  n = 0
): Collective_PaymentRequestMilestoneType => {
  const id = cuid();

  return {
    id,
    dueDate: new Date(2023, 11, 7).toISOString(),
    name: sentence5[n],
    description: sentences2[n],
    amount: number20000[n],
    ranking: 0,
    isUpfrontPayment: false,
    milestoneMembers: [
      generatePaymentRequestMilestoneMember(
        { paymentRequestMilestoneId: id },
        n
      ),
    ],
    ...props,
  };
};
