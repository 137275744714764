import {
  BorderBox,
  Box,
  Divider,
  Flex,
  Hide,
  IconHeartFilled,
  Tab,
  TabList,
  Tabs,
  Text,
} from '@collective/ui';
import { ReactNode, RefObject, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetRequired } from 'type-fest';

import { CollectiveApplicationCaseStudyModal } from '../../public-case-study';
import { EducationSection, WorkExperienceSection } from '../sections';
import { UserProfileWorkExperience } from '../types';
import { ProfileBox } from './profile-box';
import { SoloApplicationAboutSection } from './solo-application-about-section';
import { SoloApplicationCover } from './solo-application-cover';
import { SoloApplicationMotionBanner } from './solo-application-motion-banner';
import { SoloApplicationNoteBanner } from './solo-application-note-banner';
import { SoloApplicationPortfolio } from './solo-application-portfolio';
import { SoloApplicationProfileBanner } from './solo-application-profile-banner';
import { SoloApplicationQuestions } from './solo-application-questions';
import { adaptSoloApplicationData } from './solo-application-view.adapter';
import {
  ApplicationViewArgs,
  ApplicationViewFormattedProps,
  CaseStudyType,
} from './types';
import { SoloApplicationMenus } from './utils';

type SoloApplicationProps = {
  parentScrollContainer: RefObject<HTMLDivElement>;
  additionnalActions?: ReactNode;
  // used in the recruiter search to display the tags we showed in the search
  additionalTags?: ReactNode;
  cta?: ReactNode;
  motionEnabled?: boolean;
  showWorkExperienceSection?: boolean;
  showEducationSection?: boolean;
  isCollapsible?: boolean;
  updateQueryParamForPortfolio?: boolean;
};

const CASE_STUDY_SLUG_PARAM = 'caseStudySlug';

export type SoloApplicationViewProps = ApplicationViewArgs &
  SoloApplicationProps;

// Adapt the data from all sources
export const SoloApplicationView = ({
  parentScrollContainer,
  additionnalActions,
  additionalTags,
  cta,
  motionEnabled = true,
  showWorkExperienceSection = false,
  showEducationSection = false,
  isCollapsible = true,
  updateQueryParamForPortfolio = false,
  ...applicationViewArgs
}: SoloApplicationViewProps) => {
  const applicationViewData: ApplicationViewFormattedProps | null =
    adaptSoloApplicationData(applicationViewArgs);

  if (!applicationViewData) {
    return null;
  }

  return (
    <FormattedSoloApplicationView
      {...applicationViewData}
      parentScrollContainer={parentScrollContainer}
      additionnalActions={additionnalActions}
      additionalTags={additionalTags}
      cta={cta}
      motionEnabled={motionEnabled}
      showWorkExperienceSection={showWorkExperienceSection}
      showEducationSection={showEducationSection}
      isCollapsible={isCollapsible}
      updateQueryParamForPortfolio={updateQueryParamForPortfolio}
    />
  );
};

// ----

type FormattedSoloApplicationViewProps = ApplicationViewFormattedProps &
  SetRequired<
    SoloApplicationProps,
    | 'showEducationSection'
    | 'showWorkExperienceSection'
    | 'isCollapsible'
    | 'updateQueryParamForPortfolio'
  > & {
    workExperiences: UserProfileWorkExperience[] | null;
  };

const FormattedSoloApplicationView = ({
  status,
  isFavorite,
  opportunityCaseStudies,
  member,
  questions,
  noteBanner,
  shortlistPricing,
  budgetRange,
  opportunityId,
  parentScrollContainer,
  additionnalActions,
  additionalTags,
  cta,
  motionEnabled,
  workExperiences,
  showWorkExperienceSection,
  educations,
  showEducationSection,
  links,
  isCollapsible,
  updateQueryParamForPortfolio,
  network,
  onLinkClick = () => {},
}: FormattedSoloApplicationViewProps) => {
  const { t } = useTranslation('common');
  const [caseStudy, setCaseStudy] = useState<CaseStudyType | null | undefined>(
    null
  );
  // Can't use lib hooks as the component is used in several context
  // Using useSearchParams doesn't work on next for example
  const [url, setUrl] = useState<URL | undefined>(
    typeof window !== 'undefined'
      ? new URL(window.location.toString())
      : undefined
  );

  const updateQueryParams = useCallback((key: string, value: string | null) => {
    const newUrl = new URL(window.location.toString());

    if (value) {
      newUrl.searchParams.set(key, value);
    } else {
      newUrl.searchParams.delete(key);
    }

    history.replaceState(null, '', newUrl);
    setUrl(newUrl);
  }, []);

  useEffect(() => {
    const searchParamsCaseStudySlug = url?.searchParams.get(
      CASE_STUDY_SLUG_PARAM
    );
    if (
      searchParamsCaseStudySlug &&
      searchParamsCaseStudySlug !== caseStudy?.slug
    ) {
      const caseStudy = opportunityCaseStudies.find(
        ({ slug }) => searchParamsCaseStudySlug === slug
      );

      setCaseStudy(caseStudy);

      if (!caseStudy) {
        updateQueryParams(CASE_STUDY_SLUG_PARAM, null);
      }
    }
  }, [
    caseStudy?.slug,
    opportunityCaseStudies,
    updateQueryParams,
    url?.searchParams,
  ]);

  const shouldDisplayWorkExperiences =
    workExperiences.length > 0 && showWorkExperienceSection;

  const shouldDisplayEducation = educations.length > 0 && showEducationSection;

  const shouldDisplayProfileDataSection =
    shouldDisplayWorkExperiences ||
    shouldDisplayEducation ||
    opportunityCaseStudies.length > 0;

  const updateCaseStudy = (caseStudy: CaseStudyType | null) => {
    setCaseStudy(caseStudy);

    if (updateQueryParamForPortfolio) {
      if (caseStudy?.slug) {
        updateQueryParams(CASE_STUDY_SLUG_PARAM, caseStudy.slug);
      } else {
        updateQueryParams(CASE_STUDY_SLUG_PARAM, null);
      }
    }
  };

  const [tab, setTab] = useState<'experience' | 'portfolio'>(
    shouldDisplayWorkExperiences || shouldDisplayEducation
      ? 'experience'
      : 'portfolio'
  );

  const shouldHideTabs =
    (!shouldDisplayWorkExperiences && !shouldDisplayEducation) ||
    opportunityCaseStudies.length === 0;

  return (
    <Flex
      bg="rythm.100"
      justify="center"
      py={{ base: '24px', md: '40px' }}
      px={{ base: '20px', md: '40px' }}
    >
      <Flex w="100%" maxW="920px" direction="column" gap="24px">
        <BorderBox noHover>
          <SoloApplicationCover
            member={member}
            isFavorite={isFavorite}
            status={status}
          />
          <Box
            bg="white"
            pt={{ base: '32px', md: '52px' }}
            pb={{ base: '20px', md: '36px' }}
            px={{ base: '20px', md: '36px' }}
            w="100%"
            borderBottom="1px solid"
            borderColor="rythm.200"
          >
            <Box w="100%" mx="auto">
              {motionEnabled && (
                <SoloApplicationMotionBanner
                  member={member}
                  parentScrollContainer={parentScrollContainer}
                  isPortfolioPresent={!!opportunityCaseStudies.length}
                  additionnalActions={additionnalActions}
                  shortlistPricing={shortlistPricing}
                  shouldDisplayWorkExperiences={shouldDisplayWorkExperiences}
                  shouldDisplayEducation={shouldDisplayEducation}
                  hasQuestions={!!questions.length}
                />
              )}
              <SoloApplicationProfileBanner
                member={member}
                shortlistPricing={shortlistPricing}
                network={network}
                additionalTags={additionalTags}
                cta={cta}
              />
            </Box>
          </Box>
        </BorderBox>

        {!!noteBanner && (
          <SoloApplicationNoteBanner
            isFavorite={isFavorite}
            noteBanner={noteBanner}
          />
        )}

        {(questions.length > 0 || !!noteBanner) && (
          <ProfileBox>
            {questions.length > 0 && (
              <SoloApplicationQuestions
                opportunityId={opportunityId}
                questions={questions}
                budgetRange={budgetRange}
                shortlistPricing={shortlistPricing}
              />
            )}
          </ProfileBox>
        )}

        <SoloApplicationAboutSection
          member={member}
          links={links}
          onLinkClick={onLinkClick}
        />

        {shouldDisplayProfileDataSection && (
          <>
            {!shouldHideTabs && (
              <Tabs isLazy>
                <TabList p={0}>
                  <Tab
                    onClick={() => setTab('experience')}
                    isSelected={tab === 'experience'}
                    isDisabled={
                      !shouldDisplayWorkExperiences && !shouldDisplayEducation
                    }
                  >
                    {t('profile.tab.experience')}
                  </Tab>
                  <Tab
                    onClick={() => setTab('portfolio')}
                    isSelected={tab === 'portfolio'}
                    isDisabled={opportunityCaseStudies.length === 0}
                  >
                    {t('profile.tab.portfolio')}
                  </Tab>
                </TabList>
              </Tabs>
            )}

            <Box>
              <Box display={tab === 'experience' ? 'block' : 'none'}>
                <ProfileBox>
                  {shouldDisplayWorkExperiences && (
                    <Box id={SoloApplicationMenus.WORK_EXPERIENCE}>
                      <WorkExperienceSection
                        workExperiences={workExperiences}
                        isCollapsible={isCollapsible}
                      />
                    </Box>
                  )}

                  {shouldDisplayEducation && (
                    <Box id={SoloApplicationMenus.EDUCATION}>
                      <EducationSection
                        educations={educations}
                        isCollapsible={isCollapsible}
                      />
                    </Box>
                  )}
                </ProfileBox>
              </Box>
              <Box display={tab === 'portfolio' ? 'block' : 'none'}>
                <ProfileBox>
                  {opportunityCaseStudies.length > 0 && (
                    <SoloApplicationPortfolio
                      caseStudies={opportunityCaseStudies}
                      onClick={updateCaseStudy}
                    />
                  )}
                </ProfileBox>
              </Box>
            </Box>
          </>
        )}

        {isFavorite && (
          <Hide above="md">
            <Divider my={8} mx="auto" w="calc(100% - 2rem)" />
          </Hide>
        )}

        {isFavorite && (
          <Box mt={6} mx="auto" maxW="802px" px={2}>
            <Box
              bg="critical.100"
              color="decorative.fuschia.500"
              w="100%"
              p="20px"
              border="1px solid"
              borderColor="rythm.200"
              borderRadius={12}
              textAlign="center"
            >
              <Box w="fit-content" mx="auto" mb={2}>
                <IconHeartFilled size="xs" />
              </Box>
              <Text variant="desktop-m-regular" color="inherit">
                {t('profile.application.favoriteBanner')}
              </Text>
            </Box>
          </Box>
        )}

        {caseStudy && (
          <CollectiveApplicationCaseStudyModal
            caseStudy={caseStudy}
            setCaseStudy={updateCaseStudy}
          />
        )}
      </Flex>
    </Flex>
  );
};
