import { jobTitle, paragraph2 } from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Client_CollectiveType, Client_MemberType } from '../../generated';
import { generateUser } from './user';

export const generateMember = (
  props?: Partial<Client_MemberType>,
  n = 0
): Client_MemberType => ({
  id: cuid(),
  title: jobTitle[n],
  bio: paragraph2[n],
  pictureUrl: undefined,
  user: generateUser(undefined, n),
  // The cast is to avoid infinite loop where between user<->member<->collective
  collective: props?.collective || ({} as Client_CollectiveType),
  ...props,
});
