import { Flex, FlexProps } from '@collective/ui/lib/layout';

export const PublicPageFooter = (props: FlexProps) => {
  return (
    <Flex
      p="16px 20px"
      bg="white"
      borderTop="1px solid"
      borderColor="rythm.300"
      align="center"
      justify="space-between"
      gap="8px"
      {...props}
    />
  );
};
