import { AnimationControls, useAnimation } from 'framer-motion';
import { once } from 'lodash';
import {
  createContext,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { AnimatedMultiStepLayerProps } from './animated-multi-step-layer';

export type AnimatedMultiStepLayerConsumerProps<Step extends string> = {
  currentStep: Step;
  transitionToStep: (step: Step) => void;
  controls: AnimationControls;
  refs: Record<Step, RefObject<HTMLDivElement>>;
  setStep: (step: Step) => void;
  recalculatedHeightForStep: (step: Step) => void;
  shouldUnmountStep: boolean;
};

/**
 * This is different than most of our existing context because of the generic
 * I didn't find any other way to create a context and being able to have the dynamic type
 * The `once` is here to cache the result of the method and always use the same context instance
 */
export const createAnimatedMultiStepLayerContext = once(<
  Step extends string
>() =>
  createContext<AnimatedMultiStepLayerConsumerProps<Step>>({
    controls: {} as AnimationControls,
    currentStep: '' as Step,
    transitionToStep: () => {},
    refs: {} as Record<Step, RefObject<HTMLDivElement>>,
    setStep: () => {},
    recalculatedHeightForStep: () => {},
    shouldUnmountStep: false,
  })
);
export const useAnimatedMultiStepLayer = <Step extends string>() =>
  useContext(createAnimatedMultiStepLayerContext<Step>());

type AnimatedMultiStepLayerProviderProps<Step extends string> = {
  children: ReactNode;
  isOpen: boolean;
  initialStep?: Step;
  shouldUnmountStep: boolean;
} & Pick<AnimatedMultiStepLayerProps<Step>, 'steps'> &
  Pick<AnimatedMultiStepLayerConsumerProps<Step>, 'refs'>;

export const AnimatedMultiStepLayerProvider = <Step extends string>({
  children,
  isOpen,
  refs,
  steps,
  initialStep,
  shouldUnmountStep,
}: AnimatedMultiStepLayerProviderProps<Step>) => {
  const AnimatedMultiStepLayerContext =
    createAnimatedMultiStepLayerContext<Step>();

  const [currentStep, setCurrentStep] = useState<Step>(initialStep || steps[0]);
  const controls = useAnimation();

  useEffect(() => {
    setCurrentStep(initialStep || steps[0]);
  }, [steps, initialStep]);

  const stepSizes = useRef<Record<string, number>>(
    Object.fromEntries(steps.map((stepName) => [stepName, 0]))
  );

  // Since the component is usually used in a modal
  // When we close it, we reset the current step to the initial one
  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(initialStep || steps[0]);
      steps.forEach((step) => {
        stepSizes.current[step] = 0;
      });
      controls.start({ height: stepSizes.current[initialStep || steps[0]] });
    }
  }, [initialStep, isOpen, steps, controls]);

  useEffect(() => {
    let sizeDefinition: NodeJS.Timeout;
    // After a small delay after each step display, all the step heights are recomputed for the animations
    if (isOpen) {
      sizeDefinition = setTimeout(() => {
        steps.forEach((step) => {
          stepSizes.current[step] =
            refs[step].current?.getBoundingClientRect().height || 0;
        });
      }, 500);
    }

    return () => clearTimeout(sizeDefinition);
  }, [isOpen, refs, steps]);

  const transitionToStep = useCallback(
    (step: Step) => {
      setCurrentStep(step);
      controls.start({
        height: stepSizes.current[step],
        transitionEnd: {
          height: 'auto',
        },
      });
    },
    [controls]
  );

  const recalculatedHeightForStep = useCallback(
    (step: Step) => {
      setTimeout(() => {
        stepSizes.current[step] =
          refs[step].current?.getBoundingClientRect().height || 0;
      }, 500);
    },
    [refs]
  );

  const values = {
    currentStep,
    transitionToStep,
    controls,
    refs,
    setStep: setCurrentStep,
    recalculatedHeightForStep,
    shouldUnmountStep,
  };

  return (
    <AnimatedMultiStepLayerContext.Provider value={values}>
      {children}
    </AnimatedMultiStepLayerContext.Provider>
  );
};
