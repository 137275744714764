import { useFontFaceObserver } from '@collective/ui/hooks/use-font-face-observer';
import { Box, BoxProps, Flex } from '@collective/ui/lib/layout';
import { Tag } from '@collective/ui/lib/tag/tag';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

const SKILLS_ROW_GAP_SIZE = 8;
const ELLIPSIS_TAG_WIDTH = 24;

type ShortlistTagListProps = {
  skills: string[];
} & BoxProps;

// For SSR useLayoutEffect is giving a warning, so for server execution we are using useEffect
const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export const TagList = ({ skills, ...rest }: ShortlistTagListProps) => {
  const [shownSkills, setShownSkills] = useState(skills);
  const skillsRef = useRef<HTMLDivElement>(null);
  const [displayEllipsis, setDisplayEllipsis] = useState(false);

  const isFontListLoaded = useFontFaceObserver('Inter');

  useIsomorphicLayoutEffect(() => {
    /** Needed otherwise some calculation can work on Arial and not on Inter */
    if (skills.length && skillsRef.current && isFontListLoaded) {
      const { height } = skillsRef.current.getBoundingClientRect();
      const { height: parentHeight, width: parentWidth } =
        skillsRef.current.parentElement!.getBoundingClientRect();

      // if the tag container is bigger than the parent, it means we overflow
      if (height >= parentHeight) {
        // We remove all skills overflowing
        const newSkillsShown = Array.from(
          skillsRef.current.children as unknown as HTMLElement[]
        )
          .filter((node: HTMLElement) => {
            // We add some calculation to have space for the ... tag
            // Otherwise it can go on the next line
            const offsetRight = node.offsetLeft + node.clientWidth;
            const isFirstLine = node.offsetTop === 0;
            const hasSpaceForEllipsis =
              isFirstLine ||
              (!isFirstLine && parentWidth - offsetRight > ELLIPSIS_TAG_WIDTH);

            return (
              node.offsetTop < parentHeight &&
              hasSpaceForEllipsis &&
              node.textContent
            );
          })
          .map(({ textContent }) => textContent as string);

        // We set this filtered skills as what we should display and we had the ellipsis tag
        // If we have the same length, it means we don't overflow
        if (newSkillsShown.length !== skills.length) {
          setShownSkills(newSkillsShown);
          setDisplayEllipsis(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- switch from warn to error
  }, [skillsRef, isFontListLoaded]);

  return (
    <Box position="relative" overflow="hidden" {...rest}>
      <Flex
        ref={skillsRef}
        wrap="wrap"
        rowGap={`${SKILLS_ROW_GAP_SIZE}px`}
        columnGap="4px"
      >
        {shownSkills.map((tag, index) => (
          <Tag
            key={tag + index}
            label={tag}
            color="rythm.700"
            bg="rythm.100"
            px="6px"
          />
        ))}
        {displayEllipsis && (
          <Box w={0} overflow="visible">
            <Tag label="..." color="rythm.700" bg="rythm.100" px="5px" />
          </Box>
        )}
      </Flex>
    </Box>
  );
};
