import cuid from 'cuid';

import {
  Collective_MemberInvoiceType,
  MemberInvoiceStatus,
} from '../../generated';
import { generateMember } from './member';
import { generatePaymentRequest } from './payment-request';
import { generateUser } from './user';

export const generateMemberInvoice = (
  props: Partial<Collective_MemberInvoiceType>,
  n = 0,
  centsAmount = true
): Collective_MemberInvoiceType => {
  const paymentRequest = props.paymentRequest ?? generatePaymentRequest({}, n);
  const user = props.user ?? generateUser({}, n);
  const member = props.member ?? generateMember({}, n);
  return {
    id: cuid(),
    reference: 'DWX-PRUI-7257-558',
    amount: centsAmount ? 585799 : 5857.99,
    issueDate: '2012-08-19T15:21:10.547Z',
    dueDate: '2012-09-25T15:21:10.547Z',
    status: props?.status ?? MemberInvoiceStatus.AwaitingClientPayment,
    paymentRequest,
    paymentRequestId: paymentRequest.id,
    documentId: cuid(),
    user,
    member,
    userId: user.id,
    isSolo: false,
    invoiceFeeAmount: 0,
    ...props,
  };
};
