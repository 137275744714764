import { AvatarGroup, Tag } from '@collective/ui';
import { Box, Flex } from '@collective/ui/lib/layout';
import { Text } from '@collective/ui/lib/typography/text';
import { displayName, projectTypeUtils } from '@collective/utils/shared';
import dayjs from 'dayjs';
import { upperFirst } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';

import { CaseStudyEntity } from './public-case-study';

type PublicCaseStudyInfoFooterType = {
  caseStudy: CaseStudyEntity;
};

const DATE_FORMAT = 'MMM YYYY';

export const PublicCaseStudyInfoFooter = ({
  caseStudy,
}: PublicCaseStudyInfoFooterType) => {
  const { t } = useTranslation('public-pages');
  const { t: commonT } = useTranslation('common');

  if (!caseStudy?.projectOrganisation) {
    return null;
  }

  const getProjectTypeTranslation =
    projectTypeUtils.getTranslationMapping(commonT);

  const start = caseStudy.projectOrganisation.startDate;
  const end = caseStudy.projectOrganisation.endDate;
  const startDate = start && upperFirst(dayjs(start).format(DATE_FORMAT));
  const endDate = end && upperFirst(dayjs(end).format(DATE_FORMAT));

  return (
    <Flex
      direction="column"
      gap={4}
      p="20px"
      border="1px solid"
      borderColor="rythm.200"
      borderRadius="16px"
    >
      <Flex align="center" justify="space-between" gap="8px">
        <Text>{t('caseStudyPage.footer.members')}</Text>
        <AvatarGroup
          avatars={caseStudy.projectOrganisation.users.map((user) => ({
            id: user.id,
            name: displayName(user),
            src: user.pictureUrl,
          }))}
          max={3}
          size="sm"
          borderColor="rythm.900"
          data-chromatic="ignore"
        />
      </Flex>

      <Flex
        align={{ base: 'start', md: 'center' }}
        justify="space-between"
        gap="8px"
      >
        <Text>{t('caseStudyPage.footer.expertises')}</Text>
        <Flex direction={{ base: 'column', md: 'row' }} align="end" gap={1}>
          {caseStudy.projectOrganisation.projectTypes.map(({ code }) => (
            <Tag
              key={code}
              label={getProjectTypeTranslation(code)}
              color="rythm.700"
              bg="rythm.100"
            />
          ))}
        </Flex>
      </Flex>

      {startDate && endDate && (
        <Flex
          align="center"
          justify="space-between"
          gap="8px"
          data-chromatic="ignore"
        >
          <Text>{t('caseStudyPage.footer.fromDates')}</Text>
          <Text>
            <Trans
              ns="public-pages"
              i18nKey="caseStudyPage.footer.dateSpan"
              components={{
                gray: <Box as="span" color="rythm.600" />,
              }}
              values={{
                startDate,
                endDate,
              }}
            />
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
