import {
  Collective_CollectiveType,
  Collective_GetMe,
} from '@collective/data-type';
import { getCookie, getLogger } from '@collective/utils/frontend';
import { IMPERSONATE_INFO_COOKIE_NAME } from '@collective/utils/helpers';
import { displayName } from '@collective/utils/shared';
import { useEffect } from 'react';
import {
  IntercomProps,
  useIntercom as useIntercomHook,
} from 'react-use-intercom';

import { COLLECTIVE_TYPE } from './constants';

export const useIntercom = (
  me: Collective_GetMe['me'] | undefined,
  toIgnorePatterns: string[]
) => {
  const { boot, update, shutdown } = useIntercomHook();

  useEffect(() => {
    // always shutdown intercom if no user after a user was there
    if (!me) {
      shutdown();
      return;
    }

    // ignore some users for intercom
    const shouldIgnoreUser = toIgnorePatterns?.some((pattern) =>
      me.email.includes(pattern)
    );
    const impersonateCookie = getCookie(IMPERSONATE_INFO_COOKIE_NAME);

    if (shouldIgnoreUser || impersonateCookie) {
      // don't show the intercom provider at all
      shutdown();
      return;
    }

    const collectives = me?.members
      ?.map((m) => m.collective)
      .filter((c) => c) as Collective_CollectiveType[] | undefined;
    // abstraction to group users in intercom
    const intercomCompanies = collectives?.map((c) => ({
      companyId: c.id,
      name: c.name,
      customAttributes: {
        slug: c.slug,
        type: COLLECTIVE_TYPE,
      },
    }));
    // the current company is always the first one for now as users only belong
    // to one collective for now
    const currentIntercomCompany = intercomCompanies?.[0];

    getLogger('intercom').debug('loading intercom with user', { user: me });

    const intercomProps: IntercomProps = {
      name: displayName(me),
      email: me.email,
      userId: me.id,
      userHash: me.intercomHash || undefined,
      avatar: {
        type: 'avatar',
        imageUrl: me.pictureUrl || undefined,
      },
      company: currentIntercomCompany,
      companies: intercomCompanies,
      languageOverride: me.language || undefined,
      customAttributes: {
        type: COLLECTIVE_TYPE,
      },
    };

    // boot and update a new instance of intercom
    // note: double booting is not a problem so this works really nicely
    boot(intercomProps);
    update(intercomProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- switch from warn to error
  }, [boot, shutdown, update, me]);
};
