import { Badge } from '@collective/ui';
import utilsDate from '@collective/utils/date';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

type JobExpireBadgeProps = {
  expiresAt: string;
};

export const JobExpireBadge = ({ expiresAt }: JobExpireBadgeProps) => {
  const data = useBadgeData(expiresAt);

  return (
    <Badge size="md" colorScheme={data.colorScheme}>
      {data.label}
    </Badge>
  );
};

const useBadgeData = (expiresAt: string) => {
  const { t } = useTranslation('common');

  const dateFormat = utilsDate.getShortDateFormat();
  const timeFormat = utilsDate.getTimeFormat();

  switch (true) {
    case utilsDate.isInPast(expiresAt):
      return {
        label: t('job.details.keyInformation.expirationDate.value.expired', {
          date: dayjs.utc(expiresAt).format(dateFormat),
        }),
        colorScheme: 'rythm',
      };
    case utilsDate.isToday(expiresAt):
      return {
        label: t('job.details.keyInformation.expirationDate.value.today', {
          time: dayjs.utc(expiresAt).format(timeFormat),
        }),
        colorScheme: 'critical',
      };
    case utilsDate.isTomorrow(expiresAt):
      return {
        label: t('job.details.keyInformation.expirationDate.value.tomorrow', {
          time: dayjs.utc(expiresAt).format(timeFormat),
        }),
        colorScheme: 'critical',
      };
    case utilsDate.isThisWeek(expiresAt):
      return {
        label: t('job.details.keyInformation.expirationDate.value.day', {
          day: dayjs.utc(expiresAt).format('dddd'),
          time: dayjs.utc(expiresAt).format(timeFormat),
        }),
        colorScheme: 'caution',
      };
    default:
      return {
        label: t('job.details.keyInformation.expirationDate.value.date', {
          date: dayjs.utc(expiresAt).format(dateFormat),
          time: dayjs.utc(expiresAt).format(timeFormat),
        }),
        colorScheme: 'caution',
      };
  }
};
