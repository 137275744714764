import { BorderBox, Box, RichTextEditorView } from '@collective/ui';
import { useTranslation } from 'react-i18next';

import { JobProjectKeyInformation } from './job-project-key-information';
import { JobSection } from './job-section';
import { JobViewFormattedProps } from './types';
import { useHighlightedDescription } from './use-highlighted-description';

type JobDetailsProps = JobViewFormattedProps;

export const JobDetails = (props: JobDetailsProps) => {
  const { t } = useTranslation('common');

  const { company } = props;

  const description = useHighlightedDescription(props.description);

  return (
    <BorderBox
      noHover
      p="20px"
      display="flex"
      flexDirection="column"
      gap="24px"
    >
      {!!company?.description && (
        <JobSection label={t('job.details.company.label')}>
          <Box whiteSpace="pre-line">{company.description}</Box>
        </JobSection>
      )}

      <JobProjectKeyInformation {...props} />

      <JobSection label={t('job.details.description.label')}>
        <RichTextEditorView
          content={description}
          customEditorStyles={{
            'p:not(:first-of-type)': { 'margin-top': '16px' },
          }}
        />
      </JobSection>
    </BorderBox>
  );
};
