import {
  AUTHENTICATION_ERROR,
  GRAPHQL_VALIDATION_FAILED,
  NETWORK_ERROR,
  REFRESH_ERROR,
} from '@collective/utils/errors';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { GraphQLError } from 'graphql';

import { refresh } from './refresh';

export const axiosInstance = axios.create({
  baseURL: `${process.env.NX_BACKEND_URL}/graphql`,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  async (response: AxiosResponse<AxiosGraphqlResponse<never>>) => {
    if (response.data.errors) {
      const hasUnauthenticatedError = response.data.errors.find(
        (error) => error.message === AUTHENTICATION_ERROR
      );
      const isRetry = response.config.params?.retry;
      // Refreshing token if unauthenticated error
      // and not a retry (prevent infinite loops if refresh token is invalid)
      if (hasUnauthenticatedError && !isRetry) {
        await refresh();

        // Relaunching original request
        return axiosInstance({ ...response.config, params: { retry: true } });
      } else if (hasUnauthenticatedError) {
        throw new Error(REFRESH_ERROR);
      }
    }
    return response;
  },
  (err: AxiosError<AxiosGraphqlResponse<never>>) => {
    // Detect Axios network errors: https://github.com/axios/axios/issues/383
    if (axios.isAxiosError(err) && !err.response) {
      throw new Error(NETWORK_ERROR);
    }

    // Detect GraphQL validation errors that result from the frontend/backend schemas not matching
    const hasValidationError =
      err.response?.data?.errors?.find(
        (error: Error) => error.message === GRAPHQL_VALIDATION_FAILED
      ) ?? false;

    if (hasValidationError) {
      throw new Error(GRAPHQL_VALIDATION_FAILED);
    }

    throw err;
  }
);

export type AxiosGraphqlResponse<T> = {
  data: T;
  errors?: readonly GraphQLError[];
};

export const axiosGql = async <T, V = Record<string, never>>(
  query: string,
  variables?: V,
  config?: AxiosRequestConfig
): Promise<AxiosGraphqlResponse<T>> => {
  const response = await axiosInstance.post<AxiosGraphqlResponse<T>>(
    '',
    { query, variables },
    config
  );

  if (response?.data?.errors) {
    throw new Error(response.data.errors?.[0]?.message);
  }

  return {
    data: response?.data?.data,
  };
};

export function authorisationHeader(
  token?: string | null
): Pick<AxiosRequestConfig, 'headers'> {
  return { headers: { Authorization: `Bearer ${token}` } };
}
