import cuid from 'cuid';

import { Collective_OpportunityType, OpportunityStage } from '../../generated';
import { generateCollective } from './collective';

export const generateOpportunity = (
  props?: Partial<Collective_OpportunityType>
): Collective_OpportunityType => {
  return {
    id: cuid(),
    projectId: cuid(),
    collectiveId: cuid(),
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    statuses: [],
    updates: [],
    platformFees: 10,
    projectTypes: props?.projectTypes || [],
    questions: [],
    caseStudies: [],
    members: [],
    membersInvited: [],
    shares: [],
    collective: generateCollective(props?.collective),
    isAnonymized: false,
    stage: OpportunityStage.Matching,
    ...props,
  };
};
