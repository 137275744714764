import { jobTitle, sentences2 } from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Collective_ProjectPositionType } from '../../generated';

export const generateProjectPosition = (
  props?: Partial<Collective_ProjectPositionType>,
  n = 0
): Collective_ProjectPositionType => {
  return {
    id: cuid(),
    title: jobTitle[n],
    quantity: n + 1,
    budgetBrief: null,
    expectedStartDate: null,
    description: sentences2[n],
    questions: [],
    ...props,
  };
};
