import {
  city,
  companyName,
  dateBetween1900And2000,
  image,
  jobTitle,
  languages,
  sentence20,
} from '@collective/utils/fixtures';
import cuid from 'cuid';

import {
  Collective_ProfileEducationType,
  Collective_ProfileLanguageType,
  Collective_ProfileType,
  Collective_ProfileWorkExperienceType,
  ProfileLanguageLevel,
  ProfileStatus,
} from '../../generated';

export const generateProfile = (
  props?: Partial<Collective_ProfileType>,
  n = 0
): Collective_ProfileType => ({
  id: cuid(),
  languages: [],
  status: ProfileStatus.Enabled,
  title: jobTitle[n],
  experienceYears: 5,
  workExperiences: [],
  education: [],
  links: [],
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  ...props,
});

export const generateProfileLanguages = (
  props?: Partial<Collective_ProfileLanguageType>,
  n = 0
): Collective_ProfileLanguageType => ({
  id: cuid(),
  language: languages[n],
  level: ProfileLanguageLevel.Fluent,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  ...props,
});

export const generateProfileWorkExperience = (
  props?: Partial<Collective_ProfileWorkExperienceType>,
  n = 0
): Collective_ProfileWorkExperienceType => {
  const start = new Date(dateBetween1900And2000[n]);
  const end = new Date(start.getTime() + 1000 * 3600 * 24 * 90); // + 3 months

  return {
    id: cuid(),
    title: jobTitle[n],
    company: companyName[n],
    description: sentence20[n],
    location: city[n],
    logoUrl: image[n],
    startDate: start.toISOString(),
    endDate: end.toISOString(),
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    ...props,
  };
};

export const generateProfileEducation = (
  props?: Partial<Collective_ProfileEducationType>,
  n = 0
): Collective_ProfileEducationType => {
  const start = new Date(dateBetween1900And2000[n]);
  const end = new Date(start.getTime() + 1000 * 3600 * 24 * 90); // + 3 months

  return {
    id: cuid(),
    school: companyName[n],
    description: sentence20[n],
    logoUrl: image[n],
    startDate: start.toISOString(),
    endDate: end.toISOString(),
    ...props,
  };
};
