import { sentence1 } from '@collective/utils/fixtures';
import cuid from 'cuid';
import dayjs from 'dayjs';

import {
  Client_ProposalType,
  ProposalPaymentOption,
  ProposalStatusType,
} from '../../generated';
import { generateMember } from './member';
import { generateMission } from './mission';
import { generateProject } from './project';

export const generateProposal = (
  props?: Partial<Client_ProposalType>,
  n = 0
): Client_ProposalType => {
  const mission = props?.mission || generateMission();

  return {
    id: cuid(),
    createdByMemberId: cuid(),
    projectId: cuid(),
    name: sentence1[n],
    status: ProposalStatusType.Draft,
    paymentOption: ProposalPaymentOption.Milestones,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    expiresAt: dayjs().add(3, 'days').toISOString(),
    milestones: [],
    payAsYouGoMembers: [],
    createdByMember: props?.createdByMember || generateMember(),
    project: props?.project || generateProject(),
    mission,
    ...props,
  };
};
