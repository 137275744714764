import {
  alphaNumeric10,
  city,
  companyName,
  country,
  secondaryAddress,
  streetAddress,
  zipCode,
} from '@collective/utils/fixtures';

import {
  Collective_UserCompanyInformationResultType,
  CountryCode,
} from '../../generated';

export const generateUserCompanyInformation = (
  props?: Partial<Collective_UserCompanyInformationResultType>,
  n = 0
): Collective_UserCompanyInformationResultType => ({
  name: companyName[n],
  companyRegistrationId: alphaNumeric10[n],
  address: streetAddress[n],
  addressAdditionalInformation: secondaryAddress[n],
  city: city[n],
  zipCode: zipCode[n],
  country: country[n] as CountryCode,
  isChargingVat: true,
  vatNumber: alphaNumeric10[n + 1],
  ...props,
});
