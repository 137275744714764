import { alert, BorderBox, Flex, Heading, Image, Text } from '@collective/ui';
import { Trans, useTranslation } from 'react-i18next';

import { ButtonLink } from '../button-link/button-link';

type JobExpiredProps = {
  title: string;
  boardUrl?: string;
};

export const JobExpired = ({ title, boardUrl }: JobExpiredProps) => {
  const { t } = useTranslation('common');

  return (
    <BorderBox w="100%" maxW="960px" mt="96px" noHover>
      <Flex direction="column" align="center" gap="16px" p="20px">
        <Image src={alert} />
        <Heading variant="desktop-l-semibold">
          {t('job.expired.header')}
        </Heading>
        <Text color="rythm.700" align="center">
          <Trans
            i18nKey="job.expired.text"
            ns="common"
            values={{ title }}
            components={{
              bold: <Text as="span" color="inherit" variant="desktop-m-bold" />,
            }}
          />
        </Text>
        {boardUrl && (
          <ButtonLink href={boardUrl}>{t('job.expired.button')}</ButtonLink>
        )}
      </Flex>
    </BorderBox>
  );
};
