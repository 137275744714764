import {
  dateBetween1900And2000,
  number20000,
  sentence5,
  sentences2,
} from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Client_MilestoneType } from '../../generated';
import { generateMilestoneMember } from './milestone-member';
import { generateProposal } from './proposal';

export const generateMilestone = (
  props?: Partial<Client_MilestoneType>,
  n = 0
): Client_MilestoneType => {
  const id = cuid();
  // to not generate the proposal if it's provided as it can lead to a loop
  const proposal = props?.proposal || generateProposal(props?.proposal);

  return {
    id,
    proposalId: cuid(),
    dueDate: dateBetween1900And2000[n],
    name: sentence5[n],
    description: sentences2[n],
    amount: number20000[n],
    ranking: 0,
    milestoneMembers: [generateMilestoneMember({ milestoneId: id }, n)],
    proposal,
    isUpfrontPayment: false,
    ...props,
  };
};
