import cuid from 'cuid';
import dayjs from 'dayjs';

import { Collective_CaseStudyProjectOrganisationType } from '../../generated';

export const generateCaseStudyProjectOrganisation = (
  props?: Partial<Collective_CaseStudyProjectOrganisationType>
): Collective_CaseStudyProjectOrganisationType => {
  return {
    id: cuid(),
    startDate: dayjs().subtract(5, 'days').toISOString(),
    endDate: new Date().toISOString(),
    users: props?.users || [],
    projectTypes: props?.projectTypes || [],
    ...props,
  };
};
