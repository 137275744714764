import { faker } from '@faker-js/faker';

import images from '../data/images.json';

export type ImageType = 'people' | 'business';

export const getImage = (type: ImageType, index: number): string | null =>
  images[type][index] || null;

export const getRandomImageUrl = (type: ImageType): string =>
  faker.helpers.arrayElement(images[type]);
