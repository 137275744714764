import { createContext, ReactNode, useContext, useState } from 'react';

import { HighlightIdentifiers } from './highlight.utils';

type DataSearchContextProps = {
  highlightedWords: string[];
  setHighlightedWords: (words: string[]) => void;
  highlightIdentifiers: HighlightIdentifiers;
  setHighlightIdentifiers: (identifiers: HighlightIdentifiers) => void;
};

export const HighlightContext = createContext<DataSearchContextProps>({
  highlightedWords: [],
  setHighlightedWords: () => {},
  highlightIdentifiers: {},
  setHighlightIdentifiers: () => {},
});

type HighlightContextProviderProps = {
  children: ReactNode;
  initialHighlightedWords?: string[];
};

export const HighlightContextProvider = ({
  children,
  initialHighlightedWords = [],
}: HighlightContextProviderProps) => {
  const [highlightedWords, setHighlightedWords] = useState<string[]>(
    initialHighlightedWords
  );
  const [highlightIdentifiers, setHighlightIdentifiers] =
    useState<HighlightIdentifiers>({});

  const props: DataSearchContextProps = {
    highlightedWords,
    setHighlightedWords,
    highlightIdentifiers,
    setHighlightIdentifiers,
  };

  return (
    <HighlightContext.Provider value={props}>
      {children}
    </HighlightContext.Provider>
  );
};

export const useHighlight = () => useContext(HighlightContext);
