import {
  tryMultipleAttempts,
  TryMultipleAttemptsStartProps,
} from '@collective/utils/helpers';
import { useEffect, useMemo } from 'react';

export const useTryMultipleAttempts = () => {
  const tryMultipleAttemptsFn = useMemo<{
    start: (props: TryMultipleAttemptsStartProps) => void;
    reset: () => void;
  }>(() => tryMultipleAttempts(), []);

  useEffect(() => {
    // To clean on unmount
    return () => {
      tryMultipleAttemptsFn.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- switch from warn to error
  }, []);

  return tryMultipleAttemptsFn;
};
