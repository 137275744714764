import cuid from 'cuid';
import dayjs from 'dayjs';
import { times } from 'lodash';

import { Client_MissionType, Client_ProposalType } from '../../generated';
import { generateCollective } from './collective';
import { generateMilestone } from './milestone';
import { generatePaymentRequest } from './payment-request';
import { generatePaymentRequestMilestone } from './payment-request-milestone';
import { generateProject } from './project';

export const generateMission = (
  props?: Partial<Client_MissionType>,
  n = 0
): Client_MissionType => {
  const project = props?.project || generateProject({}, n);
  // empty object to avoid loop between mission <-> proposal
  const proposal = props?.proposal || ({} as Client_ProposalType);
  const collective = props?.collective || generateCollective({}, n);

  const upcomingMilestones =
    props?.upcomingMilestones ||
    times(2, (i) =>
      generateMilestone(
        {
          proposalId: proposal.id,
          proposal,
        },
        i + n
      )
    );
  const completedMilestones = times(2, (i) =>
    generatePaymentRequestMilestone(
      {
        dueDate: dayjs().subtract(15, 'days').toISOString(),
      },
      i + n
    )
  );

  return {
    id: cuid(),
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    upcomingMilestones,
    paymentRequests: props?.paymentRequests ?? [
      generatePaymentRequest({
        milestones: completedMilestones,
        mission: {} as Client_MissionType,
      }),
    ],
    project,
    proposal,
    members: [],
    collective,
    isReviewSubmitted: false,
    ...props,
  };
};
