import { Industry } from '@collective/data-type';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useIndustriesTranslationMapping() {
  const { t } = useTranslation('common');
  return useMemo(() => getIndustriesTranslationMapping(t), [t]);
}

export function getIndustriesTranslationMapping(
  t: TFunction
): Record<Industry, string> {
  return {
    [Industry.AccountingLegal]: t('industry.accountingLegal'),
    [Industry.AgricultureFishery]: t('industry.agricultureFishery'),
    [Industry.AiCloudMachineLearning]: t('industry.aiCloudMachineLearning'),
    [Industry.ApparelFashion]: t('industry.apparelFashion'),
    [Industry.ArtLeisure]: t('industry.artLeisure'),
    [Industry.Automotive]: t('industry.automotive'),
    [Industry.AviationDefenseAerospace]: t('industry.aviationDefenseAerospace'),
    [Industry.ChemicalsMaterials]: t('industry.chemicalsMaterials'),
    [Industry.Construction]: t('industry.construction'),
    [Industry.ConsumerGoods]: t('industry.consumerGoods'),
    [Industry.Cosmetics]: t('industry.cosmetics'),
    [Industry.Cybersecurity]: t('industry.cybersecurity'),
    [Industry.Education]: t('industry.education'),
    [Industry.FinanceServices]: t('industry.financialServices'),
    [Industry.FoodBeverage]: t('industry.foodBeverage'),
    [Industry.GamingGambling]: t('industry.gamingGambling'),
    [Industry.HealthcarePharmaBiotech]: t('industry.healthPharmaBiotech'),
    [Industry.HostelRestaurantsTourism]: t('industry.hostelRestaurantsTourism'),
    [Industry.HrFutureOfWork]: t('industry.hrFutureOfWork'),
    [Industry.ItServicesConsulting]: t('industry.itServicesConsulting'),
    [Industry.Luxury]: t('industry.luxury'),
    [Industry.Media]: t('industry.media'),
    [Industry.NonProfit]: t('industry.nonProfit'),
    [Industry.OilEnergy]: t('industry.oilEnergy'),
    [Industry.Other]: t('industry.other'),
    [Industry.PublicSector]: t('industry.publicSector'),
    [Industry.RealEstate]: t('industry.realEstate'),
    [Industry.RetailDistribution]: t('industry.retailDistribution'),
    [Industry.Sport]: t('industry.sport'),
    [Industry.TechSoftware]: t('industry.techSoftware'),
    [Industry.Telecoms]: t('industry.telecoms'),
    [Industry.TransportLogistics]: t('industry.transportLogistics'),
    [Industry.Web3]: t('industry.web3'),
  };
}
