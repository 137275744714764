import cuid from 'cuid';

import { Collective_ProposalMemberType } from '../../generated';
import { generateMember } from './member';

export const generateProposalMember = (
  props?: Partial<Collective_ProposalMemberType>,
  n = 0
): Collective_ProposalMemberType => ({
  memberId: cuid(),
  proposalId: cuid(),
  member: generateMember(undefined, n),
  ...props,
});
