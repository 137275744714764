import cuid from 'cuid';

import { Client_ShortlistOpportunityType } from '../../generated';
import { generateClientCollective } from './client-collective';

export const generateClientOpportunity = (
  props?: Partial<Client_ShortlistOpportunityType>,
  n = 0
): Client_ShortlistOpportunityType => {
  return {
    id: cuid(),
    collective: generateClientCollective({}, n),
    statuses: [],
    opportunityCaseStudies: [],
    opportunityMembers: [],
    opportunityMembersInvited: [],
    opportunityQuestions: [],
    platformFees: 0,
    isFavorite: false,
    shortlistSettings: {
      opportunityCard: [],
    },
    ...props,
  };
};
