import { paragraphs1 } from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Chat_ChannelMessageType } from '../../generated';

export const generateChannelMessage = (
  props?: Partial<Chat_ChannelMessageType>,
  n = 0
): Chat_ChannelMessageType => {
  return {
    id: cuid(),
    channelId: cuid(),
    userId: cuid(),
    content: paragraphs1[n],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    attachments: [],
    reactions: [],
    linkPreviews: [],
    ...props,
  };
};
