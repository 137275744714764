import { supportedLanguages } from '@collective/translation';
import {
  Badge,
  Flex,
  IconInfo,
  IconMoney,
  Text,
  Tooltip,
} from '@collective/ui';
import { useTranslation } from 'react-i18next';

import {
  IdealStartDateRow,
  JobExpirationDateRow,
  JobLocationRow,
  JobWorkPreferencesRow,
} from './job-key-information-rows';
import { JobSection } from './job-section';
import { JobViewFormattedProps } from './types';

type JobProjectKeyInformationProps = JobViewFormattedProps;

export const JobProjectKeyInformation = ({
  budgetBrief,
  idealStartDate,
  location,
  workPreferences,
  expiresAt,
}: JobProjectKeyInformationProps) => {
  const { t } = useTranslation('common');

  if (
    !budgetBrief &&
    !idealStartDate &&
    !expiresAt &&
    !location.fr &&
    !location.en &&
    !workPreferences.length
  ) {
    return null;
  }

  return (
    <JobSection label={t('job.details.keyInformation.label')}>
      <Flex direction="column" gap="16px">
        {budgetBrief && (
          <Flex gap={2} wrap="wrap" align="center">
            <IconMoney color="rythm.700" />
            <Text color="rythm.700">
              {t('job.details.keyInformation.budget.label')}
            </Text>
            <Text minH="26px" lineHeight="26px">
              {budgetBrief}
            </Text>
            <Badge size="md" colorScheme="success">
              {t('job.details.keyInformation.budget.badge')}
            </Badge>
            <Tooltip label={t('job.details.keyInformation.budget.tooltip')}>
              <IconInfo color="rythm.600" size="xs" />
            </Tooltip>
          </Flex>
        )}

        <JobLocationRow
          location={location.fr}
          language={supportedLanguages.fr}
        />
        <JobLocationRow
          location={location.en}
          language={supportedLanguages.en}
        />
        <IdealStartDateRow idealStartDate={idealStartDate} />
        <JobWorkPreferencesRow workPreferences={workPreferences} />
        <JobExpirationDateRow expiresAt={expiresAt} />
      </Flex>
    </JobSection>
  );
};
