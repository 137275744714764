import {
  companyBs,
  companyName,
  paragraphs1,
} from '@collective/utils/fixtures';
import cuid from 'cuid';
import slugify from 'slugify';

import { Admin_CollectiveType, PublicStatus } from '../../generated';
import { generateMember } from './member';

export const generateCollective = (
  props?: Partial<Admin_CollectiveType>,
  n = 0
): Admin_CollectiveType => {
  const name = props?.name || companyName[n];

  return {
    id: cuid(),
    name,
    slug: slugify(name.toLowerCase()),
    tagline: paragraphs1[n],
    publicStatus: PublicStatus.Public,
    members: [generateMember()],
    skills: companyBs[n].split(' '),
    caseStudies: [],
    hasChangedNameOnce: true,
    ...props,
  };
};
