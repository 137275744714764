import {
  Collective_MemberType,
  Collective_UserType,
} from '@collective/data-type';
import { isPresent } from '@collective/utils/helpers';
import { displayName } from '@collective/utils/shared';

type GetProposalMembersAvatarsProposal = {
  proposalMembers?: Array<{
    member?: Pick<Collective_MemberType, 'pictureUrl'> & {
      user?: Pick<
        Collective_UserType,
        'id' | 'firstname' | 'lastname' | 'pictureUrl'
      >;
    };
  }>;
};

export function getProposalMembersAvatars(
  proposal: GetProposalMembersAvatarsProposal
) {
  return (proposal.proposalMembers || [])
    .map((proposalMember) => {
      const user = proposalMember.member?.user;
      return user
        ? {
            id: user.id,
            name: displayName(user),
            src: proposalMember.member?.pictureUrl,
          }
        : null;
    })
    .filter(isPresent);
}
