import { ShortlistPricing } from '@collective/data-type';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  WrapperExpandable,
} from '@collective/ui';
import { Trans, useTranslation } from 'react-i18next';

import { BudgetRangeBadge } from '../../budget-range/budget-range-badge';
import { BudgetRange, OpportunityQuestion } from './types';
import { SoloApplicationMenus } from './utils';

type SoloApplicationQuestionsProps = {
  opportunityId: string;
  questions: OpportunityQuestion[];
  budgetRange: BudgetRange | null;
  shortlistPricing: ShortlistPricing;
};

export const SoloApplicationQuestions = ({
  questions,
  budgetRange,
  shortlistPricing,
  opportunityId,
}: SoloApplicationQuestionsProps) => {
  const { t } = useTranslation('common');

  if (
    questions.length === 0 &&
    (shortlistPricing !== ShortlistPricing.BudgetRange || !budgetRange)
  ) {
    return null;
  }

  return (
    <Box key={opportunityId}>
      <Heading variant="desktop-l-bold" id={SoloApplicationMenus.NEED}>
        {t('profile.application.content.needs.title')}
      </Heading>
      {shortlistPricing === ShortlistPricing.BudgetRange && budgetRange && (
        <Box mt={6}>
          <Text variant="desktop-m-medium">
            {t('profile.budgetRange.question')}
          </Text>
          <Box mt={3} bg="primary.1" borderRadius={8} p="16px">
            <Flex flexWrap="wrap" mb={2} gap={2} align="center">
              <BudgetRangeBadge
                budgetRangeFrom={budgetRange.budgetRangeFrom}
                budgetRangeTo={budgetRange.budgetRangeTo}
                withTooltip
              />
              <Text variant="desktop-m-regular">
                <Trans
                  i18nKey="profile.budgetRange.estimationTaxExcluded"
                  components={{
                    grey: (
                      <Text
                        as="span"
                        variant="desktop-m-regular"
                        color="rythm.700"
                      />
                    ),
                  }}
                  ns="common"
                />
              </Text>
            </Flex>
            <AnswerBlock
              answer={budgetRange.budgetRangeAnswer}
              isHTML={false}
            />
          </Box>
        </Box>
      )}
      {questions.map(({ answer, label }, index) => (
        <Box key={index} mt={6}>
          <Text variant="desktop-m-medium">{label}</Text>
          <Box mt={3} bg="primary.1" borderRadius={8} p="16px">
            <AnswerBlock answer={answer} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const AnswerBlock = ({
  answer,
  isHTML = true,
}: {
  answer: string;
  isHTML?: boolean;
}) => {
  const { t } = useTranslation('common');

  return (
    <WrapperExpandable
      numberOfLinesBeforeExpand={6}
      isHTML={isHTML}
      content={answer}
      elementToDisplayWhenExpandable={
        <Button mt={2} variant="third">
          {t('profile.application.content.needs.seeAnswerButton')}
        </Button>
      }
    />
  );
};
