import { motion } from 'framer-motion';
import { createRef, Fragment, ReactNode, RefObject, useMemo } from 'react';

import {
  AnimatedMultiStepLayerProvider,
  createAnimatedMultiStepLayerContext,
} from './animated-multi-step-layer-context';
import { useCheckChildrenStep } from './use-check-children-step';

export type AnimatedMultiStepLayerProps<
  Step extends string,
  LayoutProps = Record<string, never>
> = {
  children: ReactNode[];
  steps: readonly Step[];
  isOpen: boolean;
  initialStep?: Step;
  overflow?: 'hidden' | 'visible';
  shouldUnmountStep?: boolean;
  animationType?:
    | 'tween'
    | 'spring'
    | 'keyframes'
    | 'inertia'
    | 'just'
    | 'none'
    | 'linear'
    | 'ease'
    | 'easeIn'
    | 'easeInOut'
    | 'easeOut';
} & (
  | {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Layout: React.FC<any & { children: ReactNode }>;
      layoutProps?: Omit<LayoutProps, 'children'>;
    }
  | { Layout?: undefined; layoutProps?: undefined }
); // You can't have layout props if there are no Layout

export const AnimatedMultiStepLayer = <
  Step extends string,
  LayoutProps = Record<string, never>
>({
  children,
  steps,
  isOpen,
  Layout = Fragment,
  layoutProps = undefined,
  animationType = 'easeInOut',
  overflow = 'hidden',
  initialStep,
  shouldUnmountStep = false,
}: AnimatedMultiStepLayerProps<Step, LayoutProps>) => {
  const AnimatedMultiStepLayerContext =
    createAnimatedMultiStepLayerContext<Step>();

  const refs = useMemo(() => {
    return steps.reduce((acc, step) => {
      acc[step] = createRef<HTMLDivElement>();
      return acc;
    }, {} as Record<Step, RefObject<HTMLDivElement>>);
  }, [steps]);

  useCheckChildrenStep(children, steps);

  return (
    <AnimatedMultiStepLayerProvider<Step>
      isOpen={isOpen}
      steps={steps}
      refs={refs}
      initialStep={initialStep}
      shouldUnmountStep={shouldUnmountStep}
    >
      <Layout {...(layoutProps || {})}>
        <AnimatedMultiStepLayerContext.Consumer>
          {({ controls }) => (
            <motion.div
              animate={controls}
              transition={{
                type: animationType,
              }}
              style={{ position: 'relative', overflow }}
            >
              {children}
            </motion.div>
          )}
        </AnimatedMultiStepLayerContext.Consumer>
      </Layout>
    </AnimatedMultiStepLayerProvider>
  );
};
