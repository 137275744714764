import { ScrapedJobType } from '@collective/data-type';
import {
  FreeWorkIconComponent,
  Icon,
  IconCollectiveLogo,
  Text,
  WelcomeToTheJungleIconComponent,
} from '@collective/ui';
import { useTranslation } from 'react-i18next';

type JobSourceProps = {
  source?: ScrapedJobType | null;
};

export const JobSource = ({ source }: JobSourceProps) => {
  const { t } = useTranslation('common');

  if (source === ScrapedJobType.FreeWork) {
    return <Icon as={FreeWorkIconComponent} boxSize="12px" />;
  }

  if (source === ScrapedJobType.WelcomeToTheJungle) {
    return <Icon as={WelcomeToTheJungleIconComponent} boxSize="12px" />;
  }

  return (
    <>
      <IconCollectiveLogo size="2xs" />
      <Text variant="desktop-s-regular" color="rythm.700">
        {t('job.source.exclusive')}
      </Text>
    </>
  );
};
