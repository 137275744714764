import {
  email,
  firstName,
  lastName,
  linkedInUrls,
} from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Admin_UserType } from '../../generated';

export const generateUser = (
  props?: Partial<Admin_UserType>,
  n = 0
): Admin_UserType => ({
  id: cuid(),
  firstname: firstName[n],
  lastname: lastName[n],
  linkedInUrl: linkedInUrls[n],
  pictureUrl: undefined,
  email: email[n],
  ...props,
});
