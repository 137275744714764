import { AxiosRequestConfig } from 'axios';

import { axiosGql } from './axios-graphql';

export const fetch = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  config?: RequestInit['headers']
): (() => Promise<TData>) => {
  return async () => {
    const response = await axiosGql<TData, TVariables>(
      query,
      variables,
      config as AxiosRequestConfig
    );

    return response.data;
  };
};
