import { restRoutes } from '@collective/utils/helpers';

export const useGoogleAuthUrl = () => {
  const userAgent = window.navigator.userAgent;

  const baseUrl = `${process.env.NX_BACKEND_URL}/${restRoutes.GOOGLE_AUTH_SCREEN}`;

  // https://felixcarmona.com/solving-google-login-issues-linkedin-ios/
  if (
    userAgent.includes('Mobile') &&
    (userAgent.includes('iPhone') || userAgent.includes('iPad')) &&
    userAgent.includes('LinkedInApp')
  ) {
    return 'x-safari-' + baseUrl;
  }

  return baseUrl;
};
