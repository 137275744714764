import { Collective_GetMe } from '@collective/data-type';
import { getCookie, getLogger } from '@collective/utils/frontend';
import { IMPERSONATE_INFO_COOKIE_NAME } from '@collective/utils/helpers';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AnalyticsTrackingContext } from './analytics-tracking-context';
import { POSTHOG_APP } from './use-posthog';

export function useTrackPageViews(
  me: Collective_GetMe['me'] | undefined,
  toIgnorePatterns: string[]
) {
  const location = useLocation();
  const analyticsTrackingContext = useContext(AnalyticsTrackingContext);

  useEffect(() => {
    if (!analyticsTrackingContext) {
      getLogger().error(
        'Missing context AnalyticsTrackingProvider for useTrackPageViews',
        { me }
      );
      return;
    }

    const { analytics, enabled } = analyticsTrackingContext;

    // ignore some users for intercom
    const shouldIgnoreUser = toIgnorePatterns.some((pattern) =>
      me?.email.includes(pattern)
    );
    const impersonateCookie = getCookie(IMPERSONATE_INFO_COOKIE_NAME);

    if (!enabled || shouldIgnoreUser || impersonateCookie || !me) {
      return;
    }

    // add here all the tracking and analytics tools for which we want to send events after each page change
    analytics?.page();

    // posthog analytics backup
    POSTHOG_APP?.capture('$pageview');
  }, [
    me,
    location,
    toIgnorePatterns,
    analyticsTrackingContext,
    analyticsTrackingContext?.analytics,
    analyticsTrackingContext?.enabled,
  ]);
}
