import React, { cloneElement, isValidElement, ReactNode } from 'react';
import { Trans } from 'react-i18next';

import { useHighlight } from './highlight-context';

interface HighlightTextProps {
  children: ReactNode;
  identifiers?: string[];
}

/**
 * For the identifiers and the `shouldHighlight` logic
 * Please take a look at : https://github.com/Collective-work/Collective/pull/6439/files#r1782899975
 * To understand and not break anything ;)
 */
export const HighlightText: React.FC<HighlightTextProps> = ({
  children,
  identifiers = [],
}) => {
  const { highlightedWords, highlightIdentifiers } = useHighlight();

  const shouldHighlight =
    identifiers.length === 0 ||
    identifiers.some((id) => highlightIdentifiers[id] !== false);

  if (!highlightedWords.length || !shouldHighlight) {
    return children;
  }

  const highlightWords = (node: ReactNode): ReactNode => {
    let modifiedNode = node;

    for (const searchTerm of highlightedWords) {
      modifiedNode = highlightWord(modifiedNode, searchTerm);
    }

    return modifiedNode;
  };

  return <>{React.Children.map(children, highlightWords)}</>;
};

function escapeRegExp(str: string) {
  // \\$& Ensures the whole matched string (the special character) is included
  // in the replacement, preceded by a backslash.
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function highlightWord(node: ReactNode, searchTerm: string): ReactNode {
  if (typeof node === 'string') {
    const cases = [
      // 1. keyword matching full words
      `(?<!\\S)${escapeRegExp(searchTerm)}(?!\\.?\\w)`,
    ];

    const regex = new RegExp(`(${cases.join('|')})`, 'gi');

    const parts = node.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <mark style={{ backgroundColor: '#FFE799' }} key={index}>
          {part}
        </mark>
      ) : (
        part
      )
    );
  } else if (Array.isArray(node)) {
    return node.map((n) => highlightWord(n, searchTerm));
  } else if (isValidElement(node)) {
    if (node.type === 'mark') {
      return node;
    } else if (node.type === Trans) {
      // Assume that Trans children are simple text or resolved to text
      return cloneElement(node, {
        ...node.props,
        parent: HighlightText,
        children: React.Children.map(node.props.children, (child) =>
          highlightWord(child, searchTerm)
        ),
      });
    } else {
      // General recursive handling for other types of components
      return cloneElement(node, {
        ...node.props,
        children: React.Children.map(node.props.children, (child) =>
          highlightWord(child, searchTerm)
        ),
      });
    }
  } else {
    return node;
  }
}
