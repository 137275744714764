import cuid from 'cuid';

import { Collective_DocumentType, DocumentCategory } from '../../generated';

export const generateDocument = (
  props?: Partial<Collective_DocumentType>
): Collective_DocumentType => {
  return {
    id: cuid(),
    extension: 'pdf',
    category: DocumentCategory.Proposal,
    createdAt: new Date().toISOString(),
    originalFilename: 'generated_filename.pdf',
    ...props,
  };
};
