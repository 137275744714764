import cuid from 'cuid';

import {
  Collective_PaymentRequestScheduleType,
  PaymentRequestScheduleStatus,
} from '../../generated';

export const generatePaymentRequestSchedule = (
  props?: Partial<Collective_PaymentRequestScheduleType>
): Collective_PaymentRequestScheduleType => {
  return {
    id: cuid(),
    extraRecipients: [],
    sendDate: new Date().toISOString(),
    status: PaymentRequestScheduleStatus.Pending,
    ...props,
  };
};
