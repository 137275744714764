import { Box, Flex, Tag, Text } from '@collective/ui';
import { useTranslation } from 'react-i18next';

import { HighlightText } from '../highlighting/highlight-text';
import { useProjectTypesTranslator } from '../hooks/use-project-types-translator';

type JobSkillsProps = {
  skills: string[];
  suggestions: string[];
};

export const JobSkills = ({ skills, suggestions }: JobSkillsProps) => {
  const { t } = useTranslation('common');

  const projectTypeTranslator = useProjectTypesTranslator();

  if (!skills.length) {
    return null;
  }

  return (
    <Box>
      <Text color="rythm.700" variant="desktop-s-semibold" mb={1}>
        {t('job.general.skills')}
      </Text>

      <Flex gap="8px" wrap="wrap">
        {skills.map((code) => (
          <Tag
            key={code}
            label={<HighlightText>{projectTypeTranslator(code)}</HighlightText>}
            size="sm"
            color="rythm.700"
            bg="rythm.100"
          />
        ))}
        {suggestions.map((suggestion) => (
          <Tag
            key={suggestion}
            label={<HighlightText>{suggestion}</HighlightText>}
            size="sm"
            color="rythm.700"
            bg="rythm.100"
          />
        ))}
      </Flex>
    </Box>
  );
};
