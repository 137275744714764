import { companyName, image, paragraphs1 } from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Client_CompanyType } from '../../generated';

export const generateCompany = (
  props?: Partial<Client_CompanyType>,
  n = 0
): Client_CompanyType => ({
  id: cuid(),
  billingEmails: [],
  name: companyName[n],
  logoUrl: image[n],
  description: paragraphs1[n],
  createdAt: new Date().toISOString(),
  ...props,
});
