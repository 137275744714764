import { isNil } from 'lodash';
import { SetNonNullable, SetRequired } from 'type-fest';

type Budget = {
  budgetRangeFrom?: number | null | undefined;
  budgetRangeTo?: number | null | undefined;
  budgetRangeAnswer?: string | null | undefined;
};

export function hasOpportunityBudget(
  budget?: Budget | null
): budget is SetNonNullable<
  SetRequired<
    Budget,
    'budgetRangeFrom' | 'budgetRangeTo' | 'budgetRangeAnswer'
  >,
  'budgetRangeFrom' | 'budgetRangeTo' | 'budgetRangeAnswer'
> {
  const answer = budget?.budgetRangeAnswer;
  const from = !isNil(budget?.budgetRangeFrom);
  const to = !isNil(budget?.budgetRangeTo);

  return !!(answer && from && to);
}
