import { Box, CloseButton, Modal } from '@collective/ui';
import { useRef } from 'react';

import { CaseStudyType } from '../solo-profile';
import { PublicCaseStudy } from './public-case-study';

export type CollectiveApplicationCaseStudyModalProps = {
  caseStudy: CaseStudyType;
  setCaseStudy: (newCaseStudy: CaseStudyType) => void;
};

export const CollectiveApplicationCaseStudyModal = ({
  caseStudy,
  setCaseStudy,
}: CollectiveApplicationCaseStudyModalProps) => {
  const ref = useRef(null);

  if (!caseStudy) {
    return null;
  }

  return (
    <Modal
      onClose={() => setCaseStudy(null)}
      isOpen
      size="lg"
      initialFocusRef={ref}
      motionPreset="slideInBottom"
    >
      <Box pt="24px" position="relative" pb={{ base: '86px', md: '24px' }}>
        <CloseButton
          ref={ref}
          position="absolute"
          top="16px"
          right="16px"
          onClick={() => setCaseStudy(null)}
        />
        <PublicCaseStudy caseStudy={caseStudy} />
      </Box>
    </Modal>
  );
};
