import { SUPPORTED_LANGUAGES } from '@collective/translation';
import { projectTypeUtils } from '@collective/utils/shared';
import i18next from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useProjectTypesTranslator = () => {
  const { t } = useTranslation('common');

  return useMemo(() => projectTypeUtils.getTranslationMapping(t), [t]);
};

export const useProjectTypesTranslators = () => {
  const tFunctions = SUPPORTED_LANGUAGES.map((lng) =>
    i18next.getFixedT(lng, 'common')
  );

  return useMemo(
    () => tFunctions.map((t) => projectTypeUtils.getTranslationMapping(t)),
    [tFunctions]
  );
};
