import { Breakpoint, breakpoints } from '@collective/ui';
import { addSearchParams } from '@collective/utils/helpers';

export const DEFAULT_IMAGE_SCALE = 2;

// This type should follow what is read in the lambda in lambdas/cdn-image-resize
type SizeParams = {
  width?: number;
  height?: number;
};

// Used to get resize search params to image urls to be read by the lambda
export const getImageSearchParams = (
  { width, height }: SizeParams,
  scale = DEFAULT_IMAGE_SCALE
) => {
  const searchParams = {
    ...(width && { width: String(width * scale) }),
    ...(height && { height: String(height * scale) }),
  };
  return new URLSearchParams(searchParams);
};

export const addImageParams = (
  url: string | undefined | null,
  sizeParams: SizeParams,
  scale?: number
) => {
  if (!url) {
    return;
  }
  const imageSearchParams = getImageSearchParams(sizeParams, scale);
  return addSearchParams(url, imageSearchParams);
};

type ObjectSrcSetSizes = Partial<
  Record<Breakpoint, { width: number; height?: number }>
>;

// This notation is used in order to be able to specify the original image, so as to avoid always having to use a resized image
type SrcSetSizeOriginalCouple = [Breakpoint, 'original'];
export type ArraySrcSetSizes = Array<
  Breakpoint | `${string}px` | SrcSetSizeOriginalCouple
>;

export type SrcSetSizes = ObjectSrcSetSizes | ArraySrcSetSizes;

/*
  For srcset two syntaxes are possible, either an array of breakpoints or px values (ex: ['sm', 'md', '1024px']),
  in which case the generated srcset will be derived from those.
  Or a more fine grained object where one can define separately the file name width and the breakpoint width.
*/
export const generateSrcSet = (
  src: string,
  srcSetSizes: NonNullable<SrcSetSizes>,
  scale?: number
): string => {
  if (Array.isArray(srcSetSizes)) {
    return getSrcSetFromArray(src, srcSetSizes, scale);
  }
  return getSrcSetFromObject(src, srcSetSizes, scale);
};

const getSrcSetFromArray = (
  src: string,
  srcSetSizes: ArraySrcSetSizes,
  scale?: number
) => {
  return srcSetSizes
    .map((size) => {
      if (Array.isArray(size)) {
        const [breakpoint] = size;
        const width = breakpoints[breakpoint].replace('px', '');
        return `${src} ${width}w`;
      }

      const widthPx = size.endsWith('px')
        ? size
        : breakpoints[size as Breakpoint];
      const width = widthPx.replace('px', '');

      const link = addImageParams(
        src,
        {
          width: +width,
        },
        scale
      );

      return `${link} ${width}w`;
    })
    .join(', ');
};

const getSrcSetFromObject = (
  src: string,
  srcSetSizes: ObjectSrcSetSizes,
  scale?: number
) => {
  return Object.entries(srcSetSizes)
    .map(([breakpoint, sizes]) => {
      const breakpointValue = breakpoint.endsWith('px')
        ? breakpoint.replace('px', 'w')
        : breakpoint;

      const link = addImageParams(
        src,
        {
          width: sizes.width,
          ...(sizes.height !== undefined && { height: sizes.height }),
        },
        scale
      );

      return `${link} ${breakpointValue}`;
    })
    .join(', ');
};

const SCALE_FACTOR = 4;
export const publicPageAvatarImageParams = getImageSearchParams(
  {
    // 48px is the biggest avatar image size we show on the public page
    width: 48,
  },
  SCALE_FACTOR
);
