import {
  offeredServiceIcons,
  sentence1,
  sentences2,
} from '@collective/utils/fixtures';
import cuid from 'cuid';
import { SetRequired } from 'type-fest';

import { Collective_OfferedServiceType } from '../../generated';

export const generateOfferedService = (
  props: SetRequired<Partial<Collective_OfferedServiceType>, 'ranking'>,
  n = 0
): Collective_OfferedServiceType => {
  return {
    id: cuid(),
    collectiveId: cuid(),
    title: sentence1[n],
    description: sentences2[n],
    icon: offeredServiceIcons[n],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    ...props,
  };
};
