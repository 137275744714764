import { useIntercom as useIntercomHook } from 'react-use-intercom';

export const useSendIntercomMessage = () => {
  const { showNewMessages } = useIntercomHook();

  return {
    sendMessage: (msg: string) => {
      showNewMessages(msg);
    },
  };
};
