import {
  image,
  productName,
  sentence2,
  sentence5,
} from '@collective/utils/fixtures';
import cuid from 'cuid';
import dayjs from 'dayjs';

import { CaseStudyStatus, Collective_CaseStudyType } from '../../generated';
import { generateCollective } from '.';
import { generateCaseStudyCompanyInformation } from './case-study-company-information';
import { generateCaseStudyProjectOrganisation } from './case-study-project-organisation';
import { generateTestimonial } from './testimonial';

export const generateCaseStudy = (
  props?: Partial<Collective_CaseStudyType>,
  n = 0
): Collective_CaseStudyType => {
  const shouldGenerateTestimonial = !(
    props &&
    'testimonial' in props &&
    props.testimonial
  );
  const shouldGenerateCompanyInformation = !(
    props &&
    'companyInformation' in props &&
    props.companyInformation
  );
  const shouldGenerateProjectOrganisation = !(
    props &&
    'projectOrganisation' in props &&
    props.projectOrganisation
  );

  return {
    id: cuid(),
    collective: generateCollective(),
    title: productName[n],
    status: CaseStudyStatus.Published,
    createdAt: dayjs().subtract(5, 'days').toISOString(),
    updatedAt: new Date().toISOString(),
    description: sentence5[n],
    content: sentence2[n],
    coverImage: image[n],
    testimonial: shouldGenerateTestimonial
      ? generateTestimonial(props?.testimonial || {}, n)
      : undefined,
    companyInformation: shouldGenerateCompanyInformation
      ? generateCaseStudyCompanyInformation(props?.companyInformation || {}, n)
      : undefined,
    projectOrganisation: shouldGenerateProjectOrganisation
      ? generateCaseStudyProjectOrganisation(props?.projectOrganisation || {})
      : undefined,
    ranking: n,
    ...props,
  };
};
