import { questions } from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Collective_ProjectQuestionType } from '../../generated';

export const generateProjectQuestion = (
  props?: Partial<Collective_ProjectQuestionType>,
  n = 0
): Collective_ProjectQuestionType => {
  return {
    id: cuid(),
    name: questions[n],
    ...props,
  };
};
