import { paragraphs2 } from '@collective/utils/fixtures';
import { FALLBACK_LANGUAGE } from '@collective/utils/helpers';
import cuid from 'cuid';

import {
  Collective_PaymentRequestType,
  PaymentRequestStatus,
} from '../../generated';
import { generateMission, generatePaymentRequestMilestone } from '.';
import { generatePaymentRequestPayAsYouGoMember } from './payment-request-pay-as-you-go-member';

export const generatePaymentRequest = (
  props?: Partial<Collective_PaymentRequestType>,
  n = 0
): Collective_PaymentRequestType => {
  const missionId = props?.mission?.id || cuid();
  const paymentRequest = {
    id: cuid(),
    missionId,
    milestones: [
      generatePaymentRequestMilestone({}, 0),
      generatePaymentRequestMilestone({}, 1),
    ],
    status: Object.values(PaymentRequestStatus)[n],
    clientNote: paragraphs2[n],
    issueDate: new Date().toISOString(),
    dueDate: new Date().toISOString(),
    language: FALLBACK_LANGUAGE,
    createdAt: new Date(2022, 1, 1).toISOString(),
    updatedAt: new Date(2022, 1, 1).toISOString(),
    paymentRequestPayAsYouGoMembers: [
      generatePaymentRequestPayAsYouGoMember({}, 0),
      generatePaymentRequestPayAsYouGoMember({}, 1),
    ],
    clientInvoices: [],
    memberInvoices: [],
    paymentRequestMemberItems: [],
    paymentRequestClientItems: [],
    paymentMethods: [],
    hasCreditNote: false,
    mission:
      props?.mission ||
      generateMission({ paymentRequests: [], id: missionId }, n),
    isDraft: false,
    ...props,
  };

  return paymentRequest;
};
