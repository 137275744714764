import { Segment } from '@collective/data-type';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type SegmentTranslationVariant = 'detailed' | 'short';

export function useSegmentsTranslationMapping(
  variant?: SegmentTranslationVariant
) {
  const { t } = useTranslation('common');
  return useMemo(() => getSegmentsTranslationMapping(t, variant), [t, variant]);
}

export function getSegmentsTranslationMapping(
  t: TFunction,
  variant: SegmentTranslationVariant = 'detailed'
): Record<Segment, string> {
  if (variant === 'detailed') {
    return {
      [Segment.Micro]: t('segment.micro.detailed'),
      [Segment.Small]: t('segment.small.detailed'),
      [Segment.Medium]: t('segment.medium.detailed'),
      [Segment.Large]: t('segment.large.detailed'),
      [Segment.Enterprise]: t('segment.enterprise.detailed'),
    };
  }
  return {
    [Segment.Micro]: t('segment.micro.short'),
    [Segment.Small]: t('segment.small.short'),
    [Segment.Medium]: t('segment.medium.short'),
    [Segment.Large]: t('segment.large.short'),
    [Segment.Enterprise]: t('segment.enterprise.short'),
  };
}
