import { initDatadogUserMonitoring } from '@collective/utils/frontend';
import { useEffect } from 'react';

export function useDatadogRum(
  enabled: boolean,
  applicationId: string,
  service?: string
) {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    // initialize user monitoring collecting user information and session replays
    // remember: it's not datadog is initialised that we will record events
    //   and sessions, this is also controlled by SAMPLE_RATE and REPLAY_SAMPLE_RATE
    //   https://docs.datadoghq.com/real_user_monitoring/browser/#browser-and-session-replay-sampling-configuration
    initDatadogUserMonitoring({
      applicationId,
      service,
    });
  }, [enabled, applicationId, service]);
}
