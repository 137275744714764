import { IconCollectiveShortLogo } from '@collective/ui/lib/icon/icon';
import { HStack, StackProps } from '@collective/ui/lib/layout';
import { Text } from '@collective/ui/lib/typography';
import { COLLECTIVE_WEBSITE_URL } from '@collective/utils/helpers/lib/constants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const PoweredBy = (props: StackProps) => {
  const { t } = useTranslation('public-pages');

  const [color, setColor] = useState('rythm.600');

  return (
    <a
      href={COLLECTIVE_WEBSITE_URL}
      rel="noreferrer"
      target="_blank"
      style={{ display: 'block', width: 'fit-content' }}
    >
      <HStack
        pb="16px"
        cursor="pointer"
        width="fit-content"
        {...props}
        mt={{
          base: '92px',
          md: '108px',
        }}
        onMouseEnter={() => setColor('primary.700')}
        onMouseLeave={() => setColor('rythm.600')}
      >
        <IconCollectiveShortLogo
          color={color}
          size="xs"
          style={{ transition: 'none' }}
        />

        <Text variant="desktop-s-bold" whiteSpace="nowrap" color={color}>
          {t('poweredByCollective')}
        </Text>
      </HStack>
    </a>
  );
};
