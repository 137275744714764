import {
  paragraphs1,
  productName,
  sentence20,
} from '@collective/utils/fixtures';
import cuid from 'cuid';

import {
  ApplicationType,
  Client_ProjectType,
  ProjectStartDate,
  ProjectStep,
  ShortlistPricing,
} from '../../generated';
import { generateClient } from './client';

export const generateProject = (
  props?: Partial<Client_ProjectType>,
  n = 0
): Client_ProjectType => {
  return {
    id: cuid(),
    name: productName[n],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    description: paragraphs1[n],
    sumUp: sentence20[n],
    idealStartDate: ProjectStartDate.Asap,
    opportunities: [],
    client: generateClient(undefined, n),
    proposals: [],
    shortlistPricing: ShortlistPricing.None,
    applicationType: ApplicationType.Group,
    step: props?.step || {
      name: ProjectStep.MissionOngoing,
      cancelled: false,
    },
    totalShortlistOpportunities: 0,
    showWorkExperience: false,
    showEducation: false,
    ...props,
  };
};
