import posthog, { PostHog } from 'posthog-js';
import { useEffect, useState } from 'react';

const POSTHOG_NAME = 'public';

export let POSTHOG_PUBLIC_APP: PostHog | null = null;

export function usePublicPosthog(enabled: boolean, writeKey: string) {
  const [analytics, setAnalytics] = useState<PostHog | void>();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const loadAnalytics = () => {
      POSTHOG_PUBLIC_APP = posthog.init(
        writeKey,
        {
          // this domain is a cloudfront distribution, created to proxy events without them
          // being discarded by ad blockers - see https://posthog.com/docs/integrate/proxy
          api_host: 'https://e.collective.work',
          // https://posthog.com/docs/integrate/client/js#autocapture
          autocapture: true,
        },
        POSTHOG_NAME
      ) as PostHog;

      setAnalytics(POSTHOG_PUBLIC_APP);
    };
    loadAnalytics();
  }, [enabled, writeKey]);

  return analytics;
}
