import { sentence1 } from '@collective/utils/fixtures';
import cuid from 'cuid';
import dayjs from 'dayjs';

import {
  Collective_ProposalType,
  InvoiceType,
  ProposalPaymentOption,
  ProposalStatusType,
} from '../../generated';
import { generateMember } from './member';
import { generateProject } from './project';

export const generateProposal = (
  props?: Partial<Collective_ProposalType>,
  n = 0
): Collective_ProposalType => {
  return {
    id: cuid(),
    createdByMemberId: cuid(),
    projectId: cuid(),
    name: sentence1[n],
    status: ProposalStatusType.Draft,
    paymentOption: ProposalPaymentOption.Milestones,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    expiresAt: dayjs().add(3, 'days').toISOString(),
    milestones: [],
    payAsYouGoMembers: [],
    isIntermediated: true,
    invoiceType: InvoiceType.DirectCollectiveInvoice,
    scopeOfWork: '',
    createdByMember: props?.createdByMember || generateMember(),
    project: props?.project || generateProject(),
    proposalMembers: props?.proposalMembers || [],
    ...props,
  };
};
