import { BoxProps, Flex, Text } from '@collective/ui';
import { ReactNode } from 'react';

type ColorScheme = 'blue' | 'green' | 'basic';
type UserPillProps = {
  content: ReactNode;
  icon: ReactNode;
  colorScheme: ColorScheme;
};

const colorSchemeStyle: Record<ColorScheme, BoxProps> = {
  blue: {
    bg: 'primary.25',
    borderColor: 'primary.600',
    color: 'primary.600',
  },
  green: {
    bg: 'success.200',
    borderColor: 'success.700',
    color: 'success.700',
  },
  basic: {
    bg: 'rythm.100',
    borderColor: 'rythm.700',
    color: 'rythm.900',
  },
};

export const UserPill = ({ content, icon, colorScheme }: UserPillProps) => {
  return (
    <Flex
      alignItems="center"
      gap={1}
      border="1px solid"
      px={2}
      py={1}
      borderRadius={8}
      {...colorSchemeStyle[colorScheme]}
    >
      {icon}
      <Text variant="desktop-m-medium" color="inherit">
        {content}
      </Text>
    </Flex>
  );
};
