import {
  companyBs,
  companyName,
  paragraphs1,
} from '@collective/utils/fixtures';
import cuid from 'cuid';
import slugify from 'slugify';

import {
  Collective_CollectiveType,
  CollectiveType,
  ExclusiveLeadsStatus,
  PublicStatus,
} from '../../generated';
import { generateMember } from './member';
import { generateMemberInvited } from './member-invited';
import { generateOfferedService } from './offered-service';

export const generateCollective = (
  props?: Partial<Collective_CollectiveType>,
  n = 0
): Collective_CollectiveType => {
  const name = props?.name || companyName[n];
  return {
    id: cuid(),
    name,
    slug: slugify(name.toLowerCase()),
    tagline: paragraphs1[n],
    description: companyBs[n],
    offeredServices: [generateOfferedService({ ranking: n + 1 })],
    publicStatus: PublicStatus.Public,
    createdAt: new Date().toISOString(),
    members: [generateMember()],
    membersInvited: [generateMemberInvited()],
    skills: companyBs[n].split(' '),
    hasFilledProfilingQuestions: false,
    hasChangedNameOnce: true,
    hasProjectTypes: false,
    hasProjectTypesSuggestions: false,
    caseStudies: [],
    exclusiveLeadsStatus: ExclusiveLeadsStatus.Regular,
    type: CollectiveType.Permanent,
    ...props,
  };
};
