import { useCallback } from 'react';

const TRACKING_EVENTS_STORAGE_KEY = 'tracking-events';

type StoredEvent = {
  name: string;
  params?: Record<string, string | number | boolean | null | undefined>;
};

export const useStoredTrackingEvents = () => {
  const getStoredEvents = useCallback<() => StoredEvent[]>(() => {
    const jsonStoredEvents = sessionStorage.getItem(
      TRACKING_EVENTS_STORAGE_KEY
    );
    return jsonStoredEvents ? JSON.parse(jsonStoredEvents) : [];
  }, []);

  const storeTrackingEvent = useCallback(
    (event: StoredEvent) => {
      const storedEvents = getStoredEvents();
      const updatedEvents = (storedEvents || []).concat([event]);
      sessionStorage.setItem(
        TRACKING_EVENTS_STORAGE_KEY,
        JSON.stringify(updatedEvents)
      );
    },
    [getStoredEvents]
  );

  const cleanStoredTrackingEvents = useCallback(() => {
    sessionStorage.removeItem(TRACKING_EVENTS_STORAGE_KEY);
  }, []);

  return {
    getStoredEvents,
    storeTrackingEvent,
    cleanStoredTrackingEvents,
  };
};
