import { Maybe } from '@collective/data-type';
import { ReactElement, ReactNode, useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { useHighlight } from '../highlighting/highlight-context';
import { highlightWord } from '../highlighting/highlight-text';

export const useHighlightedDescription = (description: Maybe<string>) => {
  const { highlightedWords } = useHighlight();

  return useMemo(() => {
    /**
     * Because the description is in HTML, we need to use `dangerouslySetInnerHTML` (thanks to the RichTextEditorView component)
     * The HighlightText component doesn't work as the content is not pass as child but through a props
     * There is also an issue where the method to highlight is by inserting ReactNodes
     * What we want is an HTML string. So we need to render the highlight node as strings where we keep the rest of the text (as it's already pieces of html string)
     */
    const highlightedNode = highlightedWords.reduce<ReactNode>((acc, value) => {
      return highlightWord(acc, value);
    }, description || '');

    if (!Array.isArray(highlightedNode)) {
      return highlightedNode as string;
    }

    const handleArrays = (nodes: Array<string | ReactElement>) => {
      return nodes
        .map((node): string => {
          if (Array.isArray(node)) {
            return handleArrays(node);
          }

          return typeof node === 'string' ? node : renderToStaticMarkup(node);
        })
        .join('');
    };

    return handleArrays(highlightedNode);
  }, [highlightedWords, description]);
};
