import { productName } from '@collective/utils/fixtures';
import cuid from 'cuid';

import {
  ApplicationType,
  Client_ShortlistProjectType,
  ShortlistPricing,
} from '../../generated';
import { generateClient } from './client';
import { generateClientOpportunity } from './client-opportunity';
import { generateUser } from './user';

export const generateClientProject = (
  props?: Partial<Client_ShortlistProjectType>,
  n = 0
): Client_ShortlistProjectType => {
  const client = generateClient();

  return {
    id: cuid(),
    name: productName[n],
    opportunities: [generateClientOpportunity({}, n)],
    isShortlistEnded: false,
    isRecruiterJob: false,
    applicationType: ApplicationType.Group,
    showWorkExperience: false,
    showEducation: false,
    client: {
      ...client,
      company: {
        ...client.company,
        billingEmails: [],
      },
      projects: [],
      user: generateUser(),
    },
    shortlistPricing: ShortlistPricing.None,
    ...props,
  };
};
