import { chain } from 'lodash';
import { useMemo } from 'react';

import { useProjectTypesTranslator } from '../../hooks/use-project-types-translator';

type SkillType = {
  code: string;
  isFeatured?: boolean | null;
};

export function useSkillSort(skills: SkillType[], filterSkills: string[]) {
  const projectTypeTranslator = useProjectTypesTranslator();

  return useMemo(() => {
    return chain(skills)
      .orderBy(
        [(skill) => filterSkills.includes(skill.code), 'isFeatured'],
        ['desc', 'desc']
      )
      .map(({ code, isFeatured }) => ({
        value: projectTypeTranslator(code),
        isFeatured,
      }))
      .value();
  }, [projectTypeTranslator, skills, filterSkills]);
}
