import cuid from 'cuid';

import { Chat_ChannelType } from '../../generated';

export const generateChannel = (
  props?: Partial<Chat_ChannelType>
): Chat_ChannelType => {
  return {
    id: cuid(),
    direct: false,
    recruiter: false,
    temporary: false,
    users: [],
    formerUsers: [],
    unreadMessagesCount: 0,
    name: '',
    ...props,
  };
};
