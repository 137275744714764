import { PublicPages_TestimonialFragment } from '@collective/data-type';
import { BorderBox, Flex } from '@collective/ui/lib/layout';
import { Text } from '@collective/ui/lib/typography';
import { useTranslation } from 'react-i18next';

import { ImageWrapped } from '../image-wrapped/image-wrapped';

type PublicCaseStudyTestimonialProps = {
  testimonial?: PublicPages_TestimonialFragment | null;
};

export const PublicCaseStudyTestimonial = ({
  testimonial,
}: PublicCaseStudyTestimonialProps) => {
  const { t } = useTranslation('public-pages');

  if (!testimonial) {
    return null;
  }

  return (
    <BorderBox noHover p="20px" bg="rythm.100">
      <Text variant="desktop-l-regular" mb={4} whiteSpace="pre-line">
        {testimonial.text}
      </Text>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        gap={{ base: '4px', md: 0 }}
      >
        <Flex alignItems="center">
          {testimonial.picture && (
            <ImageWrapped
              w={6}
              h={6}
              mr={2}
              borderRadius="8px"
              src={testimonial.picture}
              srcSetSizes={['24px']}
              alt={`${testimonial.fullname} portrait`}
            />
          )}
          <Text variant="desktop-m-bold" whiteSpace="nowrap">
            {testimonial.fullname}
          </Text>
        </Flex>

        {(testimonial.position || testimonial.company) && (
          <Flex alignItems="center">
            <Text
              display={{ base: 'none', md: 'block' }}
              mx={2}
              color="rythm.300"
            >
              •
            </Text>
            <Text>
              {testimonial.position}
              {testimonial.position &&
                testimonial.company &&
                ` ${t('caseStudiesSection.testimonialAt')} `}
              {testimonial.company}
            </Text>
          </Flex>
        )}
      </Flex>
    </BorderBox>
  );
};
