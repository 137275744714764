import {
  Box,
  Flex,
  Heading,
  illustrationDiamond,
  Image,
  Infobox,
  MediaQueryShow,
  Text,
} from '@collective/ui';
import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type JobShareBannerProps = {
  button?: ReactNode;
};

export const JobShareBanner = ({ button }: JobShareBannerProps) => {
  const { t } = useTranslation('common');

  return (
    <Infobox p="20px" gridContainerStyle={{ width: '100%' }}>
      <Flex align="center" gap="16px" direction={{ base: 'column', md: 'row' }}>
        <Image src={illustrationDiamond} w="64px" h="64px" />

        <Box>
          <Heading variant="desktop-m-bold" mb={1}>
            {t('job.share.banner.header')}
          </Heading>
          <MediaQueryShow above="md">
            <Text>
              <Trans
                i18nKey="job.share.banner.description"
                components={{
                  bold: <Text as="span" variant="desktop-m-bold" />,
                }}
                ns="common"
              />
            </Text>
          </MediaQueryShow>
        </Box>

        {button}
      </Flex>
    </Infobox>
  );
};
