import { productName } from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Collective_PaymentRequestClientItemType } from '../../generated';

export const generatePaymentRequestClientItem = (
  props?: Collective_PaymentRequestClientItemType
): Collective_PaymentRequestClientItemType => ({
  id: cuid(),
  name: productName[0],
  amount: 1000,
  order: 0,
  quantity: '1',
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  ...props,
});
