import cuid from 'cuid';

import { Client_ClientType } from '../../generated';
import { generateCompany } from './company';
import { generateClientUser } from './user';

export const generateClient = (
  props?: Partial<Client_ClientType>,
  n = 0
): Client_ClientType => ({
  id: cuid(),
  companyId: cuid(),
  company: generateCompany(undefined, n),
  projects: [],
  user: generateClientUser({}, n),
  ...props,
});
