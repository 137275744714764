import { Box, Button, Flex, Heading, Image, Text } from '@collective/ui';
import { ArrayElement } from '@collective/utils/helpers';
import { useTranslation } from 'react-i18next';

import { CaseStudyCard } from '../../case-study-card/case-study-card';
import { CaseStudyEntity } from '../../public-case-study';
import { CaseStudyType } from './types';
import { SoloApplicationMenus } from './utils';

type SoloApplicationPortfolioProps = {
  caseStudies: Exclude<CaseStudyEntity, undefined>[];
  onClick: (caseStudy: CaseStudyType) => void;
};

export const SoloApplicationPortfolio = ({
  caseStudies,
  onClick,
}: SoloApplicationPortfolioProps) => {
  const { t } = useTranslation('common');

  return (
    <Box>
      <Heading variant="desktop-l-bold" id={SoloApplicationMenus.PORTFOLIO}>
        {t('profile.application.content.portfolio.title')}
      </Heading>
      <Flex
        flexDir={{ base: 'row', md: 'column' }}
        flexWrap={{ base: 'wrap', md: undefined }}
        gap={{ base: 4, md: 2 }}
        mt={6}
      >
        {caseStudies.map((caseStudy) => (
          <Box key={caseStudy.id} w={{ base: '100%', md: '100%' }}>
            <MobilePortfolioCard caseStudy={caseStudy} onClick={onClick} />
            <DesktopPortfolioCard caseStudy={caseStudy} onClick={onClick} />
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

type PortfolioCardProps = {
  caseStudy: ArrayElement<SoloApplicationPortfolioProps['caseStudies']>;
  onClick: SoloApplicationPortfolioProps['onClick'];
};

const DesktopPortfolioCard = ({ caseStudy, onClick }: PortfolioCardProps) => {
  return (
    <Button
      key={caseStudy.id}
      onClick={() => onClick(caseStudy)}
      variant="naked"
      width="100%"
      display={{ base: 'none', md: 'block' }}
    >
      <CaseStudyCard caseStudy={caseStudy} />
    </Button>
  );
};

const MobilePortfolioCard = ({ caseStudy, onClick }: PortfolioCardProps) => {
  return (
    <Box
      onClick={() => onClick(caseStudy)}
      p="12px"
      display={{ base: 'block', md: 'none' }}
    >
      <Image
        w="100%"
        src={caseStudy.coverImage}
        borderRadius={12}
        border="1px solid"
        borderColor="rythm.200"
        data-chromatic="ignore"
      />
      <Text
        variant="desktop-m-medium"
        overflow="hidden"
        textOverflow="ellipsis"
        noOfLines={2}
        mt="4px"
      >
        {caseStudy.title}
      </Text>
    </Box>
  );
};
