import cuid from 'cuid';

import {
  Admin_OpportunityType,
  Admin_ProjectType,
  Admin_ShortlistOpportunityType,
  OpportunityStage,
  OpportunityStatusType,
} from '../../generated';
import { generateCollective } from './collective';

export const generateOpportunity = (
  props?: Partial<Admin_OpportunityType>,
  n = 0
): Admin_OpportunityType => {
  return {
    id: cuid(),
    collective: generateCollective({}, n),
    isFavorite: false,
    platformFees: 10,
    lastOpportunityStatus: {
      status: OpportunityStatusType.AcceptedByTheCollective,
      createdAt: new Date().toISOString(),
      createdByUserId: '',
    },
    project: props?.project || ({} as Admin_ProjectType),
    ...props,
  };
};

export const generateShortlistOpportunity = (
  props?: Partial<Admin_ShortlistOpportunityType>,
  n = 0
): Admin_ShortlistOpportunityType => {
  return {
    ...generateOpportunity(props, n),
    opportunityNotes: [],
    opportunityCaseStudies: [],
    opportunityMembers: [],
    opportunityMembersInvited: [],
    opportunityQuestions: [],
    statuses: [],
    stage: OpportunityStage.Negotiating,
    ...props,
  };
};
