import { paragraphs2, productName } from '@collective/utils/fixtures';
import cuid from 'cuid';

import {
  Client_PaymentRequestType,
  PaymentRequestStatus,
} from '../../generated';
import { generateMission } from './mission';
import { generatePaymentRequestMilestone } from './payment-request-milestone';
import { generatePaymentRequestPayAsYouGoMember } from './payment-request-pay-as-you-go-member';

export const generatePaymentRequest = (
  props?: Partial<Client_PaymentRequestType>,
  n = 0
): Client_PaymentRequestType => {
  const missionId = props?.mission?.id || cuid();

  return {
    id: cuid(),
    name: productName[n],
    missionId,
    milestones: [
      generatePaymentRequestMilestone({}, 0),
      generatePaymentRequestMilestone({}, 1),
    ],
    status: Object.values(PaymentRequestStatus)[n],
    clientNote: paragraphs2[n],
    createdAt: new Date(2022, 1, 1).toISOString(),
    paymentRequestPayAsYouGoMembers: [
      generatePaymentRequestPayAsYouGoMember({}, 0),
      generatePaymentRequestPayAsYouGoMember({}, 1),
    ],
    clientInvoices: [],
    mission:
      props?.mission ||
      generateMission({ paymentRequests: [], id: missionId }, n),
    shouldShowReview: true,
    ...props,
  };
};
