import { Box, Button, Text } from '@collective/ui';
import { Trans, useTranslation } from 'react-i18next';

type WorkExperienceEmptyStateProps = {
  onClick: VoidFunction;
};

export const WorkExperienceEmptyState = ({
  onClick,
}: WorkExperienceEmptyStateProps) => {
  const { t } = useTranslation('common');

  return (
    <Box textAlign="center">
      <Text variant="desktop-m-regular" color="rythm.700" mb={3}>
        <Trans ns="common" i18nKey="profile.workExperience.emptyStateText" />
      </Text>
      <Button variant="secondary" onClick={onClick}>
        {t('profile.workExperience.emptyStateButton')}
      </Button>
    </Box>
  );
};
