import { AvatarGroup, Flex, Text } from '@collective/ui';
import { displayName } from '@collective/utils/shared';
import { useTranslation } from 'react-i18next';

import { ApplicationViewNetworkStats } from './types';

type SoloApplicationNetworkStatsProps = {
  network?: ApplicationViewNetworkStats;
};

export const SoloApplicationNetworkStats = ({
  network,
}: SoloApplicationNetworkStatsProps) => {
  const { t } = useTranslation('common');

  if (!network?.count) {
    return null;
  }

  const { count, users } = network;

  const avatars = users.map((user) => ({
    id: user.id,
    name: displayName(user),
    src: user.pictureUrl,
  }));

  return (
    <Flex align="center" gap="8px">
      <AvatarGroup
        avatars={avatars}
        borderColor="primary.800"
        shownAvatarsTooltipProps={{ isDisabled: true }}
      />
      <Text color="rythm.700">
        {t('profile.network.connections', { count })}
      </Text>
    </Flex>
  );
};
