import { Collective_ProfileEducationFragment } from '@collective/data-type';
import {
  Avatar,
  Box,
  Button,
  Flex,
  IconAcademicCap,
  IconButton,
  IconEdit,
  IconTrash,
  Text,
  Tooltip,
  WrapperExpandable,
} from '@collective/ui';
import { ALL_IDENTIFIER } from '@collective/utils/frontend';
import { Trans, useTranslation } from 'react-i18next';

import { HighlightText } from '../../../highlighting/highlight-text';
import { ProfileDates } from '../dates';
import { ProfileRow, ProfileRowHeader } from '../row';
import { EducationActions } from './types';

type EducationRowProps = {
  education: Collective_ProfileEducationFragment;
  actions?: EducationActions;
  isCollapsible: boolean;
};

export const EducationRow = ({
  education,
  actions,
  isCollapsible,
}: EducationRowProps) => {
  const { t } = useTranslation('common');

  const { activities, description, degreeName, field, grade, school, logoUrl } =
    education;

  if (!school) {
    return null;
  }

  return (
    <ProfileRow key={education.id} role="group">
      <Box>
        {logoUrl ? (
          <Avatar
            src={logoUrl}
            name={school}
            size="lg"
            showBorder
            borderColor="black"
          />
        ) : (
          <Flex
            w="48px"
            h="48px"
            borderRadius="14px"
            border="2px solid"
            borderColor="black"
            background="white"
            alignItems="center"
            justifyContent="center"
          >
            <IconAcademicCap />
          </Flex>
        )}
      </Box>

      {/*
        minWidth 0 to avoid children flex elements from overflowing and shrinking correctly
        source: https://www.bigbinary.com/blog/understanding-the-automatic-minimum-size-of-flex-items
      */}
      <Flex direction="column" gap="16px" flex={1} minWidth={0}>
        <ProfileRowHeader
          title={school}
          subtitle={[degreeName, field].filter(Boolean).join(', ')}
          dates={<ProfileDates {...education} format="YYYY" />}
          actions={
            actions ? <Actions actions={actions} education={education} /> : null
          }
        />

        {grade && (
          <Text>
            <Trans
              ns="common"
              i18nKey="profile.education.grade"
              values={{ grade }}
            />
          </Text>
        )}

        {activities && (
          <Text>
            <Trans
              ns="common"
              i18nKey="profile.education.activities"
              values={{ activities }}
            />
          </Text>
        )}

        {isCollapsible ? (
          <WrapperExpandable
            numberOfLinesBeforeExpand={2}
            isHTML={false}
            content={description || ''}
            elementToDisplayWhenExpandable={
              <Button
                variant="plain"
                position="absolute"
                right={0}
                bottom="-2px"
                bgColor="white"
                pl={4}
              >
                {t('profile.education.seeMore')}
              </Button>
            }
          />
        ) : (
          <HighlightText identifiers={[ALL_IDENTIFIER]}>
            <Text whiteSpace="pre-line" wordBreak="break-word">
              {description}
            </Text>
          </HighlightText>
        )}
      </Flex>
    </ProfileRow>
  );
};

type ActionsProps = {
  actions: EducationActions;
  education: Collective_ProfileEducationFragment;
};

const Actions = ({ education, actions }: ActionsProps) => {
  const { t } = useTranslation('common');

  return (
    <Flex gap="8px">
      <Tooltip label={t('profile.education.action.edit')}>
        <IconButton
          display="none"
          _groupHover={{ display: 'flex' }}
          variant="iconButton"
          icon={<IconEdit />}
          aria-label={t('profile.education.action.edit')}
          onClick={() => actions.onEdit(education)}
        />
      </Tooltip>
      <Tooltip label={t('profile.education.action.delete')}>
        <IconButton
          display="none"
          _groupHover={{ display: 'flex' }}
          variant="iconButton"
          icon={<IconTrash />}
          aria-label={t('profile.education.action.delete')}
          onClick={() => actions.onDelete(education)}
        />
      </Tooltip>
    </Flex>
  );
};
