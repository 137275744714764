import { Maybe } from '@collective/data-type';
import { Badge, Center } from '@collective/ui';
import utilsDate from '@collective/utils/date';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

type JobExpiredBadgeProps = {
  expiresAt: Maybe<string>;
};

export const JobExpiredBadge = ({ expiresAt }: JobExpiredBadgeProps) => {
  const { t } = useTranslation();

  if (!expiresAt) {
    return null;
  }

  if (!dayjs().isAfter(expiresAt)) {
    return null;
  }

  return (
    <Center>
      <Badge size="md" colorScheme="caution">
        {t('job.expired.badge', { date: utilsDate.toReadableDate(expiresAt) })}
      </Badge>
    </Center>
  );
};
