import { Avatar, Flex, IconBuildingOffice2 } from '@collective/ui';

type WorkExperienceLogoProps = {
  logoUrl?: string | null;
  name?: string | null;
};

export const WorkExperienceLogo = ({
  logoUrl,
  name,
}: WorkExperienceLogoProps) => {
  return logoUrl ? (
    <Avatar
      src={logoUrl}
      name={name}
      size="lg"
      showBorder
      borderColor="black"
    />
  ) : (
    <Flex
      w="48px"
      h="48px"
      borderRadius="14px"
      border="2px solid"
      borderColor="black"
      background="white"
      alignItems="center"
      justifyContent="center"
    >
      <IconBuildingOffice2 />
    </Flex>
  );
};
