import { email } from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Collective_MemberInvitedType, MemberRole } from '../../generated';

export const generateMemberInvited = (
  props?: Partial<Collective_MemberInvitedType>,
  n = 0
): Collective_MemberInvitedType => ({
  id: cuid(),
  collectiveId: cuid(),
  createdAt: new Date().toISOString(),
  email: email[n],
  role: MemberRole.Member,
  ...props,
});
