import { NETWORK_ERROR } from '@collective/utils/errors';
import axios from 'axios';

const client = axios.create({
  baseURL: process.env.NX_BACKEND_URL,
  timeout: 10000,
  withCredentials: true, // to keep cross origin cookies
});

client.interceptors.response.use(undefined, (err) => {
  // Detect Axios network errors: https://github.com/axios/axios/issues/383
  if (axios.isAxiosError(err) && !err.response) {
    throw new Error(NETWORK_ERROR);
  }
  throw err;
});

export function refresh(): Promise<boolean> {
  // try out a refresh when auth token expires
  return client
    .get('auth/refresh')
    .then(() => true)
    .catch(() => false);
}
