import { useCallback, useEffect } from 'react';

// If your needs are simple use useIntercomAutoHideDefaultLauncher instead
export const useIntercomHideDefaultLauncher = () => {
  return useCallback((hideIntercom: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).Intercom?.('update', {
      hide_default_launcher: hideIntercom,
    });
  }, []);
};

// Hide parameter is to be used as a conditional override in cases where we want to use the hook conditionally
// ie. when depending on the viewport or a modal's 'isOpen'
export const useIntercomAutoHideDefaultLauncher = (shouldHide = true) => {
  const hideDefaultLauncher = useIntercomHideDefaultLauncher();

  useEffect(() => {
    hideDefaultLauncher(shouldHide);
    return () => hideDefaultLauncher(false);
  }, [shouldHide, hideDefaultLauncher]);
};
