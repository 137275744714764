import {
  IconAcademicCap,
  IconAdjustmentsHorizontal,
  IconArchiveBoxArrowDown,
  IconArrowDownOnSquare,
  IconArrowDownOnSquareStack,
  IconArrowDownRight,
  IconArrowPath,
  IconArrowsPointingIn,
  IconArrowsPointingOut,
  IconArrowsRightLeft,
  IconArrowsUpDown,
  IconArrowTrendingDown,
  IconArrowTrendingUp,
  IconArrowUpRight,
  IconBanknotes,
  IconBars3,
  IconBars3BottomLeft,
  IconBars3BottomRight,
  IconBars3CenterLeft,
  IconBarsArrowDown,
  IconBarsArrowUp,
  IconBattery0,
  IconBattery50,
  IconBattery100,
  IconBeaker,
  IconBell,
  IconBellAlert,
  IconBellSnooze,
  IconBolt,
  IconBookmark,
  IconBookmarkSquare,
  IconBookOpen,
  IconBriefcase,
  IconBuildingLibrary,
  IconBuildingOffice,
  IconBuildingOffice2,
  IconBuildingStorefront,
  IconCake,
  IconCalculator,
  IconCalendar2,
  IconCalendarDays,
  IconCamera,
  IconChartBar,
  IconChartBarSquare,
  IconChartPie,
  IconChatBubbleBottomCenterText,
  IconChatBubbleLeftEllipsis,
  IconChatBubbleLeftRight,
  IconCheck2,
  IconCheckBadge,
  IconCheckCircle,
  IconCircleStack,
  IconClipboard,
  IconClipboardDocument,
  IconClipboardDocumentCheck,
  IconClipboardDocumentList,
  IconClock,
  IconCloud,
  IconCloudArrowDown,
  IconCloudArrowUp,
  IconCodeBracket,
  IconCodeBracketSquare,
  IconCog,
  IconCommandLine,
  IconComputerDesktop,
  IconCpuChip,
  IconCreditCard2,
  IconCube,
  IconCubeTransparent,
  IconCurrencyDollar,
  IconCurrencyEuro,
  IconCurrencyPound,
  IconCursorArrowRipple,
  IconDevicePhoneMobile,
  IconDeviceTablet,
  IconDocumentChartBar,
  IconDocumentCheck,
  IconEye,
  IconFilm,
  IconFingerPrint,
  IconFire,
  IconFlag,
  IconFolderArrowDown,
  IconFolderOpen,
  IconFunnel,
  IconGif,
  IconGift2,
  IconGiftTop,
  IconGlobeAlt,
  IconGlobeAmericas,
  IconGlobeAsiaAustralia,
  IconHandRaised,
  IconHandThumbUp,
  IconHashtag,
  IconHeart,
  IconHome2,
  IconHomeModern,
  IconIdentification,
  IconInbox2,
  IconInboxStack,
  IconKey,
  IconLifebuoy,
  IconLightBulb,
  IconLink2,
  IconListBullet,
  IconLockClosed,
  IconLockOpen,
  IconMagnifyingGlass,
  IconMap,
  IconMapPin,
  IconMegaphone,
  IconMusicalNote,
  IconNewspaper,
  IconPaintBrush,
  IconPhoto,
  IconPresentationChartBar,
  IconPresentationChartLine,
  IconPuzzlePiece,
  IconQrCode,
  IconQueueList,
  IconRadio,
  IconReceiptPercent,
  IconRss,
  IconScale,
  IconServer,
  IconServerStack,
  IconShare,
  IconShieldCheck,
  IconShieldExclamation,
  IconShoppingBag,
  IconShoppingCart,
  IconSignal,
  IconSparkles,
  IconSpeakerWave,
  IconSquares2x2,
  IconStar,
  IconSun,
  IconSwatch,
  IconTableCells,
  IconTag,
  IconTruck,
  IconVideoCamera,
  IconViewColumns,
  IconWrench,
  IconWrenchScrewdriver,
} from '@collective/ui/lib/icon/icon';
import { ReactElement } from 'react';

const IconProps = {
  display: 'inline',
  color: 'rythm.600',
};

export const servicesIcons: Record<string, ReactElement> = {
  'academic-cap': (
    <IconAcademicCap
      {...IconProps}
      data-testid="academic-cap"
      className="legacy-icon"
    />
  ),
  'adjustments-horizontal': (
    <IconAdjustmentsHorizontal
      {...IconProps}
      className="non-legacy-icon"
      data-testid="adjustments-horizontal"
    />
  ),
  'archive-box-arrow-down': (
    <IconArchiveBoxArrowDown
      {...IconProps}
      data-testid="archive-box-arrow-down"
      className="legacy-icon"
    />
  ),
  'arrow-down-on-square-stack': (
    <IconArrowDownOnSquareStack
      {...IconProps}
      data-testid="arrow-down-on-square-stack"
      className="legacy-icon"
    />
  ),
  'arrow-down-on-square': (
    <IconArrowDownOnSquare
      {...IconProps}
      data-testid="arrow-down-on-square"
      className="legacy-icon"
    />
  ),
  'arrow-down-right': (
    <IconArrowDownRight
      {...IconProps}
      data-testid="arrow-down-right"
      className="non-legacy-icon"
    />
  ),
  'arrow-path': (
    <IconArrowPath
      {...IconProps}
      data-testid="arrow-path"
      className="non-legacy-icon"
    />
  ),
  'arrow-trending-down': (
    <IconArrowTrendingDown
      {...IconProps}
      data-testid="arrow-trending-down"
      className="legacy-icon"
    />
  ),
  'arrow-trending-up': (
    <IconArrowTrendingUp
      {...IconProps}
      data-testid="arrow-trending-up"
      className="legacy-icon"
    />
  ),
  'arrow-up-right': (
    <IconArrowUpRight
      {...IconProps}
      data-testid="arrow-up-right"
      className="non-legacy-icon"
    />
  ),
  'arrows-pointing-in': (
    <IconArrowsPointingIn
      {...IconProps}
      data-testid="arrows-pointing-in"
      className="non-legacy-icon"
    />
  ),
  'arrows-pointing-out': (
    <IconArrowsPointingOut
      {...IconProps}
      data-testid="arrows-pointing-out"
      className="non-legacy-icon"
    />
  ),
  'arrows-right-left': (
    <IconArrowsRightLeft
      {...IconProps}
      data-testid="arrows-right-left"
      className="legacy-icon"
    />
  ),
  'arrows-up-down': (
    <IconArrowsUpDown
      {...IconProps}
      data-testid="arrows-up-down"
      className="legacy-icon"
    />
  ),
  banknotes: (
    <IconBanknotes
      {...IconProps}
      data-testid="banknotes"
      className="legacy-icon"
    />
  ),
  'bars-3-bottom-left': (
    <IconBars3BottomLeft
      {...IconProps}
      data-testid="bars-3-bottom-left"
      className="legacy-icon"
    />
  ),
  'bars-3-bottom-right': (
    <IconBars3BottomRight
      {...IconProps}
      data-testid="bars-3-bottom-right"
      className="legacy-icon"
    />
  ),
  'bars-3-center-left': (
    <IconBars3CenterLeft
      {...IconProps}
      data-testid="bars-3-center-left"
      className="legacy-icon"
    />
  ),
  'bars-3': (
    <IconBars3 {...IconProps} data-testid="bars-3" className="legacy-icon" />
  ),
  'bars-arrow-down': (
    <IconBarsArrowDown
      {...IconProps}
      data-testid="bars-arrow-down"
      className="non-legacy-icon"
    />
  ),
  'bars-arrow-up': (
    <IconBarsArrowUp
      {...IconProps}
      data-testid="bars-arrow-up"
      className="non-legacy-icon"
    />
  ),
  'battery-0': (
    <IconBattery0
      {...IconProps}
      data-testid="battery-0"
      className="legacy-icon"
    />
  ),
  'battery-50': (
    <IconBattery50
      {...IconProps}
      data-testid="battery-50"
      className="legacy-icon"
    />
  ),
  'battery-100': (
    <IconBattery100
      {...IconProps}
      data-testid="battery-100"
      className="legacy-icon"
    />
  ),
  beaker: (
    <IconBeaker {...IconProps} data-testid="beaker" className="legacy-icon" />
  ),
  'bell-alert': (
    <IconBellAlert
      {...IconProps}
      data-testid="bell-alert"
      className="non-legacy-icon"
    />
  ),
  'bell-snooze': (
    <IconBellSnooze
      {...IconProps}
      data-testid="bell-snooze"
      className="non-legacy-icon"
    />
  ),
  bell: (
    <IconBell {...IconProps} data-testid="bell" className="non-legacy-icon" />
  ),
  bolt: (
    <IconBolt {...IconProps} data-testid="bolt" className="non-legacy-icon" />
  ),
  'book-open': (
    <IconBookOpen
      {...IconProps}
      data-testid="book-open"
      className="legacy-icon"
    />
  ),
  'bookmark-square': (
    <IconBookmarkSquare
      {...IconProps}
      data-testid="bookmark-square"
      className="non-legacy-icon"
    />
  ),
  bookmark: (
    <IconBookmark
      {...IconProps}
      data-testid="bookmark"
      className="legacy-icon"
    />
  ),
  briefcase: (
    <IconBriefcase
      {...IconProps}
      data-testid="briefcase"
      className="non-legacy-icon"
    />
  ),
  'building-library': (
    <IconBuildingLibrary
      {...IconProps}
      data-testid="building-library"
      className="legacy-icon"
    />
  ),
  'building-office-2': (
    <IconBuildingOffice2
      {...IconProps}
      data-testid="building-office-2"
      className="legacy-icon"
    />
  ),
  'building-office': (
    <IconBuildingOffice
      {...IconProps}
      data-testid="building-office"
      className="non-legacy-icon"
    />
  ),
  'building-storefront': (
    <IconBuildingStorefront
      {...IconProps}
      data-testid="building-storefront"
      className="legacy-icon"
    />
  ),
  cake: <IconCake {...IconProps} data-testid="cake" className="legacy-icon" />,
  calculator: (
    <IconCalculator
      {...IconProps}
      data-testid="calculator"
      className="non-legacy-icon"
    />
  ),
  'calendar-days': (
    <IconCalendarDays
      {...IconProps}
      data-testid="calendar-days"
      className="non-legacy-icon"
    />
  ),
  calendar: (
    <IconCalendar2
      {...IconProps}
      data-testid="calendar"
      className="non-legacy-icon"
    />
  ),
  camera: (
    <IconCamera
      {...IconProps}
      data-testid="camera"
      className="non-legacy-icon"
    />
  ),
  'chart-bar-square': (
    <IconChartBarSquare
      {...IconProps}
      data-testid="chart-bar-square"
      className="non-legacy-icon"
    />
  ),
  'chart-bar': (
    <IconChartBar
      {...IconProps}
      data-testid="chart-bar"
      className="non-legacy-icon"
    />
  ),
  'chart-pie': (
    <IconChartPie
      {...IconProps}
      data-testid="chart-pie"
      className="non-legacy-icon"
    />
  ),
  'chat-bubble-bottom-center': (
    <IconChatBubbleBottomCenterText
      {...IconProps}
      data-testid="color"
      className="non-legacy-icon"
    />
  ),
  'chat-bubble-left-ellipsis': (
    <IconChatBubbleLeftEllipsis
      {...IconProps}
      data-testid="chat-bubble-left-ellipsis"
      className="non-legacy-icon"
    />
  ),
  'chat-bubble-left-right': (
    <IconChatBubbleLeftRight
      {...IconProps}
      data-testid="chat-bubble-left-right"
      className="non-legacy-icon"
    />
  ),
  'check-badge': (
    <IconCheckBadge
      {...IconProps}
      data-testid="check-badge"
      className="non-legacy-icon"
    />
  ),
  'check-circle': (
    <IconCheckCircle
      {...IconProps}
      data-testid="check-circle"
      className="non-legacy-icon"
    />
  ),
  check: (
    <IconCheck2
      {...IconProps}
      data-testid="check"
      className="non-legacy-icon"
    />
  ),
  'circle-stack': (
    <IconCircleStack
      {...IconProps}
      data-testid="circle-stack"
      className="non-legacy-icon"
    />
  ),
  'clipboard-document-check': (
    <IconClipboardDocumentCheck
      {...IconProps}
      data-testid="clipboard-document-check"
      className="non-legacy-icon"
    />
  ),
  'clipboard-document-list': (
    <IconClipboardDocumentList
      {...IconProps}
      data-testid="clipboard-document-list"
      className="legacy-icon"
    />
  ),
  'clipboard-document': (
    <IconClipboardDocument
      {...IconProps}
      data-testid="clipboard-document"
      className="legacy-icon"
    />
  ),
  clipboard: (
    <IconClipboard
      {...IconProps}
      data-testid="clipboard"
      className="non-legacy-icon"
    />
  ),
  clock: (
    <IconClock {...IconProps} data-testid="clock" className="legacy-icon" />
  ),
  'cloud-arrow-down': (
    <IconCloudArrowDown
      {...IconProps}
      data-testid="cloud-arrow-down"
      className="legacy-icon"
    />
  ),
  'cloud-arrow-up': (
    <IconCloudArrowUp
      {...IconProps}
      data-testid="cloud-arrow-up"
      className="legacy-icon"
    />
  ),
  cloud: (
    <IconCloud {...IconProps} data-testid="cloud" className="non-legacy-icon" />
  ),
  'code-bracket-square': (
    <IconCodeBracketSquare
      {...IconProps}
      data-testid="code-bracket-square"
      className="legacy-icon"
    />
  ),
  'code-bracket': (
    <IconCodeBracket
      {...IconProps}
      data-testid="code-bracket"
      className="legacy-icon"
    />
  ),
  cog: <IconCog {...IconProps} data-testid="cog" className="legacy-icon" />,
  'command-line': (
    <IconCommandLine
      {...IconProps}
      data-testid="command-line"
      className="non-legacy-icon"
    />
  ),
  'computer-desktop': (
    <IconComputerDesktop
      {...IconProps}
      data-testid="computer-desktop"
      className="legacy-icon"
    />
  ),
  'cpu-chip': (
    <IconCpuChip
      {...IconProps}
      data-testid="cpu-chip"
      className="non-legacy-icon"
    />
  ),
  'credit-card': (
    <IconCreditCard2
      {...IconProps}
      data-testid="credit-card"
      className="legacy-icon"
    />
  ),
  'cube-transparent': (
    <IconCubeTransparent
      {...IconProps}
      data-testid="cube-transparent"
      className="legacy-icon"
    />
  ),
  cube: <IconCube {...IconProps} data-testid="cube" className="legacy-icon" />,
  'currency-dollar': (
    <IconCurrencyDollar
      {...IconProps}
      data-testid="currency-dollar"
      className="legacy-icon"
    />
  ),
  'currency-euro': (
    <IconCurrencyEuro
      {...IconProps}
      data-testid="currency-euro"
      className="legacy-icon"
    />
  ),
  'currency-pound': (
    <IconCurrencyPound
      {...IconProps}
      data-testid="currency-pound"
      className="legacy-icon"
    />
  ),
  'cursor-arrow-ripple': (
    <IconCursorArrowRipple
      {...IconProps}
      data-testid="cursor-arrow-ripple"
      className="legacy-icon"
    />
  ),
  'device-phone-mobile': (
    <IconDevicePhoneMobile
      {...IconProps}
      data-testid="device-phone-mobile"
      className="legacy-icon"
    />
  ),
  'device-tablet': (
    <IconDeviceTablet
      {...IconProps}
      data-testid="device-tablet"
      className="legacy-icon"
    />
  ),
  'document-chart-bar': (
    <IconDocumentChartBar
      {...IconProps}
      data-testid="document-chart-bar"
      className="legacy-icon"
    />
  ),
  'document-check': (
    <IconDocumentCheck
      {...IconProps}
      data-testid="document-check"
      className="non-legacy-icon"
    />
  ),
  eye: <IconEye {...IconProps} data-testid="eye" className="non-legacy-icon" />,
  film: (
    <IconFilm {...IconProps} data-testid="film" className="non-legacy-icon" />
  ),
  'finger-print': (
    <IconFingerPrint
      {...IconProps}
      data-testid="finger-print"
      className="legacy-icon"
    />
  ),
  fire: (
    <IconFire {...IconProps} data-testid="fire" className="non-legacy-icon" />
  ),
  flag: <IconFlag {...IconProps} data-testid="flag" className="legacy-icon" />,
  'folder-arrow-down': (
    <IconFolderArrowDown
      {...IconProps}
      data-testid="folder-arrow-down"
      className="legacy-icon"
    />
  ),
  'folder-open': (
    <IconFolderOpen
      {...IconProps}
      data-testid="folder-open"
      className="legacy-icon"
    />
  ),
  funnel: (
    <IconFunnel {...IconProps} data-testid="funnel" className="legacy-icon" />
  ),
  gif: <IconGif {...IconProps} data-testid="gif" className="legacy-icon" />,
  'gift-top': (
    <IconGiftTop
      {...IconProps}
      data-testid="gift-top"
      className="legacy-icon"
    />
  ),
  gift: <IconGift2 {...IconProps} data-testid="gift" className="legacy-icon" />,
  'globe-alt': (
    <IconGlobeAlt
      {...IconProps}
      data-testid="globe-alt"
      className="non-legacy-icon"
    />
  ),
  'globe-americas': (
    <IconGlobeAmericas
      {...IconProps}
      data-testid="globe-americas"
      className="legacy-icon"
    />
  ),
  'globe-asia-australia': (
    <IconGlobeAsiaAustralia
      {...IconProps}
      data-testid="globe-asia-australia"
      className="legacy-icon"
    />
  ),
  'hand-raised': (
    <IconHandRaised
      {...IconProps}
      data-testid="hand-raised"
      className="non-legacy-icon"
    />
  ),
  'hand-thumb-up': (
    <IconHandThumbUp
      {...IconProps}
      data-testid="hand-thumb-up"
      className="non-legacy-icon"
    />
  ),
  hashtag: (
    <IconHashtag
      {...IconProps}
      data-testid="hashtag"
      className="non-legacy-icon"
    />
  ),
  heart: (
    <IconHeart {...IconProps} data-testid="heart" className="non-legacy-icon" />
  ),
  'home-modern': (
    <IconHomeModern
      {...IconProps}
      data-testid="home-modern"
      className="legacy-icon"
    />
  ),
  home: <IconHome2 {...IconProps} data-testid="home" className="legacy-icon" />,
  identification: (
    <IconIdentification
      {...IconProps}
      data-testid="identification"
      className="legacy-icon"
    />
  ),
  'inbox-stack': (
    <IconInboxStack
      {...IconProps}
      data-testid="inbox-stack"
      className="non-legacy-icon"
    />
  ),
  inbox: (
    <IconInbox2 {...IconProps} data-testid="inbox" className="legacy-icon" />
  ),
  key: <IconKey {...IconProps} data-testid="key" className="legacy-icon" />,
  lifebuoy: (
    <IconLifebuoy
      {...IconProps}
      data-testid="lifebuoy"
      className="legacy-icon"
    />
  ),
  'light-bulb': (
    <IconLightBulb
      {...IconProps}
      data-testid="light-bulb"
      className="legacy-icon"
    />
  ),
  link: (
    <IconLink2 {...IconProps} data-testid="link" className="non-legacy-icon" />
  ),
  'list-bullet': (
    <IconListBullet
      {...IconProps}
      data-testid="list-bullet"
      className="legacy-icon"
    />
  ),
  'lock-closed': (
    <IconLockClosed
      {...IconProps}
      data-testid="lock-closed"
      className="non-legacy-icon"
    />
  ),
  'lock-open': (
    <IconLockOpen
      {...IconProps}
      data-testid="lock-open"
      className="non-legacy-icon"
    />
  ),
  'magnifying-glass': (
    <IconMagnifyingGlass
      {...IconProps}
      data-testid="magnifying-glass"
      className="legacy-icon"
    />
  ),
  'map-pin': (
    <IconMapPin
      {...IconProps}
      data-testid="map-pin"
      className="non-legacy-icon"
    />
  ),
  map: <IconMap {...IconProps} data-testid="map" className="non-legacy-icon" />,
  megaphone: (
    <IconMegaphone
      {...IconProps}
      data-testid="megaphone"
      className="non-legacy-icon"
    />
  ),
  'musical-note': (
    <IconMusicalNote
      {...IconProps}
      data-testid="musical-note"
      className="legacy-icon"
    />
  ),
  newspaper: (
    <IconNewspaper
      {...IconProps}
      data-testid="newspaper"
      className="non-legacy-icon"
    />
  ),
  'paint-brush': (
    <IconPaintBrush
      {...IconProps}
      data-testid="paint-brush"
      className="legacy-icon"
    />
  ),
  photo: (
    <IconPhoto {...IconProps} data-testid="photo" className="legacy-icon" />
  ),
  'presentation-chart-bar': (
    <IconPresentationChartBar
      {...IconProps}
      data-testid="presentation-chart-bar"
      className="non-legacy-icon"
    />
  ),
  'presentation-chart-line': (
    <IconPresentationChartLine
      {...IconProps}
      data-testid="presentation-chart-line"
      className="non-legacy-icon"
    />
  ),
  'puzzle-piece': (
    <IconPuzzlePiece
      {...IconProps}
      data-testid="puzzle-piece"
      className="legacy-icon"
    />
  ),
  'qr-code': (
    <IconQrCode
      {...IconProps}
      data-testid="qr-code"
      className="non-legacy-icon"
    />
  ),
  'queue-list': (
    <IconQueueList
      {...IconProps}
      data-testid="queue-list"
      className="legacy-icon"
    />
  ),
  radio: (
    <IconRadio {...IconProps} data-testid="radio" className="legacy-icon" />
  ),
  'receipt-percent': (
    <IconReceiptPercent
      {...IconProps}
      data-testid="receipt-percent"
      className="legacy-icon"
    />
  ),
  rss: <IconRss {...IconProps} data-testid="rss" className="non-legacy-icon" />,
  scale: (
    <IconScale {...IconProps} data-testid="scale" className="legacy-icon" />
  ),
  'server-stack': (
    <IconServerStack
      {...IconProps}
      data-testid="server-stack"
      className="legacy-icon"
    />
  ),
  server: (
    <IconServer {...IconProps} data-testid="server" className="legacy-icon" />
  ),
  share: (
    <IconShare {...IconProps} data-testid="share" className="non-legacy-icon" />
  ),
  'shield-check': (
    <IconShieldCheck
      {...IconProps}
      data-testid="shield-check"
      className="non-legacy-icon"
    />
  ),
  'shield-exclamation': (
    <IconShieldExclamation
      {...IconProps}
      data-testid="shield-exclamation"
      className="non-legacy-icon"
    />
  ),
  'shopping-bag': (
    <IconShoppingBag
      {...IconProps}
      data-testid="shopping-bag"
      className="legacy-icon"
    />
  ),
  'shopping-cart': (
    <IconShoppingCart
      {...IconProps}
      data-testid="shopping-cart"
      className="legacy-icon"
    />
  ),
  signal: (
    <IconSignal {...IconProps} data-testid="signal" className="legacy-icon" />
  ),
  sparkles: (
    <IconSparkles
      {...IconProps}
      data-testid="sparkles"
      className="legacy-icon"
    />
  ),
  'speaker-wave': (
    <IconSpeakerWave
      {...IconProps}
      data-testid="speaker-wave"
      className="legacy-icon"
    />
  ),
  'squares-2x2': (
    <IconSquares2x2
      {...IconProps}
      data-testid="squares-2x2"
      className="legacy-icon"
    />
  ),
  star: (
    <IconStar {...IconProps} data-testid="star" className="non-legacy-icon" />
  ),
  sun: <IconSun {...IconProps} data-testid="sun" className="non-legacy-icon" />,
  swatch: (
    <IconSwatch {...IconProps} data-testid="swatch" className="legacy-icon" />
  ),
  'table-cells': (
    <IconTableCells
      {...IconProps}
      data-testid="table-cells"
      className="non-legacy-icon"
    />
  ),
  tag: <IconTag {...IconProps} data-testid="tag" className="legacy-icon" />,
  truck: (
    <IconTruck {...IconProps} data-testid="truck" className="legacy-icon" />
  ),
  'video-camera': (
    <IconVideoCamera
      {...IconProps}
      data-testid="video-camera"
      className="non-legacy-icon"
    />
  ),
  'view-columns': (
    <IconViewColumns
      {...IconProps}
      data-testid="view-columns"
      className="non-legacy-icon"
    />
  ),
  'wrench-screwdriver': (
    <IconWrenchScrewdriver
      {...IconProps}
      data-testid="wrench-screwdriver"
      className="legacy-icon"
    />
  ),
  wrench: (
    <IconWrench {...IconProps} data-testid="wrench" className="legacy-icon" />
  ),
};

export const getServiceIconFromName = (iconName: string): ReactElement => {
  if (iconName in servicesIcons) {
    return servicesIcons[iconName];
  }
  return Object.values(servicesIcons).sort()[0]; // just in case we no longer have an icon
};
