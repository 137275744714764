import { List, Text } from '@collective/ui';
import {
  getPasswordChecks,
  PASSWORD_LENGTH_MIN,
} from '@collective/utils/helpers';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const Li = styled.li<{ strike: boolean }>`
  width: 40%;
  margin-bottom: var(--chakra-space-1);

  span {
    transition: text-decoration-color 0.3s;
    text-decoration: ${({ strike }) => (strike ? 'line-through' : 'initial')};
  }

  &::before {
    content: '•';
    color: var(--chakra-colors-primary-700);
    margin-right: var(--chakra-space-2);
  }
`;

export const PasswordHints = ({
  password,
}: {
  password: string | undefined;
}) => {
  const { t } = useTranslation('common');
  const checks = getPasswordChecks(password);

  return (
    <Text variant="desktop-s-regular" as="div">
      <List display="flex" flexWrap="wrap">
        <Li strike={checks.hasMinCharacters}>
          <span>
            {t('auth.password.minCharacters', { count: PASSWORD_LENGTH_MIN })}
          </span>
        </Li>
        <Li strike={checks.hasSymbol} style={{ width: '50%' }}>
          <span>{t('auth.password.symbols', { count: 1 })}</span>
        </Li>
        <Li strike={checks.hasDigit}>
          <span>{t('auth.password.digits', { count: 1 })}</span>
        </Li>
      </List>
    </Text>
  );
};
