import { createContext, ReactNode } from 'react';

import { usePublicPosthog } from './use-public-posthog';

export const PublicAnalyticsTrackingContext = createContext<
  { enabled: boolean } | undefined
>(undefined);

type PublicAnalyticsTrackingProviderProps = {
  children: ReactNode;
  enabled: boolean;
  postHogAnalyticsKey: string;
};

export function PublicAnalyticsTrackingProvider({
  enabled,
  children,
  postHogAnalyticsKey,
}: PublicAnalyticsTrackingProviderProps) {
  usePublicPosthog(enabled, postHogAnalyticsKey);

  return (
    <PublicAnalyticsTrackingContext.Provider value={{ enabled }}>
      {children}
    </PublicAnalyticsTrackingContext.Provider>
  );
}
