import { Box } from '@collective/ui';

import { WrapperView, WrapperViewProps } from './wrapper-view';

type ProjectViewLayoutProps = Omit<WrapperViewProps, 'children'> & {
  mainView: React.ReactNode;
  sideView: React.ReactNode;
};

export const ProjectViewLayout = ({
  mainView,
  sideView,
  ...rest
}: ProjectViewLayoutProps) => (
  <WrapperView py={10} {...rest}>
    <Box flex={2}>{mainView}</Box>
    <Box flex={1} minW="352px" maxW="440px">
      {sideView}
    </Box>
  </WrapperView>
);
