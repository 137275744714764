import { Maybe } from '@collective/data-type';
import { Flex, Tag, Text } from '@collective/ui';
import { displayCurrencyFromNumber } from '@collective/utils/helpers';
import { useTranslation } from 'react-i18next';

type SoloApplicationDailyRateBadgeProps = {
  dailyRate?: Maybe<number>;
};

export const SoloApplicationDailyRateBadge = ({
  dailyRate,
}: SoloApplicationDailyRateBadgeProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('common');

  if (!dailyRate) {
    return null;
  }

  return (
    <Flex alignItems="center" gap={2}>
      <Tag
        color="primary.600"
        bg="primary.25"
        size="md"
        label={`${displayCurrencyFromNumber(dailyRate, true, language)}${t(
          'profile.application.dailyRate.perDay'
        )}`}
      />
      <Text variant="desktop-m-regular" color="rythm.700">
        {t('profile.application.dailyRate.noTax')}
      </Text>
    </Flex>
  );
};
