import { productName } from '@collective/utils/fixtures';
import { projectTypeDefinition } from '@collective/utils/shared';
import cuid from 'cuid';

import { Collective_ProjectTypeType } from '../../generated';

export const generateProjectType = (
  props?: Partial<Collective_ProjectTypeType>,
  n = 0
): Collective_ProjectTypeType => {
  return {
    id: cuid(),
    name: props?.name || productName[n],
    code:
      props?.code || Object.values(projectTypeDefinition.ProjectTypeEnum)[n],
    projectTypeCategoryId: cuid(),
    order: n,
    ...props,
  };
};
