import {
  getCollectivePublicPageUrl,
  PAGE_READY_EVENT,
} from '@collective/utils/helpers';
import { useCallback, useEffect } from 'react';

import { useTryMultipleAttempts } from '../hooks/use-try-multiple-attempts';
import { usePublicPageIframe } from './public-page-iframe-context';

type PublicPageIframeProps = {
  id: string;
  slug: string;
  queryParams?: Record<string, string>;
  onLoad?: () => void;
  onError?: () => void;
  height?: string | number;
  title?: string;
  style?: Record<string, string | number>;
};

export const PublicPageIframe = ({
  id,
  slug,
  queryParams = {},
  onLoad,
  onError,
  ...rest
}: PublicPageIframeProps) => {
  const { timestamp, pageReady, setLoaded, setHasError } =
    usePublicPageIframe();
  const multipleAttemptHelper = useTryMultipleAttempts();

  const isPageReady = useCallback(
    (event: MessageEvent) => {
      const { type, collectiveId } = event.data;
      if (type === PAGE_READY_EVENT && collectiveId === id) {
        pageReady.current = true;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- switch from warn to error
    [id]
  );

  useEffect(() => {
    window.addEventListener('message', isPageReady);
    return () => {
      window.removeEventListener('message', isPageReady);
    };
  }, [isPageReady]);

  if (timestamp === null) {
    return null;
  }

  /**
   * The iframe is a poor component without much properties, especially a onError
   * So if you load a page which doesn't exist, the onLoad will still be triggered
   * and there is no easy way to know it actually loaded the page or if it failed
   * To fix this problem, we send in the public pages a cross context event to warn the parent
   */
  const onIframeLoaded = () => {
    // We try to test if we received the page ready event each 200ms, during 2s (10 attempts)
    multipleAttemptHelper.start({
      interval: 200,
      maxRetry: 10,
      getProcessResult: () => {
        // Stored to avoid not passing in the if, having the value changing and
        // returning the wrong value because it changed during the process
        const isReady = pageReady.current;

        if (isReady) {
          setLoaded(true);
          onLoad?.();
        }

        // if true, it will cancel the interval
        return isReady;
      },
      // If max occurrence reached, we set the error
      onMaxTry: () => {
        if (!pageReady.current) {
          setHasError(true);
          onError?.();
        } else {
          onLoad?.();
        }

        setLoaded(true);
      },
    });
  };

  return (
    <iframe
      src={getCollectivePublicPageUrl(slug, {
        source: 'm',
        t: String(timestamp),
        ...queryParams,
      })}
      width="100%"
      height="100%"
      onLoad={onIframeLoaded}
      name={id}
      {...rest}
    />
  );
};
