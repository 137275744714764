export const MOCKED_LINKEDIN_COMPANY_DATA = {
  linkedin_internal_id: '69713279',
  description:
    "Collective est la plus grande plateforme d\u00e9di\u00e9e aux \u00e9quipes de freelances.\n\nNos collectifs ont d\u00e9j\u00e0 r\u00e9alis\u00e9 plusieurs milliers de projets (notamment en d\u00e9veloppement de logiciel, applications, conception de site internet et marketing) pour des start-ups, PME, ETI et grands groupes.\n\nSoumettez-nous un projet sur collective.work : vous rencontrerez probablement le collectif de vos r\u00eaves !\n\n---------------------------------------\n\nFreelances : nous mettons \u00e0 votre disposition notre plateforme pour vous permettre de cr\u00e9er, mettre en valeur et op\u00e9rer votre collectif. Vous acc\u00e9derez \u00e9galement \u00e0 nos opportunit\u00e9s de projet \u00e0 mener en \u00e9quipe.\n\n---------------------------------------\n\nCollective.work is already the largest platform for teams of freelancers.\n\nWe're building the SaaS-enabled marketplace to enable any freelancer to create and run collectives.",
  website: 'https://www.collective.work',
  industry: 'Software Development',
  company_size: [11, 50],
  company_size_on_linkedin: 35,
  hq: {
    country: 'FR',
    city: 'Paris',
    postal_code: '75002',
    line_1: '9, Rue des Colonnes',
    is_hq: true,
    state: '\u00cele-de-France',
  },
  company_type: 'PRIVATELY_HELD',
  founded_year: 2021,
  specialities: [
    '',
    'Software Development',
    'Finance services',
    'Software Development',
  ],
  locations: [
    {
      country: 'FR',
      city: 'Paris',
      postal_code: '75002',
      line_1: '9, Rue des Colonnes',
      is_hq: true,
      state: '\u00cele-de-France',
    },
    {
      country: 'TN',
      city: 'Tunis',
      postal_code: '2034',
      line_1: 'XXX',
      is_hq: false,
      state: 'XXX',
    },
  ],
  name: 'Collective.work',
  tagline:
    'La plus grande plateforme d\u00e9di\u00e9e aux \u00e9quipes de freelances : travaillez avec nos collectifs, ou cr\u00e9ez votre \u00e9quipe.',
  universal_name_id: 'usecollective',
  profile_pic_url:
    'https://s3.us-west-000.backblazeb2.com/proxycurl/company/usecollective/profile?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=0004d7f56a0400b0000000001%2F20240122%2Fus-west-000%2Fs3%2Faws4_request&X-Amz-Date=20240122T152747Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=dc74dcd62ad7d810c298a4669fde00c9b600614eeb1bf4fb2e8bbc96d6f1fb9d',
  background_cover_image_url:
    'https://s3.us-west-000.backblazeb2.com/proxycurl/company/usecollective/cover?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=0004d7f56a0400b0000000001%2F20240122%2Fus-west-000%2Fs3%2Faws4_request&X-Amz-Date=20240122T152747Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=e61fcddec35a1a8dc84df5eee687a71d3276d4d77d9ac1186b9a3b039657974d',
  search_id: '69713279',
  similar_companies: [
    {
      name: 'Hexa',
      link: 'https://fr.linkedin.com/company/joinhexa',
      industry: 'Software Development',
      location: 'Paris, \u00cele-de-France',
    },
  ],
  affiliated_companies: [],
  updates: [
    {
      article_link: null,
      image:
        'https://media.licdn.com/dms/image/D5622AQF_6sDM_TbFLw/feedshare-shrink_800/0/1705577832993?e=2147483647&v=beta&t=ym26hR7Y4i7Sjv-BjUWwV-lI3-bcr5bReA5q7oKh-1I',
      posted_on: { day: 18, month: 1, year: 2024 },
      text: 'Caravel x Collective \ud83e\udd1d\n\nSelon le Conseil d\u2019Orientation des Retraites, un salari\u00e9 perd jusqu\u2019\u00e0 50% de ses revenus \u00e0 la retraite, chiffre qui grimpe jusqu\u2019\u00e0 60% pour les ind\u00e9pendants. \n\nAlors, plut\u00f4t que d\u2019adopter la strat\u00e9gie de l\u2019autruche \ud83d\ude48, on vous propose de d\u00e9couvrir notre partenaire Caravel.\n\nCaravel, c\u2019est notamment :\n\n\u25b6\ufe0f un blog, des ressources et des conseils pour comprendre et pr\u00e9parer sa retraite en tant qu\u2019ind\u00e9pendant\n\n\u25b6\ufe0fun PER \u00e9thique qui permet de r\u00e9duire son imp\u00f4t sur le revenu\n\nPlus d\u2019excuse pour la procrastination \ud83d\ude09\nRDV ici : https://lnkd.in/g5FbuBbY',
      total_likes: 5,
    },
  ],
  follower_count: 6005,
  acquisitions: { acquired: [], acquired_by: null },
  exit_data: [],
  extra: null,
  funding_data: [],
  categories: [],
  customer_list: null,
};
