import { BorderBox, Divider } from '@collective/ui';
import { Fragment, ReactNode } from 'react';

export const ProfileBox = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const elements = Array.isArray(children) ? children : [children];

  return (
    <BorderBox noHover p={{ base: '24px 20px', md: '40px 32px' }}>
      {elements.filter(Boolean).map((element, index) =>
        index === 0 ? (
          element
        ) : (
          <Fragment key={index}>
            <Divider my={10} borderColor="rythm.300" />
            {element}
          </Fragment>
        )
      )}
    </BorderBox>
  );
};
