import { ShortlistPricing } from '@collective/data-type';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconFile,
  Link,
  Text,
} from '@collective/ui';
import { ALL_IDENTIFIER, TITLE_IDENTIFIER } from '@collective/utils/frontend';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { HighlightText } from '../../highlighting/highlight-text';
import { PhoneButton } from '../../phone-modal';
import { SoloApplicationDailyRateBadge } from './solo-application-daily-rate-badge';
import { SoloApplicationNetworkStats } from './solo-application-network-stats';
import { ApplicationViewNetworkStats, OpportunityMember } from './types';

type SoloApplicationProfileBannerProps = {
  member: OpportunityMember;
  shortlistPricing: ShortlistPricing;
  additionalTags?: ReactNode;
  cta?: ReactNode;
  network?: ApplicationViewNetworkStats;
};

export const SoloApplicationProfileBanner = ({
  member: { fullname, position, dailyRate, resumeUrl, phoneNumber },
  shortlistPricing,
  network,
  additionalTags,
  cta,
}: SoloApplicationProfileBannerProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      <Flex
        gap="8px"
        justify="space-between"
        direction={{ base: 'column', md: 'row' }}
      >
        <HighlightText identifiers={[TITLE_IDENTIFIER, ALL_IDENTIFIER]}>
          <Box flex={1}>
            <Heading as="h1" mb={1} variant="desktop-xl-bold">
              {fullname}
            </Heading>
            {!!position && (
              <Text as="h2" mb={2} variant="desktop-m-medium">
                {position}
              </Text>
            )}

            <SoloApplicationNetworkStats network={network} />

            {!additionalTags &&
              shortlistPricing === ShortlistPricing.DailyRate && (
                <SoloApplicationDailyRateBadge dailyRate={dailyRate} />
              )}
          </Box>
        </HighlightText>

        <PhoneButton phoneNumber={phoneNumber} shortVersion />
        <Box>
          {resumeUrl && (
            <Link href={resumeUrl} textDecoration="none" target="_blank">
              <Button variant="secondary" rightIcon={<IconFile />}>
                {t('profile.application.links.resume')}
              </Button>
            </Link>
          )}
          {cta}
        </Box>
      </Flex>
      {!!additionalTags && additionalTags}
    </>
  );
};
