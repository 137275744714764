import { Button } from '@collective/ui/lib/button/button';
import { Center } from '@collective/ui/lib/layout/center';
import { Heading } from '@collective/ui/lib/typography/heading';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type ErrorIframeProps = {
  onRefresh: () => void;
  children: ReactNode;
};

export const ErrorIframe = ({ onRefresh, children }: ErrorIframeProps) => {
  const { t } = useTranslation('common');

  return (
    <Center h="100%" overflow="hidden" flexDirection="column">
      <Heading variant="desktop-xl-bold" mb={6}>
        {t('uiSmart.errorIframe.title')}
      </Heading>
      <Button variant="secondary" onClick={onRefresh} mb={20}>
        {t('uiSmart.errorIframe.reloadBtn')}
      </Button>
      {children}
    </Center>
  );
};
