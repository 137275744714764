import {
  LINKEDIN_BASE_URL,
  LINKEDIN_TEST_PREFIX,
} from '@collective/utils/helpers';

/**
 * DATA GENERATED USING FAKER.JS
 */

/**
 * faker.company.name()
 */
export const companyName = [
  'Zieme - Reichel',
  'Abbott, Champlin and Gutmann',
  'Corkery, Feil and Bosco',
  'Dibbert - Howell',
  'Ortiz - Hyatt',
  'Lind, Stamm and Johnston',
  'Pfannerstill LLC',
  'Kihn Group',
  'Balistreri LLC',
  'Volkman, Johnson and Koch',
  'Gislason, Bashirian and Reynolds',
  'Jenkins - Labadie',
  'Doyle Inc',
  'Mann - Hirthe',
  'Kub - Cole',
  'Marquardt - Auer',
  'Torphy - Anderson',
  'Hartmann - Ryan',
  'Upton - Hudson',
  'Treutel LLC',
];

/**
 * faker.location.country()
 */
export const country = [
  'MR',
  'IT',
  'BI',
  'GB',
  'CG',
  'SM',
  'PT',
  'VN',
  'SL',
  'BH',
  'RS',
  'ER',
  'GN',
  'BV',
  'PG',
  'BZ',
  'ID',
  'TH',
  'KI',
  'IE',
];

/**
 * faker.company.buzzPhrase()
 */
export const companyBs = [
  'envisioneer scalable models',
  'synthesize user-centric metrics',
  'e-enable transparent markets',
  'revolutionize vertical solutions',
  'leverage proactive e-business',
  'whiteboard scalable e-markets',
  'strategize open-source methodologies',
  'synergize back-end channels',
  'visualize B2B e-services',
  'enable cross-platform schemas',
  'harness next-generation technologies',
  'unleash synergistic e-commerce',
  'engineer enterprise channels',
  'incubate scalable channels',
  'aggregate dot-com technologies',
  'reintermediate granular channels',
  'enable end-to-end schemas',
  'e-enable collaborative mindshare',
  'expedite value-added paradigms',
  'enable front-end infomediaries',
];

/**
 * faker.lorem.paragraphs(1)
 */
export const paragraphs1 = [
  'Sit aperiam placeat fuga doloribus distinctio. Ullam minima ducimus temporibus modi aut architecto ducimus. Voluptates explicabo exercitationem ut quis sed. Cupiditate sit vitae soluta. Non voluptates ut ullam quos qui illo error sunt laborum. A officia quaerat cumque.',
  'Provident esse hic. Quos esse ut ab voluptas sed quae nam. Sint autem rerum doloremque.',
  'Ut ut eos ducimus eos saepe consequatur vel in ut. Et molestiae ea ut. Placeat quidem est consequuntur aut est fuga. Placeat ex ut voluptas enim ex eveniet facere. Aut delectus aut nam et dolorum.',
  'Repellendus hic veritatis. Ex culpa earum voluptate vel labore. Ut est sunt corporis alias est et sequi voluptas.',
  'Aut rerum impedit ex rem voluptates voluptas. Totam perferendis ut non fugiat eligendi omnis. Quasi ipsum rem eos quod recusandae consectetur optio laudantium et. Possimus molestias facere quia. Ab quo occaecati quia ipsum sit qui accusantium odit doloremque.',
  'Aut eos quis ut. Omnis delectus voluptas minima. Ut minima sunt. Similique ut quam natus consequatur sit vel. Nostrum ut porro aut laborum iure molestiae et facere.',
  'Iste molestiae dolores commodi inventore sequi. Itaque quae sint. Dolor tempora et. Aperiam natus et perferendis magni quasi exercitationem delectus. Similique ad sed architecto magnam.',
  'Neque adipisci itaque culpa quis ut ab nulla. Labore ullam quos sed dolorum voluptatibus eum saepe. Omnis sit et quasi.',
  'Vel id omnis similique blanditiis voluptatem. Doloribus aut culpa ratione vel molestias omnis. Maxime sint libero laudantium.',
  'Et voluptas quis non. Architecto non explicabo facere neque et ex quaerat. Aut qui adipisci. Rerum in cupiditate voluptas molestiae fuga voluptatem quia et qui.',
  'Id corrupti sint eum vel quia sit magnam enim fuga. Ut est numquam et qui libero alias enim sequi quisquam. Autem asperiores quo accusantium reiciendis nihil.',
  'Nihil ut eum reprehenderit iure nobis. Omnis odit accusamus quo recusandae non. Perferendis voluptatum sit ut et veniam. Ut iusto aperiam. Debitis laudantium delectus illo. Occaecati explicabo aspernatur totam recusandae.',
  'Et quod hic libero nesciunt nisi omnis aut. Expedita omnis autem eius occaecati sit nesciunt hic culpa. Aut harum dolorem accusamus architecto magnam. Distinctio tempore et vitae nulla temporibus possimus. Eos in sit iste officia cumque mollitia eaque vitae. Consectetur voluptatem officiis aspernatur qui voluptas saepe dolores ad labore.',
  'Dolor alias debitis ipsum adipisci ratione inventore aliquam. Qui et reprehenderit velit et sequi minus. Et repellendus itaque est rerum dolores rerum quisquam. Non doloribus ipsam ducimus et. Asperiores exercitationem aliquam eos.',
  'Vitae optio sed omnis voluptatem. Quibusdam modi dolores tempore. Omnis sunt quaerat exercitationem enim ratione. Optio ducimus magnam sunt eveniet eos aut eum. Iste quo odio ut corrupti odio enim.',
  'Accusamus autem quae laborum ut. Corporis qui dolore ipsam suscipit hic. Accusamus aut tempora officia. Atque error quidem quis laudantium nemo. Qui dolore rerum totam animi minus voluptates reiciendis. Et tempore neque cum quis esse nostrum nobis.',
  'Quia ut nihil et qui animi dolor. Nemo consequatur quia exercitationem in est error laborum beatae. Ea non minima rerum perspiciatis. Quasi esse delectus magni numquam veniam cum. Est nemo sint rem sed maiores iure. Voluptatem illum et porro officiis omnis eveniet.',
  'Architecto rerum non. Cum esse tempore asperiores magnam odit dolores. Maiores quia eligendi laboriosam. Mollitia aut doloribus et id. Magnam ipsa nostrum. Praesentium autem in doloribus et velit nihil qui.',
  'In cum et repellat omnis. A ipsa id occaecati expedita in. Beatae et ut eveniet inventore. Repellendus quia et et quaerat exercitationem consequuntur suscipit. Eius et quasi mollitia aspernatur at. Ea et quaerat.',
  'Doloribus inventore non et sint officiis maxime deserunt enim minima. Voluptatem qui optio officia cumque debitis ut enim sed beatae. Eaque aut consequuntur illo animi distinctio commodi soluta. Aut nesciunt aut placeat est voluptatum qui ullam omnis tempore.',
];

/**
 * faker.lorem.paragraphs(2)
 */
export const paragraphs2 = [
  'Est id voluptates dolorem recusandae est perferendis. Ut qui et possimus hic in. Molestiae iste sed repudiandae consequatur rerum molestiae reprehenderit exercitationem corrupti. Id accusantium unde qui. Consequatur sint consequatur qui est sapiente aperiam quia ex consequuntur.\n' +
    ' Eligendi quas perferendis voluptatem. Hic sapiente dolorem suscipit perferendis rem. Odit sit quas magni.',
  'Sed nisi veniam earum ex culpa eveniet debitis et. Voluptatem et delectus praesentium et unde ut quibusdam in. Consequuntur quos hic animi ipsam. Tenetur deserunt eius. Dolore consequatur et ipsam ut saepe. In perspiciatis sit consectetur temporibus officia laborum quae illo.\n' +
    ' Ab soluta in sint in alias. Reiciendis repellendus autem aliquam aut vitae id nesciunt eum. In esse et ut.',
  'Officia optio placeat architecto vero eaque possimus temporibus. Ut et consequatur quia voluptatem tempore totam qui. Eum labore velit voluptatem in. Voluptatem magni dolorum natus et dolorem aut.\n' +
    ' Nobis velit ad molestiae aut nisi eius quibusdam. Aut recusandae et. Nam sint enim sit magnam ullam at ut non. Et amet sunt vitae minus quae occaecati dignissimos assumenda.',
  'Et laudantium vel nisi quidem et est. Nihil beatae labore voluptatibus quae sit. Vel et officiis architecto est.\n' +
    ' Amet neque cupiditate totam et nisi voluptatum. Nulla deserunt quo enim. Sint ut voluptates totam. Eius aut odio adipisci deleniti. Sit qui aut quia quas dolore.',
  'Illo esse nesciunt et id ut voluptatem aut necessitatibus. Temporibus voluptatum eius sequi velit dolorum soluta. Sequi et excepturi qui doloribus facere ratione. Ab occaecati eveniet repellendus. Voluptatem enim quia reprehenderit officiis vitae nulla aliquid.\n' +
    ' Et fuga hic. Dolorem fuga at quia. Quae et est. Omnis est voluptate et nihil aperiam qui assumenda. Minus corporis quaerat ipsam quaerat tempora harum tempore nisi omnis.',
  'Ducimus et dolor quae doloribus ducimus ullam. Qui ea ut omnis ut velit aut delectus quidem sed. Asperiores perspiciatis distinctio non consequatur dolores praesentium ex rerum accusantium. Nesciunt in maxime sint doloremque ipsam consequatur. Eos aut eligendi deleniti eligendi corrupti amet voluptas. Iste culpa enim doloribus corrupti sed.\n' +
    ' Tempora voluptatem est placeat. Blanditiis velit inventore aut molestias laudantium non est quibusdam corporis. Corrupti accusantium sed deserunt sunt. Dolorem vel quas placeat temporibus excepturi ut ut eius.',
  'Aut quaerat aperiam error impedit magni laudantium quis. Vitae modi quas ut eligendi vitae blanditiis molestiae ea aut. Harum quae et iure optio praesentium voluptas id nisi. Nulla minima molestiae illum dolorem expedita fuga aut qui id. Id sit soluta. Qui praesentium sunt.\n' +
    ' Accusantium a facere maiores qui. Maxime quo qui voluptatibus qui qui. Tempore voluptatum dicta et doloribus ea.',
  'Asperiores eaque et est eaque consequuntur quisquam dolorum est aut. Ut odit alias minima hic omnis et. Quibusdam excepturi repudiandae porro ratione beatae nesciunt et amet quo. Optio et iste doloribus eos quo maxime. Voluptatem voluptates repellat in sunt magnam rerum quia. Sed necessitatibus vel officiis ratione pariatur.\n' +
    ' Illum qui enim veniam voluptatibus doloribus ut et. Et ipsam est voluptates quia consequatur et. Quae excepturi tempora itaque voluptas doloremque. Porro atque necessitatibus est accusantium.',
  'Nemo voluptas rerum eos rerum natus sint. Ut qui aut et dolores nostrum suscipit aut. In sed enim qui sint quo sint cupiditate molestiae neque.\n' +
    ' Quis inventore recusandae rerum itaque in nulla vel et rerum. Consequuntur dicta voluptate tempora. Ipsam voluptatem quia vero est.',
  'Tenetur illum dolorem amet odio cumque. Labore debitis sunt deleniti. In inventore ad tempora ab.\n' +
    ' Dolores omnis occaecati dolor. Qui sunt non repellat. Illum qui deserunt. Reiciendis corporis ut voluptas. Et aperiam quo explicabo adipisci et quasi.',
  'Qui laborum veritatis aut. Eum quae corporis consequuntur atque veniam ut ipsam. Cupiditate dolorem magnam. Provident aut vel omnis beatae porro.\n' +
    'Voluptate amet voluptate autem aut et rem dolorem maiores. Recusandae labore iure sequi aut est ut sint sed. Minima nihil veritatis iste.',
  'Eius sunt et laudantium dolores placeat et laboriosam odio saepe. Consequatur ipsum deleniti odit. In esse dolorum sit est sed omnis. Corporis nihil dolor laboriosam possimus placeat.\n' +
    'Nulla excepturi culpa enim accusamus ut ab natus. Consequatur dolore et ipsam atque ipsam at. Quisquam ut sunt minus recusandae. At id ut nisi.',
  'Omnis expedita nihil accusamus sed et quisquam harum. Occaecati soluta est dignissimos magni sint quia voluptas officia. Odit omnis eos voluptatem iure enim. Et laudantium dicta.\n' +
    'Quod non assumenda harum sit architecto expedita. Ut aliquam eius sit nemo nihil omnis ut. Non distinctio voluptatem nesciunt et sed et.',
  'Est earum quo porro illo. Ea est maxime. Et et voluptatum ipsam cumque natus voluptates eos labore autem. Repudiandae dolore sunt accusamus ea commodi corporis officia soluta. Voluptatum deleniti iste velit eos impedit. Eius reprehenderit et officiis quo reprehenderit id.\n' +
    'Est voluptatem qui. Facere occaecati voluptatibus velit cupiditate. Quia magni unde id voluptas repellendus qui molestiae.',
  'Consequatur consectetur qui aut molestiae nihil fugit. Omnis architecto neque harum quod expedita quo placeat. Laudantium minus recusandae molestiae fugiat sunt. Eum veritatis tenetur natus minus quod rerum repudiandae modi. Et aspernatur sed tempore optio velit. Autem natus autem beatae voluptas.\n' +
    'Qui non nisi qui veniam laboriosam excepturi eum eos ut. Natus at assumenda officia autem. Porro deleniti beatae porro ut dolorum aut earum voluptatem voluptatem. Eos dolor in dolorem. Est repellendus dolorem ex perferendis autem libero cum. Officiis et fuga aliquid perspiciatis qui ut assumenda aut eaque.',
  'Laboriosam consequuntur repellendus rem omnis est doloribus et architecto itaque. Veritatis est nobis ipsa velit et id. Id odio et omnis cupiditate. Qui rerum est et dicta. Ut officiis non illo velit quaerat sapiente soluta illum. Voluptatem placeat et maiores libero cumque consequuntur.\n' +
    'Cumque deleniti velit ipsam dicta perferendis. Quidem iusto voluptatem eos sequi illum cumque similique quisquam. Est ex soluta ratione quidem qui. Cupiditate aut animi vel dolor accusamus consequuntur temporibus veniam est. Maiores animi explicabo consequatur illum suscipit inventore. Dolorem et aut quo omnis eum quaerat amet quasi commodi.',
  'Voluptates quas rerum omnis et dolor reprehenderit. Quis sunt rerum consequatur facilis autem laborum voluptatem vel. Sint modi ut quo autem quia est beatae. Dolorum doloremque magnam maiores consequatur at. Voluptatibus voluptatibus voluptas neque et non temporibus similique corrupti. Et quia porro in nisi sunt.\n' +
    'Laborum dolorum occaecati vero sit modi voluptate est eius. Cum vel omnis. Et sit blanditiis exercitationem.',
  'Dolor sapiente qui tempora autem corrupti praesentium consequuntur. Illo aperiam magnam nam et eum voluptate. Nemo alias sit rem. Eligendi eum ut.\n' +
    'Molestiae aut consectetur consequatur suscipit. Sit omnis repellat omnis enim totam quis doloremque et exercitationem. Perspiciatis maxime ut et. Eaque voluptas magni officia modi beatae et perferendis iusto. Quaerat esse consequatur aliquid sapiente et sunt esse ad nemo. Iure magni voluptatum sint.',
  'Dolorum sint consequuntur. Aspernatur nihil molestiae recusandae dolor sit. Ut voluptates ratione similique impedit hic officiis odio ratione. Asperiores non occaecati quo dignissimos repudiandae. Est ea consectetur quis voluptas quod non veniam. Non occaecati et numquam occaecati.\n' +
    'Rerum unde occaecati sit impedit excepturi iusto. Nobis doloremque cupiditate dolores. Pariatur esse ut sit incidunt illum quo voluptatibus. Dicta et et nostrum molestias quidem.',
  'Iste sequi ea molestias eligendi porro. Omnis qui ipsam non ducimus possimus. Voluptas quasi omnis ut sed totam voluptates exercitationem. Doloribus voluptatem optio qui quis asperiores. Nam eius pariatur. Quo ut voluptatem non magnam amet ea et qui.\n' +
    'Dolorum occaecati optio possimus repellat suscipit. Amet neque aut consequatur. Beatae distinctio est voluptatem molestiae. Velit eaque exercitationem corporis accusantium.',
];

/**
 * faker.lorem.paragraph(2)
 */
export const paragraph2 = [
  'Autem voluptatem aut id dolorem tenetur aut esse assumenda adipisci. Dolorem harum fuga libero inventore voluptatum voluptas perferendis voluptatibus. Est quos non qui qui.',
  'Blanditiis laborum eos voluptatem aut aut. Enim voluptatem asperiores maiores dolorem eius laudantium laboriosam quidem. Eaque facere aliquam laudantium accusamus consequuntur eos.',
  'Quas est tempore. Assumenda ex magni qui voluptatem eos occaecati recusandae consequatur. Perferendis aut tempore optio est facere.',
  'Repudiandae dolores facilis consequatur consequatur odit atque. Vero error eum fuga est. Tempore vel culpa dolore ad nihil.',
  'Tempore unde aliquid dolore vero aut adipisci dolore placeat. Nostrum et est voluptatem. Cupiditate consequatur vel ex aperiam numquam. Assumenda qui nemo reiciendis minus inventore maxime neque modi.',
  'Ut velit enim qui. Autem minus accusantium amet aut et.',
  'Et est vitae dolorem incidunt nobis quia animi. Expedita quo sint omnis quasi corporis rem fugiat atque iusto. Et aut nihil doloremque deleniti et consequuntur. Reiciendis est nesciunt voluptate sed recusandae.',
  'Ea ducimus ratione voluptas qui nisi amet. Facilis ducimus facere. Non aut animi vel placeat sapiente quidem nemo mollitia non.',
  'Minus ea rerum. Nihil molestiae dolor. Illo temporibus fugiat placeat consectetur.',
  'Vitae ullam velit beatae architecto et. Quis quisquam provident ducimus dolores in magni sed quia quisquam. Nulla molestias similique.',
  'Sed velit in. Natus voluptatem est molestias culpa ducimus cupiditate. Ut minima id consequatur in dolores dolor explicabo quia.',
  'Et qui neque aut recusandae et vero impedit quos ut. Unde esse quo voluptas. Omnis nisi fugit fugit. Unde aspernatur sequi est odit pariatur praesentium. Et dolor quo.',
  'Et ad neque suscipit neque. Delectus et nemo sed iusto architecto. Deleniti magnam ducimus quibusdam ipsum voluptas omnis aliquid et.',
  'Aliquid id cupiditate et aut eaque velit alias qui. Quis ea rerum aliquid fugiat assumenda odio dolor.',
  'Inventore iure consequatur voluptatum incidunt doloribus numquam. Quam commodi repellendus et molestias suscipit sapiente. Et non esse et nesciunt. Ipsam et facilis saepe cum saepe suscipit alias veritatis. Dolorem in mollitia explicabo est tempore consequuntur dignissimos.',
  'Consectetur perspiciatis repudiandae molestias qui corrupti at officiis rerum eos. Dolor aut veniam quae quas ipsum voluptatem. Non corporis aut et hic. Minus corporis asperiores cumque voluptas.',
  'Nihil nostrum aut autem repellendus omnis eos ut aliquid. Sint eos et dolores facilis. Molestias non qui non voluptatem quisquam consequatur. Ipsum ullam doloremque pariatur quis accusamus ut distinctio. Rem molestiae qui ut illo officiis et.',
  'Veritatis magnam numquam incidunt. Ea ducimus inventore. Non dolorem ut excepturi nesciunt itaque. Sequi ipsa eum quo dicta et est. Ratione molestiae eos ea.',
  'Ut debitis cum vero et natus sint aut dolorem similique. Qui deleniti rerum sint. Modi aut at consequatur illum et sit ut id nisi. Blanditiis aut est fugit et amet repudiandae excepturi iusto. Hic est rerum sint id est deleniti iste id.',
  'Doloribus hic sequi iure aut non ad officia iusto dolore. Voluptas ad quod repellat quia. Autem minima at dolorum nobis sapiente mollitia facilis quam harum. Et labore sint distinctio amet dolor consequatur. Et sit fugit excepturi.',
];

/**
 * faker.lorem.sentences(2)
 */
export const sentences2 = [
  'Est ex ut odit saepe. Animi odit repellendus maiores temporibus dolor voluptatibus sunt nobis.',
  'Et repellendus quasi. Provident non qui quod.',
  'Voluptatem voluptatem omnis ducimus numquam unde. Nulla est illum quia voluptatem eos voluptatibus sit non.',
  'Suscipit natus nihil ea est possimus quia porro maxime. Reiciendis minima esse alias sunt recusandae voluptatem vero tempora qui.',
  'Sint non impedit aperiam dolor omnis autem nihil assumenda. Placeat cumque laborum mollitia.',
  'Ad optio veritatis alias nihil est. Eveniet est labore non quia.',
  'Eius ex corporis. Aut dolor perspiciatis consectetur quis sed eos quia nostrum et.',
  'Hic quia eaque blanditiis laudantium dignissimos quasi. Deserunt molestiae animi ut aliquid cum voluptatem modi ipsam.',
  'Et ipsam ea et nihil debitis. Ducimus qui repellendus laudantium blanditiis aut expedita.',
  'Magni consequuntur et magni voluptates nesciunt id accusamus voluptas maiores. Itaque et eum voluptas atque et libero.',
  'Dolore qui consequatur atque perspiciatis dolor. Non odio ea.',
  'Ut inventore odit et. Odio et qui beatae maiores rerum et sed saepe.',
  'Voluptatem recusandae similique dolor aut vel eaque consectetur quos molestiae. Aut consectetur rerum recusandae consequuntur est labore id.',
  'Voluptatem sunt beatae voluptas odit amet voluptate eos. Non voluptatem asperiores aut dolorem consectetur voluptatem omnis fugit aliquam.',
  'Autem qui voluptatem dolore. Cum illum dolor sint rerum dolores.',
  'Recusandae debitis vitae. Itaque illum expedita ea sunt et est.',
  'Enim nostrum maiores incidunt totam nihil vel. Quidem facilis consectetur eaque.',
  'Expedita qui ut quibusdam laboriosam illum et sunt. Pariatur voluptatem nulla porro accusamus voluptatum velit delectus dolore est.',
  'Quibusdam impedit tempore incidunt eum repellendus accusantium. Ipsam optio corrupti consequatur accusantium qui quia.',
  'Quae vero quia cupiditate doloremque ut. Quis placeat fugiat deleniti blanditiis vel ex reiciendis soluta dolor.',
];

/**
 * faker.lorem.sentence(1)
 */
export const sentence1 = [
  'Sunt.',
  'Vel.',
  'Earum.',
  'Sint.',
  'Sint.',
  'Dicta.',
  'Adipisci.',
  'Consequuntur.',
  'Pariatur.',
  'Id.',
  'Fugit.',
  'Fugit.',
  'Adipisci.',
  'Earum.',
  'Voluptatibus.',
  'Perferendis.',
  'Esse.',
  'Animi.',
  'Fugit.',
  'Commodi.',
];

/**
 * faker.lorem.sentence(2)
 */
export const sentence2 = [
  'Magnam sapiente.',
  'Cumque similique.',
  'Ut rerum.',
  'Hic vero.',
  'Fuga nihil.',
  'Quo dolor.',
  'Consequatur dolorum.',
  'Doloribus iste.',
  'Beatae veritatis.',
  'Quia odio.',
  'Assumenda reiciendis.',
  'Maxime accusantium.',
  'Repellat ut.',
  'Molestiae tempore.',
  'Consequuntur consequatur.',
  'Voluptas quos.',
  'Optio repudiandae.',
  'Quia impedit.',
  'Ratione sit.',
  'Dolor non.',
];

/**
 * faker.lorem.sentence(3)
 */
export const sentence3 = [
  'Rerum eos aut.',
  'Aut qui dicta.',
  'Eos molestiae odio.',
  'Officia aliquam eos.',
  'Et et voluptatum.',
  'Provident dolores quo.',
  'Non maxime eveniet.',
  'Voluptas quos et.',
  'Vero et quam.',
  'Est nobis et.',
  'Eos tenetur quis.',
  'Necessitatibus omnis ut.',
  'Nulla aut voluptatem.',
  'Rem et necessitatibus.',
  'Ratione ut blanditiis.',
  'Voluptas fuga sed.',
  'Nesciunt itaque ut.',
  'Excepturi velit unde.',
  'Nobis eaque odio.',
  'Reiciendis unde saepe.',
];

/**
 * faker.lorem.sentence(5)
 */
export const sentence5 = [
  'In iure quas sed consectetur.',
  'Saepe delectus itaque exercitationem eius.',
  'Aut natus qui dolores perferendis.',
  'Impedit porro sequi error alias.',
  'Autem perferendis molestias eius autem.',
  'Et optio veritatis omnis nostrum.',
  'Voluptate ratione est et dolor.',
  'Qui quia enim ipsum tenetur.',
  'Voluptate sint et pariatur voluptate.',
  'Cupiditate aut aspernatur voluptatem ratione.',
  'Id ipsa impedit odit reiciendis.',
  'Atque hic totam ut adipisci.',
  'Itaque tempore autem iure qui.',
  'Tenetur odio vel soluta ipsam.',
  'Molestiae iste laboriosam est et.',
  'Dolorem molestias minima aliquam error.',
  'Eveniet officia harum nesciunt amet.',
  'Voluptates odit voluptatem et ipsa.',
  'Repudiandae maxime mollitia accusantium quo.',
  'Nihil nobis odio nesciunt magnam.',
];

/**
 * faker.lorem.sentence(20)
 */
export const sentence20 = [
  'Voluptatibus esse quidem cupiditate autem veniam vero voluptatem dolorum blanditiis magnam qui autem qui et consequatur ea laborum sit doloremque.',
  'Optio veritatis ut quaerat consequatur deserunt exercitationem dicta perspiciatis eos corrupti architecto at voluptas necessitatibus consequatur sit recusandae qui dolore.',
  'Eveniet iste ducimus natus et voluptatum minima qui est itaque soluta reprehenderit tempora quae perferendis aliquam esse et itaque exercitationem.',
  'Nisi ut dolor omnis quia et nisi est ratione error porro ipsum cumque nihil est placeat aliquam aperiam eligendi atque.',
  'Tempora occaecati nihil sed quis neque ex quidem inventore ut harum nesciunt impedit iure laborum quas est dolorem ipsum iste.',
  'Voluptatem consequatur sint dolores officiis omnis sit voluptatibus laudantium earum molestiae laboriosam dolores eligendi neque distinctio nisi eum voluptatem nulla.',
  'Nulla iste et tempore molestiae et est consequatur ratione quo molestias molestias exercitationem consectetur eos eos tempore vel harum quisquam.',
  'Consequuntur veritatis rem aut maiores voluptatibus hic est facilis alias voluptatem rem rerum ut error natus totam et molestiae et.',
  'Animi veritatis pariatur corporis facere quos culpa velit molestiae voluptatem et corrupti doloribus veritatis illo fugit velit hic nihil facere.',
  'Odit aut fuga qui quae et veniam facilis veritatis nam nihil tempora libero vitae vel sunt amet alias placeat a.',
  'Est et ut ea debitis ipsum sed deserunt eveniet atque tempora accusantium necessitatibus molestiae impedit voluptas quos quidem et repellendus.',
  'Veritatis consequatur et doloremque accusamus natus non rerum in dolorum aut officia tenetur odit nostrum qui minima recusandae ut omnis.',
  'Officia id vitae totam ratione dolores sit est debitis est repudiandae assumenda animi minima est consequatur laborum quia reprehenderit excepturi.',
  'Dolor vitae debitis culpa omnis ducimus omnis officia sit dolorum voluptatibus cumque numquam dicta voluptates voluptas deserunt quas sint beatae.',
  'Magni tenetur dolore qui et enim aut ea delectus quas corrupti sint atque consequatur ipsum dolores aspernatur quidem nemo totam.',
  'Ea ducimus distinctio in debitis qui eius tempore explicabo atque temporibus illum qui dolorem tempore tenetur omnis aliquam nobis repudiandae.',
  'Nemo placeat quisquam tempore unde sequi ducimus possimus consequatur consequatur impedit pariatur aspernatur itaque et sint non laborum dignissimos neque.',
  'Laudantium consequatur est sed asperiores et minima perferendis odit quasi ut deserunt hic maxime suscipit libero consequatur a aut dolorem.',
  'Cum sit iure debitis nulla delectus aut fuga nesciunt voluptatibus consequuntur nam dolor voluptatem rerum harum aut voluptas accusantium rerum.',
  'Dolorum nulla repudiandae et animi numquam est et rerum et maxime debitis amet doloribus consectetur molestias eos nulla et est.',
];

/**
 * faker.system.fileName()
 */
export const fileName = [
  'soft_liberia.ogx',
  'garden_adaptive.uvv',
  'shoes_administrator.m2a',
  'payment_dynamic_ergonomic.cc',
  'frozen_chips.m21',
  'borders_generating_ball.skd',
  'refined_stream_integrated.sxc',
  'cyprus_dynamic.ssdl',
  'protocol_bleeding_edge.tr',
  'taiwan_computers_handmade.dcurl',
  'auxiliary_malawi_switzerland.mny',
  'sdd_dynamic.bmml',
  'gloves.caf',
  'implementation.ecelp7470',
  'avon_usb.zir',
  'virtual_b2c.ngdat',
  'intranet_innovate_unbranded.pkg',
  'xml.bz',
  'metal_samoa.jardiff',
  'ram_account.rpm',
];

/**
 * faker.person.jobTitle()
 */
export const jobTitle = [
  'Product Markets Analyst',
  'District Usability Coordinator',
  'Direct Integration Technician',
  'Corporate Optimization Supervisor',
  'Forward Accounts Liaison',
  'Principal Security Assistant',
  'Regional Directives Consultant',
  'Legacy Implementation Officer',
  'Legacy Tactics Specialist',
  'Investor Integration Developer',
  'Future Security Director',
  'Regional Infrastructure Assistant',
  'Investor Tactics Assistant',
  'Future Implementation Coordinator',
  'National Quality Strategist',
  'Senior Implementation Officer',
  'Legacy Functionality Developer',
  'Investor Interactions Administrator',
  'Principal Functionality Administrator',
  'District Optimization Producer',
];

/**
 * faker.commerce.productName()
 */
export const productName = [
  'Rustic Cotton Chair',
  'Incredible Wooden Table',
  'Handcrafted Soft Shirt',
  'Refined Plastic Hat',
  'Awesome Plastic Chips',
  'Intelligent Fresh Sausages',
  'Handcrafted Frozen Sausages',
  'Awesome Steel Fish',
  'Ergonomic Soft Shoes',
  'Incredible Plastic Pizza',
  'Oriental Fresh Keyboard',
  'Bespoke Frozen Hat',
  'Gorgeous Metal Gloves',
  'Gorgeous Fresh Towels',
  'Bespoke Bronze Chips',
  'Generic Cotton Pants',
  'Handmade Wooden Pants',
  'Luxurious Cotton Soap',
  'Incredible Plastic Chair',
  'Fantastic Granite Salad',
];

/**
 * faker.number.int(20000)
 */
export const number20000 = [
  18745, 6844, 9791, 15867, 10793, 9479, 17906, 5138, 8933, 1912, 17748, 1394,
  6018, 8034, 14589, 17094, 7549, 15428, 10654, 3201,
];

/**
 * faker.number.int(10000)
 */
export const number10000 = [
  8771, 1057, 2536, 2629, 2738, 3964, 3283, 7405, 5476, 3179, 2426, 1451, 2967,
  7800, 9717, 8771, 9505, 1115, 6821, 5127,
];

/**
 * faker.number.int(100)
 */
export const number100 = [
  11, 80, 52, 90, 98, 54, 70, 50, 20, 28, 29, 43, 81, 15, 23, 18, 89, 17, 61,
  29,
];

/**
 * faker.number.int(10)
 */
export const number10 = [
  2, 5, 7, 9, 1, 10, 1, 0, 9, 1, 5, 4, 3, 3, 1, 1, 0, 6, 2, 1,
];

/**
 * faker.string.alphaNumeric(10)
 */
export const alphaNumeric10 = [
  '88dck5is2d',
  'v2yqtcahjp',
  'cjj8zrbfox',
  'byr1bhw2rf',
  'r5gk7n1ybc',
  '1iovo9fnr5',
  'jc1s416mcr',
  '1tlgipm5fm',
  'uvgv0fv9nu',
  't9y31f8i22',
  'tq03p7hsfu',
  'pwxhn2ys2n',
  'owyfxz2742',
  'efrggnczr5',
  'ozr0b949l2',
  'sswe57t658',
  'wn7vaeqe5l',
  'ckmph0a1ds',
  'st48beutxp',
  'uuc872oc6c',
];

/**
 * faker.location.streetAddress()
 */
export const streetAddress = [
  '4820 Jaskolski Mission',
  '97581 Kuhlman Camp',
  '2499 Lessie Village',
  '973 Hailey Burg',
  '9289 Destiny Prairie',
  '58676 Reynolds Place',
  '24628 Littel Ridge',
  '78566 Delmer Mountain',
  '2983 Anita Land',
  '8023 Shannon Stream',
  '297 Goyette Flat',
  '5312 Alessandro Park',
  '8064 Schaden Islands',
  '3167 Zetta Pike',
  '8479 Gennaro Bypass',
  '69689 Else Prairie',
  '050 Batz Bridge',
  '390 Kaley Orchard',
  '913 Milford Walk',
  '65545 Katrine Curve',
];

/**
 * faker.location.secondaryAddress()
 */
export const secondaryAddress = [
  'Suite 405',
  'Apt. 506',
  'Suite 812',
  'Suite 845',
  'Apt. 162',
  'Suite 572',
  'Suite 131',
  'Apt. 886',
  'Suite 923',
  'Suite 545',
  'Apt. 064',
  'Suite 409',
  'Suite 142',
  'Apt. 657',
  'Apt. 566',
  'Suite 025',
  'Suite 835',
  'Suite 070',
  'Apt. 818',
  'Apt. 138',
];

/**
 * faker.location.city()
 */
export const city = [
  'Levimouth',
  'West Neomafort',
  'Longmont',
  'Diannaville',
  'Romaguerafurt',
  'South Ernaview',
  'Chicopee',
  'Mabellehaven',
  'New Britain',
  'Lake Murielhaven',
  'West Alanville',
  'North Meagan',
  'New Gastonstad',
  'Fort Albina',
  'South Gerard',
  'Commerce City',
  'Westminster',
  'Mitchellbury',
  'Jersey City',
  'South Marlonboro',
];

/**
 * faker.location.zipCode()
 */
export const zipCode = [
  '39107',
  '45195',
  '02287-6063',
  '09945-0259',
  '58475',
  '24393-8205',
  '61049-8748',
  '73044',
  '46624-3716',
  '66621-9717',
  '25132-3983',
  '36789-1907',
  '74333-7687',
  '38229-2323',
  '80610-9328',
  '73804-4446',
  '58741-6044',
  '26040',
  '07699',
  '71677',
];

/**
 * faker.internet.email()
 */
export const email = [
  'Stuart79@hotmail.com',
  'Lesly.Hudson53@gmail.com',
  'Luz76@hotmail.com',
  'Hester42@yahoo.com',
  'Vernie.Hintz93@gmail.com',
  'Elsa_Miller60@hotmail.com',
  'Connor23@yahoo.com',
  'Jakayla34@gmail.com',
  'Jazlyn_Renner80@gmail.com',
  'Karson42@gmail.com',
  'Freeman.Deckow@hotmail.com',
  'Keyon91@hotmail.com',
  'Joany26@hotmail.com',
  'Max.Mayer@gmail.com',
  'Velma90@gmail.com',
  'Colin96@gmail.com',
  'Cydney30@gmail.com',
  'Myra_Kemmer69@hotmail.com',
  'Laurence_Little58@yahoo.com',
  'Hosea.Hoppe@hotmail.com',
];

/**
 * faker.person.firstName()
 */
export const firstName = [
  'May',
  'Samanta',
  'Otis',
  'Madisyn',
  'Idella',
  'Krista',
  'Rex',
  'Coleman',
  'Giovanni',
  'Junius',
  'Samantha',
  'Maryam',
  'Ashtyn',
  'Aliyah',
  'Jose',
  'Braxton',
  'Wilburn',
  'Wilma',
  'Dessie',
  'Virginie',
];

/**
 * faker.person.lastName()
 */
export const lastName = [
  'Ward',
  'Tremblay',
  'Bechtelar',
  'Haag',
  'Kuhn',
  'Strosin',
  'Swift',
  'Upton',
  'Stehr',
  'Harris',
  'Crooks',
  'Miller',
  'Gerlach',
  'Schaden',
  'Turcotte',
  'Schneider',
  'Runte',
  'Abernathy',
  'Feil',
  'Pfeffer',
];

/**
 * faker.date.between({ from: '1900', to: '2000' })
 */
export const dateBetween1900And2000 = [
  '1979-08-19T15:21:10.547Z',
  '1968-04-22T14:35:43.104Z',
  '1950-06-22T21:07:15.299Z',
  '1989-12-03T03:30:07.245Z',
  '1982-01-27T23:02:59.135Z',
  '1907-07-28T18:04:16.097Z',
  '1948-08-18T00:59:37.978Z',
  '1987-11-10T13:02:07.307Z',
  '1984-10-09T02:13:02.106Z',
  '1919-02-01T05:12:15.343Z',
  '1955-02-23T14:41:23.456Z',
  '1907-09-27T16:40:44.496Z',
  '1908-10-31T01:41:25.977Z',
  '1902-12-31T18:46:30.051Z',
  '1938-05-28T15:51:27.938Z',
  '1943-08-14T02:43:17.732Z',
  '1946-11-15T08:47:10.928Z',
  '1965-11-22T13:32:43.335Z',
  '1942-10-23T15:21:29.414Z',
  '1912-03-20T19:18:50.498Z',
];

/**
 * faker.internet.url()
 */
export const url = [
  'https://ross.info',
  'https://braeden.info',
  'http://camron.com',
  'https://shyann.org',
  'http://reina.org',
  'https://frederik.org',
  'https://lamar.net',
  'http://shane.info',
  'http://ethyl.org',
  'https://pink.info',
  'http://clear-cut-classification.com',
  'http://infantile-article.net',
  'http://big-hearted-citizenship.net',
  'http://meek-plenty.com',
  'https://glum-coat.info',
  'https://mindless-tennis.com',
  'https://mundane-well.name',
  'http://disloyal-dump-truck.com',
  'https://aggressive-alb.org',
  'http://supportive-envelope.biz',
];

/**
 * linkedin urls
 */
export const linkedInUrls = [
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-ross`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-braeden`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-camron`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-shyann`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-reina`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-frederik`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-lamar`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-shane`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-ethyl`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-pink`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-clear-cut-classification`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-infantile-article`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-big-hearted-citizenship`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-meek-plenty`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-glum-coat`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-mindless-tennis`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-mundane-well`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-disloyal-dump-truck`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-aggressive-alb`,
  `${LINKEDIN_BASE_URL}/${LINKEDIN_TEST_PREFIX}-supportive-envelope`,
];

/**
 * picsum.photos
 */
export const image = [
  'https://picsum.photos/seed/0/1024/576',
  'https://picsum.photos/seed/1/1024/576',
  'https://picsum.photos/seed/2/1024/576',
  'https://picsum.photos/seed/3/1024/576',
  'https://picsum.photos/seed/4/1024/576',
  'https://picsum.photos/seed/5/1024/576',
  'https://picsum.photos/seed/6/1024/576',
  'https://picsum.photos/seed/7/1024/576',
  'https://picsum.photos/seed/8/1024/576',
  'https://picsum.photos/seed/9/1024/576',
  'https://picsum.photos/seed/10/1024/576',
  'https://picsum.photos/seed/11/1024/576',
  'https://picsum.photos/seed/12/1024/576',
  'https://picsum.photos/seed/13/1024/576',
  'https://picsum.photos/seed/14/1024/576',
  'https://picsum.photos/seed/15/1024/576',
  'https://picsum.photos/seed/16/1024/576',
  'https://picsum.photos/seed/17/1024/576',
  'https://picsum.photos/seed/18/1024/576',
  'https://picsum.photos/seed/19/1024/576',
];

/**
 * faker.person.jobDescriptor()
 */
export const jobDescriptor = [
  'Human',
  'Direct',
  'District',
  'Global',
  'Forward',
  'CTO',
  'CFO',
  'Legacy',
  'COO',
  'Central',
  'Internal',
  'Investor',
  'Chief',
  'CSO',
  'Dynamic',
  'Designer',
  'Corporate',
  'Principal',
  'Product',
  'CEO',
];

export const offeredServiceIcons = [
  'calculator',
  'calendar',
  'camera',
  'cloud',
  'code-bracket',
  'document-check',
  'map',
  'radio',
  'rss',
];

export const languages = [
  'en',
  'fr',
  'de',
  'pm',
  'it',
  'es',
  'nl',
  'no',
  'sv',
  'fi',
];

export const questions = [
  'Do you play sushi go?',
  'How many brothers and sisters you have?',
  'What is your favorite show?',
  'Do you like more cats or dogs?',
];
