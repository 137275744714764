import {
  email,
  firstName,
  lastName,
  sentence2,
  sentence5,
} from '@collective/utils/fixtures';
import cuid from 'cuid';

import { Collective_CollectiveMemberType, MemberRole } from '../../generated';

export const generateCollectiveMember = (
  props?: Partial<Collective_CollectiveMemberType>,
  n = 0
): Collective_CollectiveMemberType => {
  return {
    id: cuid(),
    isVisible: true,
    isActivated: true,
    email: email[n],
    firstname: firstName[n],
    lastname: lastName[n],
    title: sentence2[n],
    bio: sentence5[n],
    role: MemberRole.Member,
    ...props,
  };
};
