import {
  IconBehance,
  IconDribbble,
  IconExternalLink,
  IconFiver,
  IconGitHub,
  IconInstagram,
  IconLinkedIn,
  IconMalt,
  IconPinterest,
  IconStackOverflow,
  IconTikTok,
  IconTwitch,
  IconUpwork,
  IconVimeo,
  IconXCom,
  IconYouTube,
} from '@collective/ui';
import { detectWebsite, WEBSITES } from '@collective/utils/shared';

export const getLinkIcon = (url?: string) => {
  const icons = {
    [WEBSITES.LINKEDIN]: IconLinkedIn,
    [WEBSITES.GITHUB]: IconGitHub,
    [WEBSITES.STACKOVERFLOW]: IconStackOverflow,
    [WEBSITES.DRIBBBLE]: IconDribbble,
    [WEBSITES.BEHANCE]: IconBehance,
    [WEBSITES.YOUTUBE]: IconYouTube,
    [WEBSITES.INSTAGRAM]: IconInstagram,
    [WEBSITES.X]: IconXCom,
    [WEBSITES.TIKTOK]: IconTikTok,
    [WEBSITES.MALT]: IconMalt,
    [WEBSITES.FIVER]: IconFiver,
    [WEBSITES.UPWORK]: IconUpwork,
    [WEBSITES.VIMEO]: IconVimeo,
    [WEBSITES.PINTEREST]: IconPinterest,
    [WEBSITES.TWITCH]: IconTwitch,
  };

  const website = detectWebsite(url);
  return website ? icons[website] : IconExternalLink;
};
