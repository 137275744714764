import {
  dateBetween1900And2000,
  number20000,
  sentence5,
  sentences2,
} from '@collective/utils/fixtures';
import cuid from 'cuid';

import {
  Collective_MilestoneType,
  ContractMilestoneCondition,
} from '../../generated';
import { generateMilestoneMember } from './milestone-member';

export const generateMilestone = (
  props?: Partial<Collective_MilestoneType>,
  n = 0
): Collective_MilestoneType => {
  const id = cuid();

  return {
    id,
    proposalId: cuid(),
    dueDate: dateBetween1900And2000[n],
    name: sentence5[n],
    description: sentences2[n],
    amount: number20000[n],
    ranking: 0,
    milestoneMembers: [generateMilestoneMember({ milestoneId: id }, n)],
    isUpfrontPayment: false,
    condition: ContractMilestoneCondition.When,
    ...props,
  };
};
