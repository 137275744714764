import {
  alphaNumeric10,
  city,
  companyName,
  country,
  secondaryAddress,
  streetAddress,
  zipCode,
} from '@collective/utils/fixtures';
import cuid from 'cuid';

import {
  Collective_UserCompanyType,
  CompanyClassification,
  CountryCode,
  HasLegalStructure,
  ParticipationStatus,
} from '../../generated';

export const generateUserCompany = (
  props?: Partial<Collective_UserCompanyType>,
  n = 0
): Collective_UserCompanyType => {
  return {
    id: cuid(),
    userId: cuid(),
    name: companyName[n],
    classification: CompanyClassification.FrenchMicroAuto,
    companyRegistrationId: alphaNumeric10[n],
    address: streetAddress[n],
    addressAdditionalInformation: secondaryAddress[n],
    city: city[n],
    zipCode: zipCode[n],
    country: country[n] as CountryCode,
    isChargingVat: true,
    vatNumber: alphaNumeric10[n + 1],
    hasLegalStructure: HasLegalStructure.Yes,
    participationStatus: ParticipationStatus.CanParticipate,
    verified: false,
    lastVerifiedBy: '',
    ...props,
  };
};
