import { Box, Divider, Text } from '@collective/ui';
import { ReactNode } from 'react';

type JobSectionProps = {
  label: string;
  children: ReactNode;
};

export const JobSection = ({ label, children }: JobSectionProps) => {
  return (
    <Box>
      <Text variant="desktop-s-medium" color="rythm.700" mb={2}>
        {label}
      </Text>
      <Divider borderColor="rythm.300" mb={4} />
      {children}
    </Box>
  );
};
