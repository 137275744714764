import { firstName, lastName, sentence5 } from '@collective/utils/fixtures';
import cuid from 'cuid';
import dayjs from 'dayjs';

import { Collective_TestimonialType } from '../../generated';

export const generateTestimonial = (
  props?: Partial<Collective_TestimonialType>,
  n = 0
): Collective_TestimonialType => {
  return {
    id: cuid(),
    fullname: `${firstName[n]} ${lastName[n]}`,
    text: sentence5[n],
    createdAt: dayjs().subtract(5, 'days').toISOString(),
    updatedAt: new Date().toISOString(),
    ...props,
  };
};
