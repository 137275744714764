import { difference } from 'lodash';
import { Children, ReactElement, ReactNode, useEffect } from 'react';

import { AnimatedStep, AnimatedStepProps } from './animated-step';

export const useCheckChildrenStep = (
  children: ReactNode[],
  steps: readonly string[]
) => {
  useEffect(() => {
    const childrenStep: string[] = [];

    Children.forEach(children, (child, index) => {
      if (!isAnimatedStepComponent(child)) {
        throw new Error(
          `AnimatedMultiStepLayer only accepts AnimatedStep as children (error for child at index ${index})`
        );
      }

      if (!steps.includes(child.props.step)) {
        throw new Error(
          `Invalid step in your AnimatedStep at index ${index}. Found "${
            child.props.step
          }", possible values are : "${steps.join('", "')}"`
        );
      }
      if (child.props.nextStep && !steps.includes(child.props.nextStep)) {
        throw new Error(
          `Invalid nextStep in your AnimatedStep at index ${index}. Found "${
            child.props.nextStep
          }", possible values are : "${steps.join('", "')}" or null`
        );
      }
      if (childrenStep.includes(child.props.step)) {
        throw new Error(
          `Invalid step in your AnimatedStep at index ${index}. The step "${child.props.step}" has already been defined`
        );
      }
      childrenStep.push(child.props.step);
    });

    if (childrenStep.length !== steps.length) {
      const stepDiff = difference(steps, childrenStep);

      throw new Error(
        `These steps are not defined as AnimatedSteps : "${stepDiff.join(
          '", "'
        )}"`
      );
    }
  }, [children, steps]);
};

function isAnimatedStepComponent(
  node: ReactNode
): node is ReactElement<AnimatedStepProps, 'AnimatedStep'> {
  return !!(
    typeof node === 'object' &&
    node &&
    'type' in node &&
    typeof node.type !== 'string' &&
    node.type.name === AnimatedStep.name
  );
}
