import {
  jobDescriptor,
  number100,
  number10000,
} from '@collective/utils/fixtures';
import cuid from 'cuid';

import {
  Collective_PaymentRequestPayAsYouGoMemberType,
  PayAsYouGoUnit,
} from '../../generated';
import { generateMember } from './member';

export const generatePaymentRequestPayAsYouGoMember = (
  props?: Partial<Collective_PaymentRequestPayAsYouGoMemberType>,
  n = 0
): Collective_PaymentRequestPayAsYouGoMemberType => ({
  id: cuid(),
  paymentRequestId: cuid(),
  memberId: cuid(),
  description: jobDescriptor[n],
  amount: number10000[n],
  quantity: number100[n].toString(),
  unit: PayAsYouGoUnit.Hours,
  member: generateMember({}, n),
  ...props,
});
