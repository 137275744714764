import {
  Avatar,
  BorderBox,
  Box,
  Flex,
  IconHeartFilled,
  Text,
} from '@collective/ui';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NoteBanner } from './types';

type SoloApplicationNoteBannerProps = {
  isFavorite: boolean;
  noteBanner: NoteBanner;
};

export const SoloApplicationNoteBanner = ({
  isFavorite,
  noteBanner,
}: SoloApplicationNoteBannerProps) => {
  const { t } = useTranslation('common');

  const note = useMemo(() => {
    const textNodes = noteBanner.note.split('\n');

    return (
      <Box>
        {textNodes.map((fragment, index) => (
          <Fragment key={index}>
            {fragment ? (
              <Text variant="desktop-m-regular">{fragment}</Text>
            ) : (
              <br />
            )}
          </Fragment>
        ))}
      </Box>
    );
  }, [noteBanner]);

  return (
    <BorderBox
      display="flex"
      p="20px"
      gap={3}
      alignItems="stretch"
      _hover={{}}
      flexDir={{ base: 'column', md: 'row' }}
      bgColor="decorative.gold.100"
      borderColor="decorative.gold.700"
    >
      {isFavorite && (
        <>
          <Flex
            color="decorative.fuschia.500"
            alignItems="center"
            justifyContent="center"
            flexDir={{ base: 'row', md: 'column' }}
            gap={2}
            p="8px"
            borderRadius={8}
          >
            <IconHeartFilled size="sm" />
            <Text color="inherit" variant="desktop-m-medium">
              {t('profile.application.favorite')}
            </Text>
          </Flex>
          <Box w="1px" bg="rythm.200" display={{ base: 'none', md: 'block' }} />
        </>
      )}
      <Box flex={1}>
        {note}
        <Flex alignItems="center" gap={2} mt="12px">
          <Avatar
            src={noteBanner.pictureUrl}
            name={noteBanner.adminName}
            size="smRound"
            showBorder
            borderColor="rythm.700"
          />
          <Text variant="desktop-m-regular">
            {t('profile.application.fromCollective', {
              adminName: noteBanner.adminName,
            })}
          </Text>
        </Flex>
      </Box>
    </BorderBox>
  );
};
