import { jobDescriptor } from '@collective/utils/fixtures';
import { faker } from '@faker-js/faker';
import cuid from 'cuid';

import {
  Collective_PayAsYouGoMemberType,
  PayAsYouGoUnit,
} from '../../generated';
import { generateMember } from './member';

export const generatePayAsYouGoMember = (
  props?: Partial<Collective_PayAsYouGoMemberType>,
  n = 0
): Collective_PayAsYouGoMemberType => {
  return {
    id: cuid(),
    proposalId: cuid(),
    memberId: cuid(),
    description: jobDescriptor[n],
    amount: faker.number.int(100),
    quantity: faker.number.int(100).toString(),
    unit: faker.helpers.arrayElement(Object.values(PayAsYouGoUnit)),
    member: generateMember(undefined, n),
    ...props,
  };
};
