import {
  Box,
  Flex,
  Heading,
  IconBars3,
  IconButton,
  IconPlus,
  Spacer,
  Tooltip,
} from '@collective/ui';
import { useTranslation } from 'react-i18next';

import { UserProfile } from '../../types';
import { WorkExperienceActions } from './types';
import { groupWorkExperiences } from './utils';
import { WorkExperienceEmptyState } from './work-experience-empty-state';
import { WorkExperienceRow } from './work-experience-row';

type WorkExperienceSectionProps = {
  actions?: WorkExperienceActions;
  isCollapsible: boolean;
  workExperiences?: UserProfile['workExperiences'];
};

export const WorkExperienceSection = ({
  actions,
  isCollapsible,
  workExperiences = [],
}: WorkExperienceSectionProps) => {
  const { t } = useTranslation('common');

  const workExperiencesWithoutEndDates = workExperiences.filter(
    ({ endDate }) => !endDate
  );

  const groups = groupWorkExperiences(
    workExperiences.map((data, index) => ({ ...data, index }))
  );

  return (
    <Box>
      <Flex alignItems="center" mb="20px">
        <Heading variant="desktop-m-bold">
          {t('profile.workExperience.title')}
        </Heading>
        <Spacer />
        <Flex gap={2}>
          {actions && workExperiencesWithoutEndDates.length > 1 && (
            <Tooltip label={t('profile.workExperience.reorderLabel')}>
              <IconButton
                variant="iconButton"
                icon={<IconBars3 />}
                aria-label={t('profile.workExperience.reorderLabel')}
                onClick={actions?.onReorderClick}
              />
            </Tooltip>
          )}

          {actions && (
            <Tooltip label={t('profile.workExperience.addLabel')}>
              <IconButton
                variant="iconButton"
                icon={<IconPlus />}
                aria-label={t('profile.workExperience.addLabel')}
                onClick={actions.onCreateClick}
              />
            </Tooltip>
          )}
        </Flex>
      </Flex>

      <Flex direction="column">
        {actions && !workExperiences.length && (
          <WorkExperienceEmptyState onClick={actions.onCreateClick} />
        )}
        {groups.map(({ id, items }) => (
          <WorkExperienceRow
            key={id}
            workExperiences={items}
            isCollapsible={isCollapsible}
            actions={actions}
          />
        ))}
      </Flex>
    </Box>
  );
};
