import { jobTitle, paragraph2, sentence3 } from '@collective/utils/fixtures';
import cuid from 'cuid';

import {
  Collective_CollectiveType,
  Collective_MemberType,
  MemberRole,
  RolePermission,
} from '../../generated';
import { generateUser } from './user';

export const generateMember = (
  props?: Partial<Collective_MemberType>,
  n = 0
): Collective_MemberType => ({
  id: cuid(),
  userId: cuid(),
  collectiveId: cuid(),
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  title: jobTitle[n],
  bio: paragraph2[n],
  references: sentence3[n],
  pictureUrl: undefined,
  user: generateUser(undefined, n),
  role: MemberRole.Member,
  rolePermissions: Object.values(RolePermission),
  isVisible: true,
  // The cast is to avoid infinite loop where between user<->member<->collective
  collective: props?.collective || ({} as Collective_CollectiveType),
  ...props,
});
