import cuid from 'cuid';
import dayjs from 'dayjs';
import { times } from 'lodash';

import { Collective_MissionType } from '../../generated';
import {
  generateCollective,
  generateMilestone,
  generatePaymentRequest,
  generatePaymentRequestMilestone,
  generateProject,
  generateProposal,
} from '.';

export const generateMission = (
  props?: Partial<Collective_MissionType>,
  n = 0
): Collective_MissionType => {
  const project = props?.project || generateProject({}, n);
  const proposal = props?.proposal || generateProposal({}, n);
  const collective = props?.collective || generateCollective({}, n);

  const upcomingMilestones =
    props?.upcomingMilestones ||
    times(2, (i) =>
      generateMilestone(
        {
          proposalId: proposal.id,
        },
        i + n
      )
    );
  const completedMilestones = times(2, (i) =>
    generatePaymentRequestMilestone(
      {
        dueDate: dayjs().subtract(15, 'days').toISOString(),
      },
      i + n
    )
  );

  return {
    id: cuid(),
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    milestones: upcomingMilestones,
    upcomingMilestones,
    paymentRequests: props?.paymentRequests ?? [
      generatePaymentRequest({
        milestones: completedMilestones,
      }),
    ],
    project,
    projectId: project.id,
    proposal,
    proposalId: proposal.id,
    members: [],
    collective,
    collectiveId: collective.id,
    isPrepaid: false,
    ...props,
  };
};
